import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Select, { components } from 'react-select';
import moment from 'moment';
import { Modal } from "react-bootstrap";

import country_arr from './common_arr/countries-arr.js';
import s_a from './common_arr/states-arr.js';
import product_unit_arr from './common_arr/product-unit-arr.js';
import currency_arr from './common_arr/currency-arr.js';

import { useTheme } from "./theme-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';

function EditPurchaseOrder(props) {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const history = useHistory()

    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null)

    const [organizationDetails, setOrganizationDetails] = useState(null)
    const [branchList, setBranchlist] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [salesManagerList, setSalesManagerList] = useState([])
    const [termsList, setTermsList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [currencyExchangeRates, setCurrencyExchangeRates] = useState([])
    const [addressList, setAddressList] = useState([]);
    const [gstinList, setGstinList] = useState([]);
    const [itemList, setItemList] = useState([])
    const [SupplyStateOptions, setSupplyStateOptions] = useState([]);
    const [warehouseList, setWarehouseList] = useState([])
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [brandList, setBrandList] = useState([])

    const [addressApiState, setAddressApiState] = useState(false)
    const [gstApiState, setGstApiState] = useState(false)
    const [itemApiState, setItemApiState] = useState(false);

    const itemListApiController = useRef(new AbortController())
    const userListApiController = useRef(new AbortController())


    const [userType, setUserType] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null)
    const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
    const [selectedGstin, setSelectedGstin] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [referenceNumber, setReferenceNumber] = useState("")
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("")
    const [orderDate, setOrderDate] = useState(moment.utc().format('YYYY-MM-DD'))
    const [dueDate, setDueDate] = useState(moment.utc().add(5, "days").format('YYYY-MM-DD'))
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [selectedSalesManager, setSelectedSalesManager] = useState([])
    const [selectedBrandsList, setSelectedBrandsList] = useState([])
    const [placeOfSupply, setPlaceOfSupply] = useState(null)
    const [selectedSourceOfSupply, setSelectedSourceOfSupply] = useState(null);
    const [subject, setSubject] = useState("")
    const [adjustment, setAdjustment] = useState({ text: "Adjustment", display: "0", value: 0 })
    const [customerNotes, setCustomerNotes] = useState("")
    const [termsAndConditions, setTermsAndConditions] = useState("")

    const [itemArray, setItemArray] = useState([{ item: null, item_type: "", tax_code: "", item_stock_type: null, brand: "", item_popover: false, item_popover_input: "", quantity: 1, pre_defined_rate: 0, rate: 0, rate_popover: false, tax: null, taxable: false, tax_calculation_method: null, op_toggle: false }])
    const [subTotal, setSubTotal] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)

    const [submitButtonState, setSubmitButtonState] = useState(false);



    const [productCodeModalShow, setProductCodeModalShow] = useState(false)
    const [productCodeItemId, setProductCodeItemId] = useState(null)
    const [productCodeItemType, setProductCodeItemType] = useState(null)
    const [productCodeSearch, setProductCodeSearch] = useState("")
    const [productCodeList, setProductCodeList] = useState([])
    const [productCodeListApiState, setProductCodeListApiState] = useState(false)

    const [ItemModalShow, setItemModalShow] = useState(false);
    const [Product_type, setProduct_type] = useState("Goods");
    const [ItemName, setItemName] = useState("");
    const [UnitSelectionList, setUnitSelectionList] = useState([]);
    const [Unit, setUnit] = useState("8");
    const [TaxPreference, setTaxPreference] = useState("Taxable");
    const [TaxCalculationMethod, setTaxCalculationMethod] = useState("TAX_Inclusive")
    const [Default_tax_preference, setDefault_tax_preference] = useState({ "Inter_State_Tax": "", "Intra_State_Tax": "" });
    const [Tracking_inventory, setTracking_inventory] = useState(false);
    const [Tracking_inventory_type, setTracking_inventory_type] = useState("");
    const [itemSubmitButtonState, setitemSubmitButtonState] = useState(false);

    const [gstInListModalShow, setGstInListModalShow] = useState(false);

    const [AddressModalShow, setAddressModalShow] = useState(false);
    const [AddressType, setAddressType] = useState("");

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false);

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)
    
    const [approvalInfo, setApprovalInfo] = useState(null);

    var page_name = "edit-purchase-order";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const unionTerritoryList = [
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Delhi",
        "Jammu and Kashmir",
        "Ladakh",
        "Lakshadweep",
        "Puducherry"
    ]


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        let state_arr = s_a.s_a[country_arr.country_arr.findIndex((obj) => obj == "India")].split("|");
        let obj_array = [];
        for (let i = 0; i < state_arr.length; i++) {
            obj_array.push({ value: state_arr[i], label: state_arr[i] });
        }
        setSupplyStateOptions(obj_array);
    }, []);

    useEffect(() => {
        var obj_array = [];
        var Productunitarr = product_unit_arr.product_unit_arr;
        for (let k = 0; k < Productunitarr.length; k++) {
            var obj2 = {};
            obj2.label = Productunitarr[k].Short_term + "(" + Productunitarr[k].Unit + ")";
            obj2.value = Productunitarr[k].id;
            obj_array.push(obj2);
        }
        setUnitSelectionList(obj_array);
    }, [])

    useEffect(() => {
        if ((taxGroupList.length > 0) && (currencyList.length > 0)) {
            setSubTotal(trimDecimalPlaces(itemArray.reduce((p, o) => { return p + ((o.quantity * o.rate) + (o.tax == null ? 0 : (o.quantity * o.rate) * (taxGroupList.find(x => x.tax_group_id == o.tax.value).tax_group_percentage / 100))) }, 0), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place))
        }
    }, [itemArray, selectedCurrency, currencyList, taxGroupList])

    useEffect(() => {
        if (currencyList.length > 0) {
            setGrandTotal(trimDecimalPlaces(subTotal + adjustment.value, selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place))
        }
    }, [subTotal, adjustment, selectedCurrency, currencyList])

    async function getCreditorAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-creditor-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing" && x.default_address))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping" && x.default_address))
        }
        else {
            throw "creditor address details api error"
        }
        setAddressApiState(false)
    }

    async function getVendorAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-vendor-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing"))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping"))
        }
        else {
            throw "vendor address details api error"
        }
        setAddressApiState(false)
    }

    async function getManufacturerAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing" && x.default_address))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping" && x.default_address))
        }
        else {
            throw "manufacturer address details api error"
        }
        setAddressApiState(false)
    }

    async function getCreditorGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-creditor-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setGstinList(body.data);
        }
        else {
            throw "creditor tax details api error"
        }
        setGstApiState(false)
    }

    async function getVendorGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-vendor-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setGstinList(body.data);
        }
        else {
            throw "vendor tax details api error"
        }
        setGstApiState(false)
    }

    async function getManufacturerGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setGstinList(body.data);
        }
        else {
            throw "manufacturer tax details api error"
        }
        setGstApiState(false)
    }

    async function getSupplierSalesManagers(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-sales-managers-of-supplier`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        setSalesManagerList(body.data)
    }

    async function getCreditorBrands(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-creditor-brands`, { method: 'POST', body: JSON.stringify({ "email": user.email, "creditor_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            body.data.length > 0 && setBrandList(body.data[0].brands_ids);
        }
        else {
            throw "brand api error"
        }
    }


    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": "purchase_order", "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setPurchaseOrderNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
        }
        else {
            swal({
                title: "Oops",
                text: body.message,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Go to transaction series"
                    }
                }
            }).then((val) => {
                if (val) {
                    window.open("/transaction-series")
                }
            })

            console.log("get sub series data api error")
        }
    }

    async function getItems(search_value, brands) {
        try {
            if (itemApiState) {
                itemListApiController.current.abort()
                itemListApiController.current = new AbortController()
            }

            setItemApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-product-by-search`, { signal: itemListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "brands": brands, "type": "Purchase", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.operation === "success") {
                setItemList(body.data);
                setItemApiState(false);
            }
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTaxGroupDetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation == "success") && (body.data.length > 0)) {
            setTaxGroupList(body.data);
        }
    }

    async function getTerms() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-invoice-terms`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTermsList(body)
        }
    }

    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body.data)
        }
    }

    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyExchangeRates(body[0])
        }
    }

    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x => x.organization_id == getCookie("organization_id")))
        }
    }

    async function updateProductCode(id, type, val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/update-hsnsac-code-of-product`, { method: 'POST', body: JSON.stringify({ "email": user.email, product_id: id, product_type: type, product_code: val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation != "success") {
            throw "update product code api error"
        }
    }

    async function getProductCodeList(type, val) {
        setProductCodeListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-hsn-code`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_type": type === "Goods" ? "HSN" : "SAC", "search_key": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setProductCodeList(body.data)
            setProductCodeListApiState(false)
        }
        else {
            throw "get product code list api error"
        }
    }

    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x => x.organization_id == getCookie("organization_id")))
        }
    }
    async function getPurchaseOrderDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-purchase-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setPurchaseOrderDetails(body.data[0])
        }
        else {
            throw "get purchase order details api error"
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "purchase_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBranchDetails()
            let p2 = getTransactionSeriesList()
            let p3 = getWarehouses()
            let p4 = getTaxGroupDetails()
            let p5 = getCurrencies()
            let p6 = getCurrencyExchangeRates()
            let p7 = getOrganizationDetails()
            let p8 = getItems(null, null)
            let p9 = getTerms()
            let p10 = getApprovalDetails()

            Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10])
                .then(async () => {
                    await getPurchaseOrderDetails()
                    setstate(1)
                    // console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { history.push("/create-purchase-order"); })
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if ((purchaseOrderDetails != null) && (branchList.length > 0) && (transactionSeriesList.length > 0) && (warehouseList.length > 0)) {

            setUserType({ label: purchaseOrderDetails.user_type, value: purchaseOrderDetails.user_type })
            setSelectedUser({ label: purchaseOrderDetails.doc_user_name, value: purchaseOrderDetails.doc_user_id })

            getSupplierSalesManagers(purchaseOrderDetails.doc_user_id)
            if (purchaseOrderDetails.user_type == "CREDITOR") {
                getCreditorAddressDetails(purchaseOrderDetails.doc_user_id)
                getCreditorGstinDetails(purchaseOrderDetails.doc_user_id)
                getCreditorBrands(purchaseOrderDetails.doc_user_id)
            }
            else if (purchaseOrderDetails.user_type == "VENDOR") {
                getVendorAddressDetails(purchaseOrderDetails.doc_user_id)
                getVendorGstinDetails(purchaseOrderDetails.doc_user_id)
            }
            else if (purchaseOrderDetails.user_type == "MANUFACTURER") {
                getManufacturerAddressDetails(purchaseOrderDetails.doc_user_id)
                getManufacturerGstinDetails(purchaseOrderDetails.doc_user_id)
            }

            setSelectedBranch({ label: branchList.find(br => br.branch_id === purchaseOrderDetails.branch_id) === undefined ? "" : branchList.find(br => br.branch_id === purchaseOrderDetails.branch_id).branch_name, value: purchaseOrderDetails.branch_id });
            setPlaceOfSupply(warehouseList.find(x => x.wid == purchaseOrderDetails.warehouse_id).state)
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == purchaseOrderDetails.transaction_series_id).series_name, value: purchaseOrderDetails.transaction_series_id });
            setSelectedBillingAddress(purchaseOrderDetails.billing_address)
            setSelectedShippingAddress(purchaseOrderDetails.shipping_address)
            setSelectedGstin(purchaseOrderDetails.gstin_details)
            setPurchaseOrderNumber(purchaseOrderDetails.purchase_order_number)
            setReferenceNumber(purchaseOrderDetails.reference_number)
            setSelectedSourceOfSupply({ label: purchaseOrderDetails.source_of_supply, value: purchaseOrderDetails.source_of_supply });
            setOrderDate(moment(purchaseOrderDetails.order_date).format('YYYY-MM-DD'))
            setSelectedTerm(purchaseOrderDetails.term == null ? null : { label: purchaseOrderDetails.term.term_name, value: purchaseOrderDetails.term.term_days })
            setDueDate(moment(purchaseOrderDetails.due_date).format('YYYY-MM-DD'))
            setSelectedSalesManager(purchaseOrderDetails.sales_manager.map(x => { return { label: x.sales_manager_name + " - " + x.sales_manager_email, value: x.sales_manager_email, name: x.sales_manager_name } }))
            setSelectedCurrency({ label: purchaseOrderDetails.order_currency.currency_code, value: purchaseOrderDetails.order_currency.currency_id })
            setSelectedWarehouse({ label: warehouseList.find(x => x.wid == purchaseOrderDetails.warehouse_id).name, value: purchaseOrderDetails.warehouse_id })
            setSubject(purchaseOrderDetails.subject)
            setAdjustment({ ...purchaseOrderDetails.adjustment, display: purchaseOrderDetails.adjustment.value.toString() })
            setSelectedBrandsList(purchaseOrderDetails.brands_list)
            setCustomerNotes(purchaseOrderDetails.customer_notes)
            setTermsAndConditions(purchaseOrderDetails.terms_and_conditions)

            let temp = purchaseOrderDetails.item_array.map((item) => {
                return {
                    item: { label: item.item_name, value: item.item_id },
                    item_type: item.item_type,
                    tax_code: item.tax_code,
                    item_stock_type: item.item_stock_type,
                    brand: item.brand,
                    item_popover: false,
                    item_popover_input: "",
                    quantity: item.quantity,
                    pre_defined_rate: 0,
                    rate: item.rate,
                    rate_popover: false,
                    tax: item.tax_group_id == null ? null : { label: `(${item.tax_group_percentage}%) ${item.tax_group_name}`, value: item.tax_group_id },
                    taxable: item.taxable,
                    tax_calculation_method: item.tax_calculation_method,
                    op_toggle: false
                }
            })
            // console.log(temp)

            setItemArray(temp)
        }
    }, [purchaseOrderDetails, branchList, transactionSeriesList, warehouseList])

    useEffect(() => {
        if ((selectedBranch != null) && (transactionSeriesList.length > 0) && (warehouseList.length > 0)) {
            let tb = branchList.find(x => x.branch_id == selectedBranch.value)
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == tb.default_transaction_series).series_name, value: tb.default_transaction_series })
            setPurchaseOrderNumber("")
            getSubSeriesData(tb.default_transaction_series)

            let twl = warehouseList.filter(x => x.branch_id == selectedBranch.value)
            if (twl.length > 0) {
                twl.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                let temp = twl[0]
                setSelectedWarehouse({ label: temp.name, value: temp.wid })
                setPlaceOfSupply(temp.state);
            }
            else {
                let tw = warehouseList.find(x => x.default == 1)
                setSelectedWarehouse({ label: tw.name, value: tw.wid })
                setPlaceOfSupply(tw.state);
            }
        }
    }, [selectedBranch, transactionSeriesList, warehouseList])

    const submitItemDetails = () => {

        var values = {
            "ItemName": ItemName,
            "Unit": Unit,
            "Sku": "",
            "Product_type": Product_type,
            "Manufacturer": "",
            "SubCategory": "",
            "Category": "",
            "Brand": "",
            "Hsn_code": "",
            "Sac_code": "",
            "Tracking_inventory": Tracking_inventory,
            "Tracking_inventory_type": Tracking_inventory_type,
            "Shipping_flag": 0,
            "Shipping_info": JSON.stringify([{ "Weight": "", "Dimension": { "Width": "", "Height": "", "Length": "" } }]),
            "Specification_info": JSON.stringify({ "MPN": "", "ICBN": "", "UPC": "", "EAN": "" }),
            "TaxPreference": TaxPreference,
            "Tax_Calculation_Method": TaxCalculationMethod,
            "Default_tax_preference": JSON.stringify(Default_tax_preference),
            "organization_id": organization_id,
            "page_name": page_name,
        };

        if (ItemName.trim().length == 0) {
            swal("Warning!", "Please enter a Item Name", "warning");
            return false;
        }
        if (Unit.length == 0) {
            swal("Warning!", "Please select a unit", "warning");
            return false;
        }

        setitemSubmitButtonState(true);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/product`, values, headers).then((res) => {
            setitemSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Item details saved successfully!", "success");
                getItems(null, selectedBrandsList.length > 0 ? selectedBrandsList.map(b => b.value) : null);
                setItemModalShow(false);
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    // const convertCurrencyAmount = (p_curr, amt) => {
    //     let pc_code = currencyList.find(x => x.currency_id == p_curr).currency_code

    //     let c_rate = (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]
    //     let c2_rate = (pc_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + pc_code]

    //     return Math.round(amt * (c_rate / c2_rate) * 100) / 100
    // }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    const validateOrder = () => {
        if (selectedBillingAddress == null) {
            swal("Oops!", "Billing Address cannot be empty", "warning");
            return false
        }
        if (selectedShippingAddress == null) {
            swal("Oops!", "Shipping Address cannot be empty", "warning");
            return false
        }
        if (selectedSourceOfSupply == null) {
            swal("Oops!", "Source of Supply cannot be empty", "warning");
            return false
        }
        if (dueDate == "") {
            swal("Oops!", "Due Date cannot be empty", "warning");
            return false
        }
        if (selectedCurrency == null) {
            swal("Oops!", "Currency cannot be empty", "warning");
            return false
        }
        if (selectedWarehouse == null) {
            swal("Oops!", "Warehouse cannot be empty", "warning");
            return false
        }

        let t = itemArray.find(o => {
            if (o.item == null) {
                return o
            }
        })
        if (t != undefined) {
            swal("Oops!", "Order item cannot be empty", "warning");
            return false
        }

        let t2 = itemArray.find(o => {
            if ((o.taxable == true) && (o.tax == null)) {
                return o
            }
        })
        if (t2 != undefined) {
            swal("Oops!", "Select a tax for the taxable item", "warning");
            return false
        }

        return true
    }

    const updateOrderSubmit = (val) => {
        var object = {}

        object.billing_address = selectedBillingAddress
        object.shipping_address = selectedShippingAddress
        object.gstin_number = selectedGstin === null ? null : selectedGstin.GstIn
        object.gstin = selectedGstin

        object.reference_number = referenceNumber.trim()

        object.source_of_supply = selectedSourceOfSupply.value
        object.term = (selectedTerm == null) ? null : { term_name: selectedTerm.label, term_days: selectedTerm.value }
        object.due_date = dueDate

        object.sales_manager_arr = selectedSalesManager.map(x => { return { sales_manager_name: x.name, sales_manager_email: x.value } })

        let org_c_code = currencyList.find(x => x.currency_id == organizationDetails.currency).currency_code
        let usr_c_code = org_c_code

        let org_c_rate = (org_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + org_c_code]
        let usr_c_rate = (usr_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + usr_c_code]
        let pord_c_rate = (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]

        let exchange_rate = {
            organization: { base_curr: "USD", quote_curr: org_c_code, rate: org_c_rate },
            user: { base_curr: "USD", quote_curr: usr_c_code, rate: usr_c_rate },
            purchase_order: { base_curr: "USD", quote_curr: selectedCurrency.label, rate: pord_c_rate },
        }

        let ic = currencyList.find(x => x.currency_id == selectedCurrency.value)
        object.order_currency = { currency_id: ic.currency_id, currency_symbol: ic.currency_symbol, currency_code: ic.currency_code, format: ic.format, decimal_place: ic.decimal_place, exchange_rate: exchange_rate }

        object.warehouse_id = selectedWarehouse.value
        object.subject = subject

        object.brands_list = selectedBrandsList

        let temp = []
        itemArray.forEach((obj) => {
            let tobj = {}
            tobj.item_name = obj.item.label
            tobj.item_id = obj.item.value
            tobj.item_type = obj.item_type
            tobj.item_stock_type = obj.item_stock_type
            tobj.brand = obj.brand
            tobj.tax_code_type = obj.item_type == "Goods" ? "HSN" : "SAC"
            tobj.tax_code = obj.tax_code
            tobj.quantity = obj.quantity
            tobj.rate = obj.rate
            tobj.taxable = obj.taxable ? 1 : 0
            tobj.tax_calculation_method = obj.tax_calculation_method
            tobj.tax_group_id = (obj.tax == null) ? null : obj.tax.value
            tobj.tax_group_name = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_name
            tobj.tax_group_percentage = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_percentage
            tobj.tax_group_details = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_details

            temp.push(tobj)
        })
        object.item_array = temp

        let t = { text: adjustment.text, value: adjustment.value }
        object.adjustment = t

        object.grand_total = grandTotal
        object.customer_notes = customerNotes
        object.terms_and_conditions = termsAndConditions

        object.save_flag = val
        object.purchase_order_id = props.match.params.id
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-purchase-order`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Purchase Order updated successfully!`, "success").then((value) => { history.push("/purchase-order-list"); });
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Reference Number already exists!!", "error");
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj => {
            if ((obj.term_name.trim() == "") || (obj.no_of_days == "")) {
                f1 = false
            }
        })
        if (f1 == false) {
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj => { return obj.term_name.trim() })
        if (temp.length != [... new Set(temp)].length) {
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList

        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Terms added successfully!", "success")
                handleNewTermsModalClose()
                getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([])
    }

    const newCurrencySubmit = () => {

        if (selectedCurrencyCode == null) {
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        if (currencySymbol.trim() == "") {
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
        object.decimal_place = selectedCurrencyDecimalPlace == null ? null : selectedCurrencyDecimalPlace.value
        object.format = selectedCurrencyFormat == null ? null : selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if (res.data.operation === "same_currency_code") {
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null)
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Purchase Order</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            ((!approvalInfo || approvalInfo.type === "not_configure") && state === 1) &&
                            <>
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                    </svg>
                                    </span>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">Purchase Approval Part Not Configured yet. Configure this, From <Link to={"/approvals"}>Approval Page</Link>.</div>
                                        </div>
                                    </div>
                                </div><br />
                            </>
                        }
                        <div className="card">
                            <div className="card-body">

                                {
                                    state == 1 ?
                                        <>
                                            <div className="row gx-0 ">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">User Type</label>
                                                    <Select value={userType} isDisabled styles={theme == 'dark' ? dropdownStyleDark : {}} />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">{userType == null ? "User" : userType.value.toLowerCase().replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase())} Name</label>
                                                    <Select value={selectedUser} isDisabled styles={theme == 'dark' ? dropdownStyleDark : {}} />
                                                </div>
                                            </div>

                                            <div className="row gx-0 ">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                    <Select value={selectedBranch} isDisabled styles={theme == 'dark' ? dropdownStyleDark : {}} />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                    <Select value={selectedTransactionSeries} isDisabled styles={theme == 'dark' ? dropdownStyleDark : {}} />
                                                </div>
                                            </div>

                                            <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: !addressApiState && selectedUser != null ? "1" : "0", visibility: !addressApiState && selectedUser != null ? "visible" : "hidden", height: !addressApiState && selectedUser != null ? "auto" : "0" }} >

                                                <div className="col-md-6 px-1 mb-4">
                                                    <div className="card card-dashed p-6 flex-row">
                                                        <div className="d-flex flex-column flex-grow-1">
                                                            <div className="d-flex align-items-center fs-5 fw-bold mb-5">Billing Address</div>
                                                            {
                                                                selectedBillingAddress != null ?
                                                                    <>
                                                                        {(selectedBillingAddress.address_line1 != "" && selectedBillingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.address_line1} ${selectedBillingAddress.address_line2}`}</div>}
                                                                        <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.city != null ? selectedBillingAddress.city : ""} ${selectedBillingAddress.state != null ? selectedBillingAddress.state : ""}, ${selectedBillingAddress.pincode != null ? selectedBillingAddress.pincode : ""} ${selectedBillingAddress.country != null ? selectedBillingAddress.country : ""}`}</div>
                                                                    </>
                                                                    :
                                                                    <div className='text-hover-primary'>Select an address</div>
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("billing") }} className="btn btn-sm px-3">
                                                                <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 px-1 mb-4">
                                                    <div className="card card-dashed p-6 flex-row">
                                                        <div className="d-flex flex-column flex-grow-1">
                                                            <div className="d-flex align-items-center fs-5 fw-bold mb-5">Shipping Address</div>
                                                            {
                                                                selectedShippingAddress != null ?
                                                                    <>
                                                                        {(selectedShippingAddress.address_line1 != "" && selectedShippingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.address_line1} ${selectedShippingAddress.address_line2}`}</div>}
                                                                        <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.city != null ? selectedShippingAddress.city : ""} ${selectedShippingAddress.state != null ? selectedShippingAddress.state : ""}, ${selectedShippingAddress.pincode != null ? selectedShippingAddress.pincode : ""} ${selectedShippingAddress.country != null ? selectedShippingAddress.country : ""}`}</div>
                                                                    </>
                                                                    :
                                                                    <div className='text-hover-primary'>Select an address</div>
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("shipping") }} className="btn btn-sm px-3">
                                                                <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: !gstApiState && selectedUser != null ? "1" : "0", visibility: !gstApiState && selectedUser != null ? "visible" : "hidden", height: !gstApiState && selectedUser != null ? "auto" : "0" }} >
                                                <div className="col-12 px-1 mb-4">
                                                    <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 rounded">
                                                        <h5>GSTIN Details</h5>

                                                        <div className="d-flex flex-wrap align-items-center gap-1">
                                                            {
                                                                selectedGstin != null ?
                                                                    <>
                                                                        <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                            <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.gst_treatment}</span>
                                                                            <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                        </div>
                                                                        <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                            <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.GstIn}</span>
                                                                            <div className="fw-semibold text-gray-400">GSTIN</div>
                                                                        </div>
                                                                    </> :
                                                                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                        <span className="fs-6 text-gray-700 fw-bold">No GSTIN selected</span>
                                                                    </div>
                                                            }

                                                            <div>
                                                                <button onClick={(e) => { setGstInListModalShow(true); }} className="btn btn-sm px-3">
                                                                    <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2 required">Warehouse</label>
                                                    <Select
                                                        options={warehouseList.map(x => { return { label: x.name, value: x.wid } })}
                                                        onChange={(val) => { setSelectedWarehouse(val); setPlaceOfSupply(warehouseList.find(x => x.wid == val.value).state); }}
                                                        value={selectedWarehouse}
                                                        placeholder="Select Warehouse..."
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2 required">Currency</label>
                                                    <Select
                                                        options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id } })}
                                                        onChange={(val) => { setSelectedCurrency(val) }}
                                                        value={selectedCurrency}
                                                        components={{
                                                            MenuList: (props) => {
                                                                return (
                                                                    <components.MenuList  {...props}>
                                                                        {props.children}
                                                                        <div className='d-flex justify-content-center'>
                                                                            <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setCurrencyModalShow(true) }} >&#10011; Add New Currency</button>
                                                                        </div>
                                                                    </components.MenuList >
                                                                )
                                                            }
                                                        }}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Reference Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={referenceNumber} onChange={(e) => { setReferenceNumber(e.target.value) }} />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Order Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={purchaseOrderNumber} readOnly disabled />
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Order Date</label>
                                                    <input type="date" className="form-control form-control-solid" value={orderDate} disabled />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2 required">Due Date</label>
                                                    <input type="date" className="form-control form-control-solid" value={dueDate} onChange={(e) => { setDueDate(e.target.value); setSelectedTerm(null); }} />
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Terms</label>
                                                    <Select
                                                        options={termsList.map(x => { return { label: x.term_name, value: x.no_of_days } })}
                                                        onChange={(val) => { setSelectedTerm(val); setDueDate(moment.utc(orderDate).add(val.value, "days").format('YYYY-MM-DD')); }}
                                                        value={selectedTerm}
                                                        components={{
                                                            MenuList: (props) => {
                                                                return (
                                                                    <components.MenuList  {...props}>
                                                                        {props.children}
                                                                        <div className='d-flex justify-content-center'>
                                                                            <button className='btn btn-success rounded btn-sm my-1'
                                                                                onClick={() => {
                                                                                    setTermsModalShow(true)
                                                                                    setTermsPanelList(termsList.map(x => { return { ...x } }))
                                                                                }}
                                                                            >&#10011; Configure Terms</button>
                                                                        </div>
                                                                    </components.MenuList >
                                                                )
                                                            }
                                                        }}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Sales Manager</label>
                                                    <Select
                                                        isMulti
                                                        options={salesManagerList.map(x => { return { label: x.name + " - " + x.user_id, value: x.user_id, name: x.name } })}
                                                        onChange={(val) => { setSelectedSalesManager(val) }}
                                                        value={selectedSalesManager}
                                                        placeholder="Select Sales Manager..."
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Brands</label>
                                                    <Select
                                                        isMulti
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={brandList}
                                                        onChange={(val) => {
                                                            setSelectedBrandsList(val);
                                                            setItemList([]);
                                                            getItems(null, val.length > 0 ? val.map(b => b.value) : null)
                                                        }}
                                                        value={selectedBrandsList}
                                                        placeholder="Select Brands..."
                                                        isClearable
                                                        isDisabled={((userType == null) || userType.value != "CREDITOR")}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Source Of Supply</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={SupplyStateOptions}
                                                        onChange={(val) => { setSelectedSourceOfSupply(val) }}
                                                        value={selectedSourceOfSupply}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0">
                                                <div className="col-md-12 px-1 mb-4">
                                                    <label className="fs-5 fw-bold mb-2">Subject</label>
                                                    <textarea className="form-control form-control-solid fs-6" rows="3" value={subject} onChange={(e) => { setSubject(e.target.value) }} placeholder={"Let your customer know what this Order is for"} ></textarea>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className='' style={{ overflowX: "auto" }}>
                                                <div className='container' style={{ width: "150rem" }}>
                                                    <div className="row mb-5 gx-0">
                                                        <div className="col-2"><p className="fs-5 text-center">Item</p></div>
                                                        <div className="col"><p className="fs-5 text-center">Rate</p></div>
                                                        <div className="col"><p className="fs-5 text-center">Quantity</p></div>
                                                        <div className="col-2"><p className="fs-5 text-center">Tax (%)</p></div>
                                                        <div className="col-1"><p className="fs-5 text-center">Tax amount</p></div>
                                                        <div className="col"><p className="fs-5 text-center">Line Total</p></div>
                                                        <div className="col"></div>
                                                    </div>
                                                    {
                                                        itemArray.map((obj, indx, arr) => {
                                                            return (
                                                                <div key={indx} className="row mb-5 gx-0 align-items-baseline">
                                                                    <div className="col-2 px-0">
                                                                        <Select
                                                                            options={itemList.map(x => { return { label: x.product_name, value: x.pid } })}
                                                                            onChange={(val) => {
                                                                                if (itemArray.filter(x => ((x.item !== null) && (x.item.value === val.value))).length > 0) {
                                                                                    swal("Oops!", "This product already selected.", "warning");
                                                                                    return
                                                                                }

                                                                                let temp = itemArray.map(a => { return { ...a } });
                                                                                temp[indx].item = val;

                                                                                let pr_d = itemList.find(x => x.pid == val.value)

                                                                                temp[indx].brand = pr_d.brand
                                                                                temp[indx].item_type = pr_d.product_type
                                                                                temp[indx].item_stock_type = pr_d.tracking_inventory_type
                                                                                temp[indx].tax_code = pr_d.product_type == "Goods" ? pr_d.hsn_code : pr_d.sac_code
                                                                                temp[indx].tax_calculation_method = pr_d.tax_information[0].tax_calculation_method

                                                                                let g_rate = pr_d.price_information === null ? 0 : trimDecimalPlaces(parseFloat(pr_d.price_information.purchase_price), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)
                                                                                temp[indx].pre_defined_rate = g_rate

                                                                                if (pr_d.tax_information[0].tax_preference == "Taxable") {
                                                                                    temp[indx].taxable = true
                                                                                }
                                                                                else {
                                                                                    temp[indx].taxable = false
                                                                                    temp[indx].tax = null
                                                                                }

                                                                                if ((pr_d.tax_information[0].tax_preference == "Taxable") && (placeOfSupply != null) && (selectedSourceOfSupply != null)) {
                                                                                    let applied_tax
                                                                                    if (placeOfSupply != selectedSourceOfSupply.value) {
                                                                                        let tp = pr_d.tax_information[0].tax_details
                                                                                        applied_tax = tp.Inter_State_Tax_Percentage
                                                                                        temp[indx].tax = { label: `(${tp.Inter_State_Tax_Percentage}%) ${tp.Inter_State_Tax_Name}`, value: tp.Inter_State_Tax }
                                                                                    }
                                                                                    else {
                                                                                        let tp = pr_d.tax_information[0].tax_details
                                                                                        applied_tax = tp.Intra_State_Tax_Percentage
                                                                                        temp[indx].tax = { label: `(${tp.Intra_State_Tax_Percentage}%) ${tp.Intra_State_Tax_Name}`, value: tp.Intra_State_Tax }
                                                                                    }

                                                                                    if (pr_d.tax_information[0].tax_calculation_method === "TAX_Inclusive") {
                                                                                        temp[indx].rate = g_rate / (1 + (applied_tax / 100))
                                                                                    }
                                                                                    else {
                                                                                        temp[indx].rate = g_rate
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    temp[indx].rate = g_rate
                                                                                }

                                                                                setItemArray(temp)
                                                                            }}
                                                                            onInputChange={(val) => { getItems(val, selectedBrandsList.length > 0 ? selectedBrandsList.map(b => b.value) : null); }}
                                                                            isLoading={itemApiState}
                                                                            value={itemArray[indx].item}
                                                                            components={{
                                                                                MenuList: (props) => {
                                                                                    return (
                                                                                        <components.MenuList  {...props}>
                                                                                            {props.children}
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setItemModalShow(true) }} >&#10011; Add New Item</button>
                                                                                            </div>
                                                                                        </components.MenuList >
                                                                                    )
                                                                                }
                                                                            }}
                                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            menuPosition={'fixed'}
                                                                        // menuShouldBlockScroll={true}
                                                                        />
                                                                        {
                                                                            itemArray[indx].item != null &&
                                                                            <div className='mt-1'>
                                                                                {
                                                                                    itemArray[indx].item_type == "Goods" ?
                                                                                        <>
                                                                                            <span className="badge badge-primary me-1">Goods</span>
                                                                                            <span className='text-info'>HSN code: </span>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <span className="badge badge-success me-1">Service</span>
                                                                                            <span className='text-info'>SAC code: </span>
                                                                                        </>
                                                                                }

                                                                                <div style={{ position: "relative", display: "inline-block" }} >
                                                                                    <span className='text-hover-primary' style={{ cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].item_popover = true
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >{itemArray[indx].tax_code == "" ? "Update " : itemArray[indx].tax_code}<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    <div style={{ position: "absolute", top: "-90px", left: "80px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].item_popover ? "1" : "0", visibility: itemArray[indx].item_popover ? "visible" : "hidden" }}>
                                                                                        <p className='mb-1'>{itemArray[indx].item_type == "Goods" ? "HSN Code" : "SAC Code"}</p>
                                                                                        <div className='d-flex gap-1 mb-1'>
                                                                                            <input type="text" className="form-control form-control-solid px-2" value={itemArray[indx].item_popover_input}
                                                                                                onChange={(e) => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_popover_input = e.target.value
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            />
                                                                                            <button className='btn btn-sm btn-secondary px-3' onClick={() => { setProductCodeModalShow(true); setProductCodeItemId(itemArray[indx].item.value); setProductCodeItemType(itemArray[indx].item_type); }}><i className="fa fa-search p-0" aria-hidden="true"></i></button>
                                                                                        </div>
                                                                                        <div className='d-flex gap-1 mb-1'>
                                                                                            <button className='btn btn-sm btn-success px-2 py-1'
                                                                                                onClick={async () => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    await updateProductCode(temp[indx].item.value, temp[indx].item_type, temp[indx].item_popover_input)
                                                                                                    temp[indx].tax_code = temp[indx].item_popover_input
                                                                                                    temp[indx].item_popover = false
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            >Save</button>
                                                                                            <button className='btn btn-sm btn-secondary px-2 py-1'
                                                                                                onClick={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_popover = false
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            >Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col px-1">
                                                                        <input type="number" className="form-control form-control-solid px-2" value={trimDecimalPlaces(itemArray[indx].rate, selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place).toString()}
                                                                            onChange={(e) => {
                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                temp[indx].rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                temp[indx].pre_defined_rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                setItemArray(temp)
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (itemArray[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                    let temp = itemArray.map(a => { return { ...a } });
                                                                                    if (temp[indx].tax != null) {
                                                                                        temp[indx].rate = temp[indx].pre_defined_rate == "" ? 0 : parseFloat(temp[indx].pre_defined_rate) / (1 + (taxGroupList.find(x => x.tax_group_id == temp[indx].tax.value).tax_group_percentage / 100))
                                                                                    }
                                                                                    setItemArray(temp)
                                                                                }
                                                                            }}
                                                                        />
                                                                        {
                                                                            itemArray[indx].item != null &&
                                                                            <div className='mt-1'>
                                                                                <div style={{ position: "relative", display: "inline-block" }} >
                                                                                    <button className='btn btn-link p-0'
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].rate_popover = true
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].rate_popover = false
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >Update<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div style={{ position: "absolute", top: "-60px", left: "65px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "10rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].rate_popover ? "1" : "0", visibility: itemArray[indx].rate_popover ? "visible" : "hidden" }}>
                                                                                        <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Inclusive" && "bg-primary"}`}
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } });
                                                                                                temp[indx].tax_calculation_method = "TAX_Inclusive"
                                                                                                if (temp[indx].tax != null) {
                                                                                                    temp[indx].rate = temp[indx].pre_defined_rate / (1 + (taxGroupList.find(x => x.tax_group_id == temp[indx].tax.value).tax_group_percentage / 100))
                                                                                                }
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >Tax Inclusive</div>
                                                                                        <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Exclusive" && "bg-primary"}`}
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } });
                                                                                                temp[indx].tax_calculation_method = "TAX_Exclusive"
                                                                                                if (temp[indx].tax != null) {
                                                                                                    temp[indx].rate = temp[indx].pre_defined_rate
                                                                                                }
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >Tax Exclusive</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col px-1">
                                                                        <input type="number" className="form-control form-control-solid px-2" value={itemArray[indx].quantity.toString()}
                                                                            onChange={(e) => {
                                                                                if (parseFloat(e.target.value) < 0) {
                                                                                    return
                                                                                }

                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                temp[indx].quantity = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                setItemArray(temp)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-2 px-0'>
                                                                        <Select
                                                                            options={taxGroupList.filter(x => {
                                                                                if ((placeOfSupply == null) || (selectedSourceOfSupply == null)) {
                                                                                    return true
                                                                                }
                                                                                else {
                                                                                    if (placeOfSupply != selectedSourceOfSupply.value) {
                                                                                        return x.tax_group_type == "INTERSTATE"
                                                                                    }
                                                                                    else {
                                                                                        return x.tax_group_type != "INTERSTATE"
                                                                                    }
                                                                                }
                                                                            }).map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                            onChange={(val) => {
                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                temp[indx].tax = val
                                                                                if (val != null) {
                                                                                    if (temp[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                        temp[indx].rate = temp[indx].pre_defined_rate / (1 + (taxGroupList.find(x => x.tax_group_id == val.value).tax_group_percentage / 100))
                                                                                    }
                                                                                    else if (temp[indx].tax_calculation_method == "TAX_Exclusive") {
                                                                                        temp[indx].rate = temp[indx].pre_defined_rate
                                                                                    }
                                                                                }

                                                                                setItemArray(temp)
                                                                            }}
                                                                            isClearable
                                                                            value={itemArray[indx].tax}
                                                                            isDisabled={!obj.taxable}
                                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            menuPosition={'fixed'}
                                                                            menuShouldBlockScroll={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col px-1">
                                                                        <input type="number" className="form-control form-control-solid px-2" readOnly value={trimDecimalPlaces((itemArray[indx].tax == null ? 0 : (itemArray[indx].quantity * itemArray[indx].rate) * (taxGroupList.find(x => x.tax_group_id == itemArray[indx].tax.value).tax_group_percentage / 100)), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} />
                                                                    </div>
                                                                    <div className="col px-1">
                                                                        <input type="number" className="form-control form-control-solid px-2" readOnly value={trimDecimalPlaces(((itemArray[indx].quantity * itemArray[indx].rate) + (itemArray[indx].tax == null ? 0 : (itemArray[indx].quantity * itemArray[indx].rate) * (taxGroupList.find(x => x.tax_group_id == itemArray[indx].tax.value).tax_group_percentage / 100))), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} />
                                                                    </div>
                                                                    {
                                                                        arr.length > 1 ?
                                                                            <div className="col position-relative">
                                                                                <button className='btn btn-sm btn-outline-info rounded-pill border border-info fw-bolder'
                                                                                    onClick={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].op_toggle = !temp[indx].op_toggle
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                >&#x2630;</button>
                                                                                {
                                                                                    itemArray[indx].op_toggle &&
                                                                                    <div className='position-absolute' style={{ top: "-40px", left: "-30px", width: "max-content" }}>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "50% 0 0 50%", padding: "6px 15px" }}
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                temp.splice(indx, 1)
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >&#10006;</button>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border-top border-bottom border-info fs-3 rounded-0' style={{ padding: "4px 10px" }}
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                temp[indx].op_toggle = false
                                                                                                temp.splice(indx, 0, temp[indx])
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >&#10063;</button>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "0 50% 50% 0", padding: "6px 15px" }}
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                if (indx == 0) {
                                                                                                    temp[indx].op_toggle = false
                                                                                                }
                                                                                                else {
                                                                                                    let t = itemArray[indx]
                                                                                                    temp.splice(indx, 1)
                                                                                                    temp.splice(indx - 1, 0, t)
                                                                                                }
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12" height="12" viewBox="0 0 512.000000 512.000000">
                                                                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                                                    <path d="M2475 5090 c-82 -9 -181 -44 -253 -88 -73 -44 -2015 -1625 -2063 -1679 -54 -60 -99 -137 -127 -215 -34 -96 -42 -271 -18 -368 51 -204 209 -377 408 -447 106 -38 264 -44 371 -14 43 12 104 34 135 50 63 32 76 42 977 776 351 286 643 520 649 519 6 -1 342 -270 746 -600 840 -684 838 -683 915 -718 123 -56 276 -73 411 -46 221 44 421 238 479 465 19 76 19 231 0 314 -21 87 -84 204 -147 273 -51 54 -1958 1613 -2049 1675 -122 81 -284 120 -434 103z" />
                                                                                                    <path d="M2480 2870 c-64 -7 -165 -37 -225 -67 -52 -26 -115 -77 -1108 -886 -671 -547 -981 -806 -1012 -844 -54 -70 -101 -165 -120 -246 -19 -81 -19 -237 0 -312 56 -221 245 -408 465 -459 151 -36 329 -12 463 62 40 22 377 290 838 666 l770 628 42 -33 c23 -18 368 -299 767 -624 399 -325 745 -604 770 -621 101 -69 222 -106 355 -107 113 -1 177 14 285 65 160 76 290 237 335 413 18 69 20 221 4 297 -19 91 -91 227 -156 294 -56 57 -1935 1592 -2038 1665 -122 86 -277 125 -435 109z" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            : <div className="col" style={{ visibility: "hidden" }}></div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="row mb-5">
                                                        <div className="col-3">
                                                            <button className='btn btn-primary rounded fs-7' onClick={() => { setItemArray([...itemArray, { item: null, item_type: "", tax_code: "", item_stock_type: null, brand: "", item_popover: false, item_popover_input: "", quantity: 1, pre_defined_rate: 0, rate: 0, rate_popover: false, tax: null, taxable: false, tax_calculation_method: null, op_toggle: false }]) }}>&#10011; Add a row</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="">
                                                        <div className="fw-semibold fs-5 mb-3 text-dark">Tax Calculation</div>
                                                        {
                                                            itemArray.reduce((p, o) => {
                                                                if (o.tax == null) {
                                                                    return p
                                                                }
                                                                else {
                                                                    let tg = taxGroupList.find(x => x.tax_group_id == o.tax.value)

                                                                    let decimal = Math.pow(10, parseInt(currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place));
                                                                    let total_taxable_amount = Math.round(((o.quantity * o.rate) * (tg.tax_group_percentage / 100)) * decimal) / decimal;
                                                                    let total_tax = tg.tax_group_details == null ? 0 : tg.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);

                                                                    tg.tax_group_details.forEach((obj1, index1) => {
                                                                        if (p.find(x => x.tax_id == obj1.tax_id)) {
                                                                            let t = p.find(x => x.tax_id == obj1.tax_id);
                                                                            t.tax_amount = t.tax_amount + Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
                                                                        }
                                                                        else {
                                                                            let json = {};
                                                                            json.tax_id = obj1.tax_id;
                                                                            json.tax_name = obj1.tax_name;
                                                                            json.tax_percentage = obj1.tax_percentage;
                                                                            json.tax_amount = Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
                                                                            p.push(json);
                                                                        }
                                                                    })

                                                                    return p
                                                                }
                                                            }, []).map((obj, index) => {
                                                                return (
                                                                    <div key={index} className="d-flex text-gray-800 mb-3 fs-6">
                                                                        <div className="fw-semibold pe-5">{obj.tax_name} ({obj.tax_percentage}%):</div>
                                                                        <div className="text-end">{currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {obj.tax_amount}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className='col-md-6 d-flex align-items-center'>
                                                            <p className='m-0 fs-5'>Sub Total</p>
                                                        </div>
                                                        <div className='col-md-6 my-2'>
                                                            <input type="text" className="form-control form-control-solid" value={subTotal} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-6 my-2">
                                                            <input type="text" className="form-control form-control-solid" value={adjustment.text} onChange={(e) => { setAdjustment({ ...adjustment, text: e.target.value }) }} />
                                                        </div>
                                                        <div className='col-md-6 my-2'>
                                                            <input type="number" className="form-control form-control-solid" value={adjustment.display.toString()}
                                                                onChange={(e) => { setAdjustment({ ...adjustment, display: e.target.value, value: e.target.value == "" ? 0 : parseFloat(e.target.value) }) }}
                                                                onFocus={(e) => { if (adjustment.display === "0") { setAdjustment({ ...adjustment, display: "" }) } }}
                                                                onBlur={(e) => { if (adjustment.display === "") { setAdjustment({ ...adjustment, display: "0" }) } }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 mt-4 d-flex align-items-center'>
                                                            <h4 className='m-0'>Grand Total</h4>
                                                        </div>
                                                        <div className='col-md-6 mt-4 d-flex align-items-center'>
                                                            <h3>{currencyList.length > 0 && selectedCurrency != null && currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {grandTotal}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row mb-5">
                                                <div className="col-md-6 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Customer Notes</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={customerNotes} onChange={(e) => { setCustomerNotes(e.target.value) }} placeholder={"Looking forward for your business."}></textarea>
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Terms and Conditions</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={termsAndConditions} onChange={(e) => { setTermsAndConditions(e.target.value) }} placeholder={"Enter the terms and conditions of your business to be displayed in your transaction"}></textarea>
                                                </div>
                                            </div>

                                            {
                                                write  &&
                                                <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateOrder() && updateOrderSubmit(false) }}>
                                                    <span className="indicator-label">Save as Draft</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }

                                            {
                                                write  &&
                                                <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateOrder() && updateOrderSubmit(true) }}>
                                                    <span className="indicator-label">Save</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                        </>
                                        :
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <PageError />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={gstInListModalShow} onHide={() => { setGstInListModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>GSTIN List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setGstInListModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f4f6f9" }}>
                    <div className='container'>
                        {
                            gstinList.length === 0 ?
                                <div className="overflow-auto pb-5">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">No Data Available.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="d-flex flex-column gap-5">

                                        {
                                            gstinList.sort((a, b) => a.add_date_time - b.add_date_time).map((obj, index) => {
                                                return (
                                                    <div key={index} className="position-relative fv-row w-100 flex-md-root" style={{ cursor: "pointer" }} onClick={() => { setSelectedGstin(obj); setSelectedSourceOfSupply({ label: obj.place_of_supply, value: obj.place_of_supply }); setGstInListModalShow(false); }}>
                                                        <div className="card card-flush h-lg-100">
                                                            <div className="card-header mt-6">
                                                                <div className="card-title flex-column"><h3 className="fw-bolder mb-1">{obj.GstIn}</h3></div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="card-body p-9">
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.legal_name}</div>
                                                                        <div className="fw-semibold text-gray-400">Business Legal Name</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                        <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.place_of_supply}</div>
                                                                        <div className="fw-semibold text-gray-400">Source of Supply</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            selectedGstin != null && selectedGstin.unique_id === obj.unique_id &&
                                                            <div className='position-absolute' style={{ top: "3rem", right: "7rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setGstInListModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={ItemModalShow} onHide={() => { setItemModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Item</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="fv-row mb-10">
                            <label className="fs-6 fw-semibold mb-2">Type</label>
                            <div className="row" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button='true']">
                                <div className="col">
                                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${Product_type === "Goods" && "active"}`} data-kt-button="true">
                                        <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                            <input className="form-check-input" type="radio" name="discount_option" value="2" onChange={(e) => { setProduct_type("Goods") }} checked={Product_type === "Goods"} />
                                        </span>
                                        <span className="ms-5"><span className="fs-4 fw-bold text-gray-800 d-block">Goods</span></span>
                                    </label>
                                </div>
                                <div className="col">
                                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${Product_type === "Service" && "active"}`} data-kt-button="true">
                                        <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                            <input className="form-check-input" type="radio" name="discount_option" value="3" onChange={(e) => { setProduct_type("Service") }} checked={Product_type === "Service"} />
                                        </span>
                                        <span className="ms-5"><span className="fs-4 fw-bold text-gray-800 d-block">Service</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label className="required form-label">Item Name</label>
                            <input type="text" name="product_name" className="form-control mb-2" placeholder="Item name" value={ItemName} onChange={(e) => { setItemName(e.target.value) }} />
                            <div className="text-muted fs-7">A Item name is required and recommended to be unique.</div>
                        </div>
                        <div className="mb-5">
                            <label className="required form-label">Unit</label>
                            <Select
                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                options={UnitSelectionList}
                                onChange={(event) => { setUnit(event.value); }}
                                value={UnitSelectionList.filter(option => option.value == Unit).length != 0 && UnitSelectionList.filter(option => option.value == Unit)[0]}
                                placeholder="--Select product Unit--"
                            />
                            <div className="text-muted fs-7">Enter the product Unit.</div>
                        </div>
                        <div className="mb-5">
                            <label className="required form-label">Tax Preference</label>
                            <Select
                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                options={["Taxable", "Non Taxable", "Out of Scope", "Non-GST Supply"].map(x => ({ label: x, value: x }))}
                                onChange={(val) => { setTaxPreference(val.value); }}
                                value={{ label: TaxPreference, value: TaxPreference }}
                                placeholder="--Select Tax Preference--"
                            />
                            <div className="text-muted fs-7">Enter the Tax Preference.</div>
                        </div>
                        {
                            TaxPreference == "Taxable" &&
                            <>
                                <div className="mb-5">
                                    <label className="fs-6 fw-semibold mb-2">Tax Calculation Method</label>
                                    <div className="d-flex align-items-center">
                                        <div className={`rounded mx-2 px-6 py-2 ${TaxCalculationMethod == "TAX_Inclusive" && "bg-primary"}`} onClick={() => { setTaxCalculationMethod("TAX_Inclusive"); }}>TAX Inclusive</div>
                                        <div className={`rounded mx-2 px-6 py-2 ${TaxCalculationMethod == "TAX_Exclusive" && "bg-primary"}`} onClick={() => { setTaxCalculationMethod("TAX_Exclusive"); }}>TAX Exclusive</div>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label">Intra State Tax Rate</label>
                                    <Select
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                        options={taxGroupList.filter(x => x.tax_group_type != "INTERSTATE").map(x => { return { label: x.tax_group_name, value: x.tax_group_id } })}
                                        onChange={(val) => { setDefault_tax_preference({ ...Default_tax_preference, Intra_State_Tax: val.value }) }}
                                        value={Default_tax_preference.Intra_State_Tax === "" ? null : { label: taxGroupList.find(x => x.tax_group_id == Default_tax_preference.Intra_State_Tax).tax_group_name, value: Default_tax_preference.Intra_State_Tax }}
                                        placeholder="--Select Intra state tax group--"
                                    />
                                    <div className="text-muted fs-7">Set the Intra State Tax Rate.</div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label">Inter State Tax Rate</label>
                                    <Select
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                        options={taxGroupList.filter(x => x.tax_group_type == "INTERSTATE").map(x => { return { label: x.tax_group_name, value: x.tax_group_id } })}
                                        onChange={(val) => { setDefault_tax_preference({ ...Default_tax_preference, Inter_State_Tax: val.value }) }}
                                        value={Default_tax_preference.Inter_State_Tax === "" ? null : { label: taxGroupList.find(x => x.tax_group_id == Default_tax_preference.Inter_State_Tax).tax_group_name, value: Default_tax_preference.Inter_State_Tax }}
                                        placeholder="--Select Inter state tax group--"
                                    />
                                    <div className="text-muted fs-7">Set the Inter State Tax Rate.</div>
                                </div>
                            </>
                        }

                        <label className="form-check form-check-custom form-check-solid align-items-start">
                            <input className="form-check-input me-3" type="checkbox" name="email-preferences[]" checked={Tracking_inventory === true} value={Tracking_inventory} onChange={(e) => { setTracking_inventory(e.target.checked) }} />
                            <span className="form-check-label d-flex flex-column align-items-start">
                                <span className="fw-bolder fs-5 mb-0">Track Inventory for this item</span>
                                {Tracking_inventory && <span className="text-muted fs-6">You cannot enable/disable inventory tracking once you've created transactions for this item</span>}
                            </span>
                        </label>

                        {
                            Tracking_inventory === true &&
                            <div className="mb-5">
                                <label className="required fw-semibold fs-6 mb-5">Advanced Inventory Tracking</label>
                                <div className="d-flex fv-row">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("None") }} checked={Tracking_inventory_type === "None"} />
                                        <label className="form-check-label" for="kt_modal_update_role_option_0">
                                            <div className="fw-bold text-gray-800">Track Quantity</div>
                                            <div className="text-gray-600"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="d-flex fv-row">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("Track Serial Number") }} checked={Tracking_inventory_type === "Track Serial Number"} />
                                        <label className="form-check-label" for="kt_modal_update_role_option_1">
                                            <div className="fw-bold text-gray-800">Track Serial Number</div>
                                            <div className="text-gray-600"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="d-flex fv-row">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("Track Batches") }} checked={Tracking_inventory_type === "Track Batches"} />
                                        <label className="form-check-label" for="kt_modal_update_role_option_2">
                                            <div className="fw-bold text-gray-800">Track Batches</div>
                                            <div className="text-gray-600"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setItemModalShow(false) }}>Close</button>
                    {
                        write  &&
                        <button className="btn btn-success rounded" data-kt-indicator={itemSubmitButtonState ? "on" : "off"} disabled={itemSubmitButtonState} onClick={() => { submitItemDetails() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={() => { handleNewTermsModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewTermsModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                                termsPanelList.map((x, indx) => {
                                    return (
                                        <div key={indx} className="row mb-2">
                                            <div className='col-5'>
                                                <input type="text" className="form-control form-control-solid" value={x.term_name}
                                                    onChange={(e) => {
                                                        let temp = termsPanelList.map(x => { return { ...x } })
                                                        temp[indx].term_name = e.target.value
                                                        setTermsPanelList(temp)
                                                    }}
                                                />
                                            </div>
                                            <div className='col-5'>
                                                <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days}
                                                    onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            let temp = termsPanelList.map(x => { return { ...x } })
                                                            temp[indx].no_of_days = e.target.value
                                                            setTermsPanelList(temp)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='col-2 my-auto'>
                                                <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger'
                                                    onClick={() => {
                                                        let temp = termsPanelList.map(a => { return { ...a } })
                                                        temp.splice(indx, 1)
                                                        setTermsPanelList(temp)
                                                    }}
                                                >&#10006;</button>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={() => { setTermsPanelList([...termsPanelList, { term_name: "", no_of_days: "" }]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write  &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState ? "on" : "off"} disabled={termsSubmitButtonState} onClick={() => { newTermsSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewTermsModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={currencyModalShow} onHide={() => { handleNewCurrencyModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewCurrencyModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr[0]).map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[0][e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e) => { setCurrencySymbol(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0, 2, 3].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89"].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write  &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState ? "on" : "off"} disabled={currencySubmitButtonState} onClick={() => { newCurrencySubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewCurrencyModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={AddressModalShow} onHide={() => { setAddressModalShow(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Select {AddressType === "billing" ? "Billing" : "Shipping"} Address</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddressModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row gx-9 gy-6">
                            {
                                addressList.map((obj, indx) => {
                                    return (
                                        <div key={indx} className="card card-dashed flex-wrap p-6" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (AddressType == "billing") {
                                                    setSelectedBillingAddress(obj)
                                                }
                                                else {
                                                    setSelectedShippingAddress(obj)
                                                }
                                                setAddressModalShow(false)
                                            }}
                                        >
                                            <div className="d-flex flex-column py-2">
                                                <div className="d-flex align-items-center fs-5 fw-bold mb-5">Address {indx + 1} {selectedBillingAddress != null && selectedBillingAddress.id === obj.id && "(Billing)"} {selectedShippingAddress != null && selectedShippingAddress.id === obj.id && "(Shipping)"} </div>
                                                <div className="fs-6 fw-semibold text-gray-600">
                                                    {obj.state}
                                                    <br />{obj.city},{obj.pincode}
                                                    <br />{obj.country}
                                                </div>
                                            </div>
                                            {
                                                (selectedBillingAddress != null && selectedShippingAddress != null) && (selectedBillingAddress.id === obj.id || selectedShippingAddress.id === obj.id) &&
                                                <div className='position-absolute' style={{ top: "1rem", right: "1rem" }} >
                                                    <svg width="30" height="30" viewBox="0 0 64 64">
                                                        <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setAddressModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={productCodeModalShow} onHide={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Find {productCodeItemType == "Goods" ? "HSN" : "SAC"} Code</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="container">

                            <label className="form-label">Search {productCodeItemType == "Goods" ? "HSN" : "SAC"} code for your item</label>
                            <div className="d-flex gap-1">
                                <input type="text" className="form-control" value={productCodeSearch} onChange={(e) => { setProductCodeSearch(e.target.value) }} />
                                <button className="btn btn-primary btn-sm rounded" onClick={(e) => { getProductCodeList(productCodeItemType, productCodeSearch) }} disabled={productCodeListApiState}>Search</button>
                            </div>

                            {
                                productCodeList.length > 0 && productCodeList.map((obj, index) => {
                                    return (
                                        <div key={index} className="align-items-center border border-primary cursor-pointer d-flex gap-2 my-2 p-1 rounded"
                                            onClick={() => {
                                                setProductCodeModalShow(false);
                                                let temp = itemArray.map(a => { return { ...a } })
                                                let ti = itemArray.findIndex(x => x.item.value == productCodeItemId)
                                                temp[ti].item_popover_input = obj.codes
                                                setItemArray(temp)

                                                setProductCodeItemId(null);
                                                setProductCodeItemType(null);
                                                setProductCodeSearch("");
                                                setProductCodeList([]);
                                                setProductCodeListApiState(false);
                                            }}
                                        >
                                            <div className="symbol symbol-40px">
                                                <span className="symbol-label bg-light">
                                                    <span className="svg-icon svg-icon-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <p className="fs-6 text-gray-800 text-hover-primary fw-semibold mb-1">{obj.code_description}</p>
                                                <span className="fs-7 text-muted fw-semibold">#{obj.codes}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default EditPurchaseOrder;