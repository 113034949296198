import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Pagination } from 'antd';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import StatusBadge from '../common_component/StatusBadge.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import flow_img from '../flow_images/Customer_order_flow.jpg';

export const ShowSubCategory = () => {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const [status, setstatus] = useState("");

    const [categoryList, setCategoryList] = useState([])

    const [subcategorId, setsubcategorId] = useState("");
    const [Category, setCategory] = useState("");
    const [subcategorycode, setSubcategorycode] = useState("");
    const [subcategoryname, setSubcategoryname] = useState("");
    const [subcategorydescription, setSubcategorydescription] = useState("");

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [subcategoryListApiState, setSubcategoryListApiState] = useState(false)

    const [SubCategoryModalShow, setSubCategoryModalShow] = useState(false);
    const [StatusModalShow, setStatusModalShow] = useState(false);

    const [specificationModalShow, setSpecificationModalShow] = useState(false)
    const [subcategorySpecificationInfoApiState, setSubcategorySpecificationInfoApiState] = useState(false)
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null)
    const [specificationData, setSpecificationData] = useState([])
    const [specificationModalSubmitButtonState, setSpecificationModalSubmitButtonState] = useState(false)

    const apiController = useRef(new AbortController())

    const [dropzoneModalShow, setDropzoneModalShow] = useState(false);
    const [ImageModalShow, setImageModalShow] = useState(false);
    const [Imagesrc, setImagesrc] = useState("");
    const [image_location, setimage_location] = useState("");
    const [image_key, setimage_key] = useState("");

    const [flowModalShow, setFlowModalShow] = useState(false)

    var page_name = "subcategory-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const MAX_SIZE = 4194304;

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(name)
        return (

            <div className="preview-box">
                <img src={previewUrl} alt="" style={{ maxHeight: "80px", maxWidth: "80px", borderRadius: "4px", marginRight: "10px" }} />
                <span title={name} className="name">{name.length >= 10 ? name.slice(0, 10) + "...." : name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" onClick={remove} >
                    <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                        <g>
                            <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                        </g>
                    </svg>
                </span>}
                {
                    status === "done" &&
                    <span className="dzu-previewButton remove" onClick={remove} >
                        <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                            <g>
                                <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                            </g>
                        </svg>
                    </span>
                }
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('subcategory_id', selectedSubcategoryId);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('type', "subcategory");
        setMainSubmitButtonState(false);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }

        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-common-image`, headers, body }
    }

    const handleChangeStatus = ({ xhr }) => {
        setMainSubmitButtonState(true);
        if (xhr) {
            xhr.onreadystatechange = () => {
                console.log(xhr);
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    if (result && result.operation === "success") {
                        setimage_location(result.message.Location);
                        setimage_key(result.message.Key);
                        setMainSubmitButtonState(false);
                    }

                    else {
                        swal("Warning!", result.operation, "warning");
                        setMainSubmitButtonState(false);
                    }
                }
            }
        }
        else {
            setMainSubmitButtonState(false);
        }
    }

    const newSubCategoryImageSubmit = () => {
        if (image_location == "" || image_key == "" ) {
            swal("Warning!", "You need to upload something before submitting.", "warning");
            return false;
        }

        var object = {};
        object.image_location = image_location
        object.image_key = image_key
        object.subcategory_id = selectedSubcategoryId

        object.organization_id = organization_id;
        object.page_name = page_name;

        //console.log(object);
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-subcategory-image`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Subcategory image saved successfully!", "success")

                setDropzoneModalShow(false);
                setImagesrc("");
                setimage_key("");
                setimage_location("");
                setSelectedSubcategoryId("");
                getsubcategorydetails(currentPage, rowsPerPage, searchValue);
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }


    async function getcategorydetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setCategoryList(body.data);
        }
    }

    async function getsubcategorydetails(page_number, Size, search_val) {
        try {
            var object = {};
            object.email = user.email;
            if (subcategoryListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setSubcategoryListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/subcategory-datatable`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "current_page": page_number - 1, "rows_per_page": Size, "search_value": search_val }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setSubcategoryList(body.data.list);
            setListCount(body.data.count);

            setSubcategoryListApiState(false)

        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getSubcategorySpecificationInfo(scid) {
        setSubcategorySpecificationInfoApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-subcategory-specification-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_category_id": scid, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setSubcategorySpecificationInfoApiState(false)
            if (body.data !== null) {
                setSpecificationData(body.data.specification_data)
            }
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getsubcategorydetails(currentPage, rowsPerPage, searchValue)
            let p2 = getcategorydetails()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getsubcategorydetails(currentPage, rowsPerPage, searchValue)
        }
    }, [currentPage, rowsPerPage, searchValue])

    const submitSubCategory = (id) => {
        setCategory("");
        setSubcategoryname("");
        setSubcategorycode("");
        setSubcategorydescription("");
        setsubcategorId("");
    }

    const updateSubCategory = (obj) => {
        setsubcategorId(obj.id);
        setCategory(obj.category);
        setSubcategoryname(obj.subcategory_name);
        setSubcategorycode(obj.subcategory_code);
        setSubcategorydescription(obj.subcategory_description);
        setSubCategoryModalShow(true);
    }
    const updateStatus = (id, add_status) => {
        setsubcategorId(id);
        setstatus(add_status);
        setStatusModalShow(true); 
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": subcategorId, "status": status, "page_name": page_name, "organization_id": organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-subcategory`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation === "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    setStatusModalShow(false);
                    getsubcategorydetails(currentPage, rowsPerPage, searchValue);;
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitSubCategorydetails = (e) => {

        var id = subcategorId;
        var sub_category_name = subcategoryname.trim();
        var sub_category_code = subcategorycode.trim();
        var sub_category_description = subcategorydescription;

        var cexp = /^[0-9]+$/
        if (Category == "") {
            swal("Warning!", "Please select a category", "warning");
            return false;
        }
        if (sub_category_name.length <= 0) {
            swal("Warning!", "Please enter a SubCategory Name", "warning");
            return false;
        }
        if (sub_category_code.length <= 0) {
            swal("Warning!", "Please enter a SubCategory Code", "warning");
            return false;
        }
        else if (!cexp.test(sub_category_code)) {
            swal("Warning!", "SubCategory Code can only be numbers!", "warning");
            return false;
        }

        var values = {
            "id": id,
            "category": Category,
            "sub_category_name": sub_category_name,
            "sub_category_code": sub_category_code,
            "sub_category_description": sub_category_description,

            "page_name": page_name,
            "organization_id": organization_id
        };
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        let link
        if (subcategorId === "") {
            link = "add-subcategory"
        }
        else {
            link = "update-subcategory"
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/${link}`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation === "success") {
                swal("Great!", `Sub Category details ${subcategorId === "" ? "added" : "updated"} successfully!`, "success")

                getsubcategorydetails(currentPage, rowsPerPage, searchValue);
                setSubcategoryname("");
                setSubcategorycode("");
                setSubcategorydescription("");
                setCategory("");
                setSubCategoryModalShow(false);
            }
            else if (res.data.operation === "same_subcategory_name") {
                swal("Oops!", "Same Sub Category Name Already Exist!", "error")
            }
            else if (res.data.operation === "same_subcategory_code") {
                swal("Oops!", "Same Sub Category Code Already Exist!", "error")
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const configureSubcategorySpecificationSubmit = () => {

        let flag = false
        specificationData.forEach((obj) => {
            if (obj.heading.trim() === "") {
                flag = true
            }
            if (obj.attribute_list.find(x => x.trim() === "") !== undefined) {
                flag = true
            }
        })
        if (flag) {
            swal("Oops!", "Specification Data cannot have empty fields", "warning")
            return
        }

        var object = {};

        object.sub_category_id = selectedSubcategoryId
        object.specification_data = specificationData

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setSpecificationModalSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/configure-subcategory-specification-info`, object, headers).then((res) => {
            setSpecificationModalSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Subcategory Specification Info configured successfully!", "success")
                handleSpecificationModalClose()
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleSpecificationModalClose = () => {
        setSpecificationModalShow(false)

        setSelectedSubcategoryId(null)
        setSpecificationData([])
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack mb-5">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Sub-category List</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <button className="btn btn-sm btn-primary me-3 rounded btn-hover-scale" onClick={(e) => { setSubCategoryModalShow(true); submitSubCategory() }}>
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff"></path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create New Subcategory</span>
                        </button>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' /></div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                subcategoryListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    subcategoryList.length > 0 && categoryList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3">
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px">Subcategory Name</th>
                                                                            <th className="min-w-150px">Subcategory Code</th>
                                                                            <th className="min-w-150px">Category</th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            subcategoryList.map((obj) => {
                                                                                return (
                                                                                    <tr key={obj.id}>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.subcategory_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.subcategory_code}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{categoryList.find(option => option.id === obj.category).category_name}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={""} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1"><span className={`dropdown-item cursor-pointer`} onClick={(e) => { updateSubCategory(obj); }} >Edit details</span></li>
                                                                                                        <li className="py-1"><span className={`dropdown-item cursor-pointer`} onClick={(e) => { setImagesrc(obj.image_location); setimage_key(""); setimage_location(""); setSelectedSubcategoryId(obj.id); setDropzoneModalShow(true); }}>Upload Image</span></li>
                                                                                                        <li className="py-1"><span className={`dropdown-item cursor-pointer`} onClick={(e) => { updateStatus(obj.id,obj.add_status);}} >Change status</span></li>
                                                                                                        <li className="py-1"><span className={`dropdown-item cursor-pointer`} onClick={(e) => { setSpecificationModalShow(true); setSelectedSubcategoryId(obj.id); getSubcategorySpecificationInfo(obj.id); }} >Configure Ecommerce specifications</span></li>
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={ImageModalShow} onHide={() => { setImageModalShow() }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Image Preview</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setImageModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <img src={Imagesrc} alt="" style={{ height: "300px", padding: '25px', marginTop: '0' }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={(e) => { setImageModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Modal show={dropzoneModalShow} onHide={() => { setDropzoneModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>Upload Subcategoy Image</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDropzoneModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        <div className="card card-flush flex-row-fluid p-6 pb-5 mw-100">
                            <div className="card-body text-center">
                                {Imagesrc !== null &&
                                    <img src={Imagesrc} className="rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px" alt="" />}
                            </div>
                        </div>
                        <Dropzone
                            getUploadParams={getUploadParams}
                            maxSize={MAX_SIZE}
                            onChangeStatus={(event) => {
                                handleChangeStatus(event);
                            }}
                            maxFiles={1}
                            multiple={false}
                            styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5', justifyContent: "center" },
                                inputLabelWithFiles: { margin: '20px 3%' }
                            }}
                            PreviewComponent={Preview}
                            accept="image/*"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setDropzoneModalShow(false) }}>Close</button>
                    {
                        (write && (image_key !== "" && image_location !== "")) &&
                        <button className="btn btn-success rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { newSubCategoryImageSubmit() }}>
                            <span className="indicator-label">+ Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={StatusModalShow} onHide={() => { setStatusModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Current Status is : {status === "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                            <h3>Are you sure about changing status?</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setStatusModalShow(false) }} className="btn btn-secondary">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitStatus(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>

            <Modal show={SubCategoryModalShow} onHide={() => { setSubCategoryModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{subcategorId !== "" ? <>Update</> : <>Add</>} SubCategory</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setSubCategoryModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Select Category</label>
                                <Select
                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={categoryList.map(x => ({ label: x.category_name, value: x.id }))}
                                    onChange={(event) => {
                                        setCategory(event.value);
                                    }}
                                    value={categoryList.map(x => ({ label: x.category_name, value: x.id })).filter(option => option.value === Category).length > 0 && categoryList.map(x => ({ label: x.category_name, value: x.id })).filter(option => option.value === Category)[0]}
                                    placeholder={`--Select--`}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">SubCategory Name</label>
                                <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={subcategoryname} onChange={(e) => { setSubcategoryname(e.target.value) }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="required fs-5 fw-bold mb-2">SubCategory Code</label>
                            <input id="subcategory_code" className="form-control form-control-solid" placeholder="" name="categorycode" value={subcategorycode} onChange={(e) => { setSubcategorycode(e.target.value) }} />
                        </div>

                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="fs-5 fw-bold mb-2">SubCategory Description</label>
                            <input id="subcategory_description" className="form-control form-control-solid" placeholder="" name="subcategory-description" value={subcategorydescription} onChange={(e) => { setSubcategorydescription(e.target.value) }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setSubCategoryModalShow(false) }} className="btn btn-secondary">Cancel</button>
                    {
                        write &&
                        <button type="button" onClick={(e) => { submitSubCategorydetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} >
                            <span className="indicator-label">{subcategorId !== "" ? "Update" : "Save"}</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={specificationModalShow} onHide={() => { handleSpecificationModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Subcategory Specifications</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleSpecificationModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        subcategorySpecificationInfoApiState ? <div className="text-center"><div class="spinner-border text-success" role="status"></div></div> :
                            <div className='container'>
                                {
                                    specificationData.length <= 0 ? <div className='text-center'><div className='text-gray-600 fs-2'>No Headers added</div></div> :
                                        specificationData.map((obj, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='d-flex'>
                                                        <label className='form-label my-auto mx-2'>Heading</label>
                                                        <input className="form-control form-control-solid m-1 mb-2" placeholder="Enter a Heading..." value={obj.heading}
                                                            onChange={(e) => {
                                                                let t = specificationData.map(x => { return { ...x } })
                                                                t[i].heading = e.target.value
                                                                setSpecificationData(t)
                                                            }}
                                                        />
                                                        <button className='btn btn-link text-hover-danger mx-3'
                                                            onClick={() => {
                                                                let t = specificationData.map(x => { return { ...x } })
                                                                t.splice(i, 1)
                                                                setSpecificationData(t)
                                                            }}
                                                        >&#10006;</button>
                                                    </div>
                                                    {
                                                        obj.attribute_list.map((obj2, j) => {
                                                            return (
                                                                <div key={j} className='d-flex ms-10'>
                                                                    <label className='form-label my-auto mx-2'>Attribute</label>
                                                                    <input className="form-control form-control-solid m-1" placeholder="Enter an Attribute..." value={obj2}
                                                                        onChange={(e) => {
                                                                            let t = specificationData.map(x => { return { ...x } })
                                                                            t[i].attribute_list[j] = e.target.value
                                                                            setSpecificationData(t)
                                                                        }}
                                                                    />
                                                                    <button className='btn btn-link text-hover-danger mx-3'
                                                                        onClick={() => {
                                                                            let t = specificationData.map(x => { return { ...x } })
                                                                            t[i].attribute_list.splice(j, 1)
                                                                            setSpecificationData(t)
                                                                        }}
                                                                    >&#10006;</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <button className='btn btn-link text-hover-success ms-10'
                                                        onClick={() => {
                                                            let t = specificationData.map(x => { return { ...x } })
                                                            t[i].attribute_list.push("")
                                                            setSpecificationData(t)
                                                        }}>+ Add Attribute
                                                    </button>
                                                </div>
                                            )
                                        })
                                }
                                <button className='btn btn-link text-hover-success' onClick={() => { setSpecificationData([...specificationData, { heading: "", attribute_list: [] }]) }}>+ Add Heading</button>
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={() => { handleSpecificationModalClose() }} className="btn btn-secondary">Cancel</button>
                    {
                        write &&
                        <button type="button" onClick={() => { configureSubcategorySpecificationSubmit() }} className="btn btn-primary" data-kt-indicator={specificationModalSubmitButtonState ? "on" : "off"} disabled={specificationModalSubmitButtonState} >
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ShowSubCategory;
