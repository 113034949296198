
import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';


import Select from 'react-select';
import "react-month-picker/css/month-picker.css";
import uuid from 'react-uuid';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { useAuthData } from "./auth-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';

export const ItemImages = (props) => {

    let [state, setstate] = useState(2);
    const [ImageModalShow, setImageModalShow] = useState(false);
    const [Imagesrc, setImagesrc] = useState(false);

    const [SequenceSelectionList, setSequenceSelectionList] = useState([{ label: 1, value: 1 }]);

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [unique_id, setUnique_id] = useState(uuid());
    const [hasproductimage, sethasproductimage] = useState(false)
    const [productname, setproductname] = useState("");
    const [inputFields, setInputFields] = useState([{ order: { label: 1, value: 1 }, file_name: "", flag: false }]);
    const [compressedformat, setcompressedformat] = useState("on")
    var page_name = "item-images";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)
    const MAX_SIZE = 4194304;

    const divStyle = {
        border: '1px solid #999',
        background: '#FCFBFB',
    };

    const textstyle = {
        paddingTop: '50px',
    };

    const dropzoneStyle = {
        paddingTop: '15px',
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    var files = [];
    const checkresize = (event) => {
        if (event.target.checked) {
            setcompressedformat("on");
        }
        else {
            setcompressedformat("off");
        }
    }

    async function getproductimages() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getproductimages`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "pid": props.match.params.id, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        var i;
        if (body.data.length > 0) {
            let temp_input = body.data.map(obj => { return { order: { label: Number(obj.order_num), value: Number(obj.order_num) }, file_name: obj.product_image, flag: true } })
            setInputFields(temp_input)
            sethasproductimage(true)

        }
    }
    async function getproductname() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getproductname`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "pid": props.match.params.id, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        if (body.operation === "success") {
            //setcompressedformat(body[0].compressedformat);
            setproductname(body.data[0].product_name);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getproductimages();
            let p2 = getproductname();
            Promise.all([p1, p2])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

    const handleChangeStatus2 = (index, { meta }) => {
        const { name, status } = meta;
        if (status === 'removed') {
            inputFields[index].file_name = ''
        }
    };

    const handleAddFields = () => {
        if (inputFields.length < 20) {
            const values = [...inputFields];
            values.push({ order: "", file_name: "", flag: false });
            let temp_dropdown = [];
            for (let i = 1; i < (values.length + 1); i++) {
                var temp_obj = {};
                temp_obj.label = i;
                temp_obj.value = i;
                temp_dropdown.push(temp_obj);

            }
            setSequenceSelectionList(temp_dropdown);
            setInputFields(values);
        }
        else {
            swal("Oops!", `You can add maximum ${inputFields.length} images of a product!`, "error");
        }

    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        let temp_dropdown = [];
        for (let i = 1; i < (values.length + 1); i++) {
            var temp_obj = {};
            temp_obj.label = i;
            temp_obj.value = i;
            temp_dropdown.push(temp_obj);

        }
        setSequenceSelectionList(temp_dropdown);
        setInputFields(values);
    };

    const handleInputChange = (index, name, val, event) => {
        console.log(name);
        console.log(event.value);
        const values = [...inputFields];
        if (name === "order") {
            if (values.filter(option => option.order.value == event.value).length > 0) {
                swal("Oops!", "This order number already exist!", "warning");
            }
            else {
                values[index].order = event;
            }
        }

        else {
            values[index].file_name = unique_id.concat("_", val);
        }
        setInputFields(values);
    };



    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(name)
        return (

            <div className="preview-box">
                <img src={previewUrl} style={{ maxHeight: "80px", maxWidth: "80px", borderRadius: "4px", marginRight: "10px" }} />
                <span title={name} className="name">{name.length >= 10 ? name.slice(0, 10) + "...." : name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" onClick={remove} >
                    <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                        <g>
                            <path class="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                        </g>
                    </svg>
                </span>}
                {
                    status === "done" &&
                    <span className="dzu-previewButton remove" onClick={remove} >
                        <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                            <g>
                                <path class="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                            </g>
                        </svg>
                    </span>
                }
            </div>
        )
    }

    const getUploadParams = ({ file }) => {

        // let fileSizeMB = (file.size) / (1024 ** 2);
        // console.log(fileSizeMB)

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('user_id', user.email);
        body.append('organization_id', organization_id);
        body.append('unique_id', unique_id);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }

        return { url: `${process.env.REACT_APP_Image_Upload_URL}/upload-product-images/c6354341-8a15-4e94-bf1d-b9b42e8824b2`, headers, body }
    }


    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        setMainSubmitButtonState(true);
        if (xhr) {
            xhr.onreadystatechange = () => {
                console.log(xhr);
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    if (result && result.message === "success") {
                        setMainSubmitButtonState(false);
                    }

                    else {
                        swal("Warning!", result.message, "warning");
                        setMainSubmitButtonState(false);
                    }
                }
            }
        }
        else {
            setMainSubmitButtonState(false);
        }
    }
    const onSubmit = () => {
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].order == '') {
                swal("Oops!", "Select a sequence for image " + (i + 1), "error");
                return false;
            }
            else if (inputFields[i].order.value < 1 || inputFields[i].order.value > 20) {
                swal("Oops!", "Sequence number should be between 1-20", "error");
                return false;
            }
            else if (inputFields[i].file_name == '') {
                swal("Oops!", "Select a file for image " + (i + 1), "error");
                return false;
            }
        }
        var i;
        var flag = 0;
        for (i = 0; i < inputFields.length; i++) {
            var key = inputFields[i].order;
            var j;
            var count = 0;
            for (j = i; j < inputFields.length; j++) {
                if (key === inputFields[j].order) {
                    count = count + 1;
                }
            }
            if (count > 1) {
                flag = 1;
                break;
            }
        }
        if (flag === 0) {
            let values = {};
            values.user_id = user.email;
            values.organization_id = organization_id;
            values.unique_id = unique_id;
            values.pimage = inputFields;
            values.pid = props.match.params.id;
            values.compressedformat = compressedformat;
            values.page_name = page_name
            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }
            setMainSubmitButtonState(true);
            axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-product-images`, values, headers).then((res) => {
                setMainSubmitButtonState(false);
                if (res.data.operation === "success") {
                    swal("Great!", "Images added successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else if (res.data.operation === "filenotuploaded") {
                    swal("Oops!", "You have not uploaded any images or documents!", "error");
                }

                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else {
            swal("Oops!", "You can't give same sequence for multiple images!", "error");
            return false;
        }
        // e.preventDefault();
    }
    const onUpdate = () => {
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].order == '') {
                swal("Oops!", "Select a sequence for image " + (i + 1), "error");
                return false;
            }
            else if (inputFields[i].order < 0 || inputFields[i].order > 20) {
                swal("Oops!", "Sequence number should be between 1-20", "error");
                return false;
            }
            else if (inputFields[i].file_name == '') {
                swal("Oops!", "Select a file for image " + (i + 1), "error");
                return false;
            }
        }
        var i;
        var flag = 0;
        for (i = 0; i < inputFields.length; i++) {
            var key = inputFields[i].order;
            var j;
            var count = 0;
            for (j = i; j < inputFields.length; j++) {
                if (key === inputFields[j].order) {
                    count = count + 1;
                }
            }
            if (count > 1) {
                flag = 1;
                break;
            }
        }
        //console.log(flag);
        if (flag === 0) {
            let values = {};
            values.user_id = user.email;
            values.organization_id = organization_id;
            values.unique_id = unique_id;
            values.pimage = inputFields;
            values.pid = props.match.params.id;
            values.compressedformat = compressedformat;
            values.page_name = page_name
            // console.log(values)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }
            setMainSubmitButtonState(true);
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update_product_img`, values, headers).then((res) => {
                setMainSubmitButtonState(false);
                if (res.data.operation === "success") {
                    swal("Great!", "Images updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else {
            swal("Oops!", "You can't give same sequence for multiple images!", "error");
            return false;
        }
    }

    return (
        <>

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{productname}

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state == 1 ?
                            <>
                                {/* <form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/products.html"> */}


                                <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                    <div className="tab-content">
                                        <div className="kt-portlet__body">
                                            <div className="row">
                                                <div className="col-xl-12 p-5" style={divStyle}>
                                                    {inputFields.map((inputField, index) => (
                                                        <React.Fragment key={`${inputField}~${index}`}>
                                                            <h5 className="mt-2">Item Image:{index + 1}</h5>
                                                            <div className="form-group row">
                                                                {/* <Form.Group> */}
                                                                <div className="col-lg-3" style={textstyle}>
                                                                    <Select
                                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                        options={SequenceSelectionList}
                                                                        onChange={(event) => { handleInputChange(index, "order", event, event); }}
                                                                        value={inputField.order}
                                                                        placeholder="Select Sequence"
                                                                    />
                                                                    {/* <input type="invalid" tooltip>{errors.order}</input> */}
                                                                </div>

                                                                <div className="col-xl-5" style={dropzoneStyle}>
                                                                    <Dropzone
                                                                        getUploadParams={getUploadParams}
                                                                        maxSize={MAX_SIZE}
                                                                        onChangeStatus={(event) => {
                                                                            handleChangeStatus(event);
                                                                            handleInputChange(index, "file", event.file.name, event);
                                                                            handleChangeStatus2(index, event);
                                                                            // console.log(event);
                                                                            // let fileSizeMB = (event.file.size) / (1024 ** 2);
                                                                            // console.log(fileSizeMB)
                                                                            // if(fileSizeMB<4)
                                                                            // {
                                                                            //     handleChangeStatus(event);
                                                                            //     handleInputChange(index,"file",event.file.name, event);
                                                                            //     handleChangeStatus2(index, event);
                                                                            // }
                                                                            // else
                                                                            // {
                                                                            //     swal("Warning!", "File size should be less than 4MB", "warning");
                                                                            //     return false;
                                                                            // }
                                                                        }}
                                                                        maxFiles={1}
                                                                        multiple={false}
                                                                        styles={{
                                                                            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5', justifyContent: "center" },
                                                                            inputLabelWithFiles: { margin: '20px 3%' }
                                                                        }}
                                                                        PreviewComponent={Preview}
                                                                        accept="image/*"
                                                                    />
                                                                    <div className="form-text">Image size should be less than 4MB.</div>
                                                                </div>
                                                                <div className="col-lg-1" style={dropzoneStyle}>
                                                                    {inputFields.length > 1 ?
                                                                        <button
                                                                            className="btn font-weight-bold btn-danger btn-icon"
                                                                            type="button"
                                                                            onClick={() => handleRemoveFields(index)}
                                                                        >
                                                                            <i className="la la-remove"></i>
                                                                        </button>
                                                                        : null}
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <a class="d-block overlay" onClick={() => { setImagesrc(("https://cdnstatic.adoerp.com").concat("/Product_image_750px/WEBP_750px_", (inputField.file_name))); setImageModalShow(true); }}>{inputField.flag == true &&
                                                                        <>
                                                                            <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-150px" style={{ "background-image": `url('${("https://cdnstatic.adoerp.com").concat("/Product_image_750px/WEBP_750px_", (inputField.file_name))}')` }}>
                                                                            </div>
                                                                            <div class="overlay-layer card-rounded bg-dark bg-opacity-25"><i class="fa-solid fa-eye fs-3x text-white"></i></div>
                                                                        </>
                                                                    }</a>

                                                                    {/* {inputField.file_name.substring(inputField.file_name.indexOf("_") + 1)}                                                    */}
                                                                </div>
                                                                {/* <div class="col-lg-1">
                                                                    <a class="d-block overlay" data-fslightbox="lightbox-hot-sales" onClick={() =>{setImagesrc(("https://cdnstatic.adoerp.com").concat("/Product_image_750px/WEBP_750px_", (inputField.file_name))); setImageModalShow(true);}}>       
                                                                        <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{"background-image": `url('${("https://cdnstatic.adoerp.com").concat("/Product_image_750px/WEBP_750px_", (inputField.file_name))}')`}}>                       
                                                                        </div>
                                                                        <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                            <i className="ki-duotone ki-eye fs-3x text-white"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>                    </div>       
                                                                    </a>                       
                                                                </div> */}
                                                            </div>
                                                            {/* </>
                                                } */}
                                                        </React.Fragment>
                                                    ))}
                                                </div>

                                                <div className="form-group row" style={dropzoneStyle}>
                                                    <div>
                                                        <button type="button" className="btn font-weight-bold btn-primary" onClick={() => handleAddFields()}>
                                                            <i className="la la-plus"></i>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancel</a>
                                        {hasproductimage ?
                                            <button onClick={(e) => { onUpdate(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
                                                <span className="indicator-label">Save Changes</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            :
                                            <button onClick={(e) => { onSubmit(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
                                                <span className="indicator-label">Save Changes</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                        }
                                    </div>
                                </div>

                                {/* </form> */}
                            </>
                            :
                            <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                <br />
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className="fw-bolder">Item Images</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">
                                                    </div>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Something went wrong.</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <br />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={ImageModalShow} onHide={() => { setImageModalShow() }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Image Preview</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setImageModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <img src={Imagesrc} style={{ height: "300px", padding: '25px', marginTop: '0' }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={(e) => { setImageModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default ItemImages;
