import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Link, useHistory } from 'react-router-dom'

import s_a from './states-arr.js';
import formatNumber from '../common_component/formatNumber.js';
import removeImg from './icons/remove.svg'
import country_arr from './common_arr/countries-arr.js';
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js";
import PageError from '../common_component/PageError.js';

export const CreatePaymentReceived = (props) => {
    const [state, setstate] = useState(2);
    const history = useHistory()
    const { theme, dropdownStyleDark } = useTheme()

    const [fileNames, setFileNames] = useState([])
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());

    const [References, setReferences] = useState("");
    const [Payment_Type, setPayment_Type] = useState("Invoice Payment");

    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
    const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

    const [copyIndex, setCopyIndex] = useState("")
    const [copyMode, setCopyMode] = useState("")
    const [copyData, setCopyData] = useState(null)

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [newHead, setNewHead] = useState({ name: "", code: "", uq_ac: "", sub: 0, parent: "", description: "" })
    const [addAccHeadModalShow, setAddAccHeadModalShow] = useState(false)
    const [categoryList, setCategoryList] = useState([]);
    const [accountheadList, setAccountHeadList] = useState([])

    const [submitButton, SetSubmitButton] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [totalAddressList, setTotalAddressList] = useState([])
    const [addressListApiState, setAddressListApiState] = useState(false)

    const [invoiceArray, setInvoiceArray] = useState([]);
    //{invoice_number: null, invoice_id: null, invoice_date: "", invoice_new: false,billing_address: null, shipping_address: null,adjust_amount_arr:[], gstin_details: null,payment_mode:"Cash",configuration_obj:{denomination:[],notes:""}}
    const [default_acc, setDefault_acc] = useState({});

    const [gstinList, setGstinList] = useState([]);
    const [totalGstinList, setTotalGstinList] = useState([])
    const [gstinListApiState, setGstinListApiState] = useState(false)
    const [gstInListModalShow, setGstInListModalShow] = useState(false);

    const [Configuration_obj, setConfiguration_obj] = useState({ denomination: [], Notes: "" });

    const [configurationindex, setconfigurationIndex] = useState(0);
    const [Currency_symbol, setCurrency_symbol] = useState("");
    const [Currency_code, setCurrency_code] = useState("");
    const [PaymentNumber, setPaymentNumber] = useState("");
    const [PaymentMode, setPaymentMode] = useState({ label: "Cash", value: "Cash" });
    const [PaymentModeList, setPaymentModeList] = useState([{ label: "Bank Transfer", value: "Bank Transfer" }, { label: "Cash", value: "Cash" }, { label: "Cheque", value: "Cheque" }, { label: "Credit Card", value: "Credit Card" }, { label: "Debit Card", value: "Debit Card" }, { label: "UPI", value: "UPI" }]);
    const [GSTINNumber, setGSTINNumber] = useState("");
    const [selectedPlaceOfSupply, setSelectedPlaceOfSupply] = useState(null);
    const [SelectedDestinationPlace, setSelectedDestinationPlace] = useState(null);
    const [SupplyStateOptions, setSupplyStateOptions] = useState([]);
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [gstTreatmentlist, setgstTreatmentlist] = useState([{ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" }, { label: "Registered Business-Composition", value: "Registered Business-Composition", description: "A business that is registered under composition scheme in GST" }, { label: "Unregistered Business", value: "Unregistered Business", description: "Business that has not been registered under GST" }, { label: "Consumer", value: "Consumer", description: "A customer who is regular consumer" }, { label: "Special Economic Zone", value: "Special Economic Zone", description: "Business that is located in a special economic zone" }, { label: "Deeemed Export", value: "Deeemed Export", description: "Supply of goods to an export oriented unit" }, { label: "SEZ Devloper", value: "SEZ Devloper", description: "An organization/person who owns atleast 26% of the equity in creating business units" }])
    const [paymentForList, setpaymentForList] = useState([{ label: "Customer", value: "Customer" }]);
    const [ConfigurationModalShow, setConfigurationModalShow] = useState(false);
    const [advanceConfigurationModalShow, setAdvanceConfigurationModalShow] = useState(false);
    const [amtArray, setamtArray] = useState([]);
    const [Calculation_info, setCalculation_info] = useState({ cash_amount: 0, bank_amount: 0, credit_card_amount: 0, debit_card_amount: 0, upi_amount: 0, cheque_amount: 0, payment_amount: 0 });
    const [PaymentAmount, setPaymentAmount] = useState(0);

    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [baseCurrency, setBaseCurrency] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState("");
    const [gstTreatment, setgstTreatment] = useState(null);
    const [paymentFor, setpaymentFor] = useState({ label: "Customer", value: "Customer" });
    const [paymentValue, setpaymentValue] = useState(null);
    const [DepositThroughAccount, setDepositThroughAccount] = useState(null)
    const [paymentValueList, setpaymentValueList] = useState([]);
    const [TotalpaymentValueList, setTotalpaymentValueList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null);
    const [branchList, setBranchlist] = useState([]);
    const [transactionSeriesList, setTransactionSeriesList] = useState([]);
    const [DepositThroughAccountlist, setDepositThroughAccountlist] = useState([]);
    const [Notes, setNotes] = useState("");

    const [addressModalShow, setAddressModalShow] = useState(false)
    const [addressType, setAddressType] = useState(null)
    const [invoiceIndexAddress, setInvoiceIndexAddress] = useState(null)
    const [addressId, setAddressId] = useState(null)

    const [gstinModalShow, setGstinModalShow] = useState(false)
    const [invoiceIndexGstin, setInvoiceIndexGstin] = useState(null)
    const [gstinId, setGstinId] = useState(null)

    const [invoiceIndexAdjustment, setInvoiceIndexAdjustment] = useState(null)
    const [invoiceIndexCurrency, setInvoiceIndexCurrency] = useState(null);
    const [advanceAdjustmentModalShow, setAdvanceAdjustmentModalShow] = useState(false);
    const [advancePaymentList, setAdvancePaymentList] = useState([]);

    const [selectedGstin, setSelectedGstin] = useState(null)
    const [Payment_date, setPayment_date] = useState(moment().format('YYYY-MM-DD'));
    const [timezone_change, set_timezone_change] = useState("+00:00");
    const [PaymentUserApiState, setPaymentUserApiState] = useState(false);

    const [currencyList, setCurrencyList] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [baseExchangeRate, setBaseExchangeRate] = useState(0);
    const [currencyExchangeRates, setCurrencyExchangeRates] = useState(null);
    const [submitButtonState, setSubmitButtonState] = useState(false);

    const [upsertGstInModalShow, setUpsertGstInModalShow] = useState(false);
    const [upsertGstInId, setUpsertGstInId] = useState("");
    const [upsertGstInTreatmentList, setUpsertGstInTreatmentList] = useState([{ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" }, { label: "Registered Business-Composition", value: "Registered Business-Composition", description: "A business that is registered under composition scheme in GST" }, { label: "Special Economic Zone", value: "Special Economic Zone", description: "Business that is located in a special economic zone" }, { label: "Deeemed Export", value: "Deeemed Export", description: "Supply of goods to an export oriented unit" }, { label: "SEZ Devloper", value: "SEZ Devloper", description: "An organization/person who owns atleast 26% of the equity in creating business units" }])
    const [upsertGstInTreatment, setUpsertGstInTreatment] = useState(null)
    const [upsertGstInNumber, setUpsertGstInNumber] = useState("");
    const [upsertGstInLegalName, setUpsertGstInLegalName] = useState("");
    const [upsertGstInTradeName, setUpsertGstInTradeName] = useState("");
    const [upsertGstInSelectedPlaceOfSupply, setUpsertGstInSelectedPlaceOfSupply] = useState(null)
    const [upsertGstInSubmitButtonState, setUpsertGstInSubmitButtonState] = useState(false);
    const [ExpenseGstinList, setExpenseGstinList] = useState([]);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [creditcardlist, setCreditCardlist] = useState([{ label: "Visa", value: "Visa" }, { label: "MasterCard", value: "MasterCard" }, { label: "RuPay", value: "RuPay" }]);
    const [debitcardlist, setDebitCardlist] = useState([{ label: "lifestyle credit cards", value: "lifestyle credit cards" }, { label: "Travel credit cards", value: "Travel credit cards" }]);

    const [invoiceListApiState, setInvoiceListApiState] = useState(false)
    const [invoiceList, setInvoiceList] = useState([])

    const apiController = useRef(new AbortController())

    var page_name = "new-payment-received";

    const search_length = 1;

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const formatNumberOptions = {
        removeTrailingZeros: true
    }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    useEffect(() => {
        if ((selectedBranch != null) && (transactionSeriesList.length > 0)) {
            let tb = branchList.find(x => x.branch_id == selectedBranch.value);
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == tb.default_transaction_series).series_name, value: tb.default_transaction_series });
            getSubSeriesData(tb.default_transaction_series, paymentFor.value);
        }
    }, [selectedBranch, transactionSeriesList])

    useEffect(() => {
        if (Payment_Type !== "" && selectedCurrency !== null  && categoryList.length > 0 && currencyExchangeRates !== null) {
            categoryList.forEach((obj) => {
                obj.headArray.forEach((obj1) => {
                    let temp_obj = { label: obj1.name, value: obj1.hid, code: obj1.code };
                    if (Payment_Type === "Invoice Payment" && obj1.name === "Petty Cash") {
                        setInvoiceArray([{ invoice_number: null, invoice_id: null, invoice_date: "", invoice_new: false, billing_address: null, shipping_address: null, adjust_amount_arr: [], gstin_details: null, invoice_amount: 0, due_amount: 0, payment_amount: 0, deposit_through_account_name: obj1.name, deposit_through_account: obj1.hid, deposit_through_account_category: obj.cid, payment_mode: "Cash", invoice_currency: selectedCurrency, configuration_obj: { denomination: [], notes: "" }, currency_rate: (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label] }])
                    }
                    if (Payment_Type === "Advance Payment" && obj1.name === "Advance Payment") {
                        setDepositThroughAccount(temp_obj);
                    }
                })
            });
        }
    }, [Payment_Type])

    useEffect(() => {
        if (categoryList.length > 0 && selectedCurrency !== null && currencyExchangeRates !== null) {
            categoryList.map((obj) => {
                obj.headArray.map((obj1) => {
                    let temp_obj = { label: obj1.name, value: obj1.hid, code: obj1.code };
                    if (obj1.name === "Petty Cash") {
                        setDefault_acc(temp_obj);
                    }
                })
            });
            setBaseExchangeRate(currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]);
            //setInvoiceArray([{ invoice_number: null, invoice_id: null, invoice_date: "", invoice_new: false, billing_address: null, shipping_address: null, adjust_amount_arr: [], gstin_details: null, invoice_amount: 0, due_amount: 0, payment_amount: 0, deposit_through_account_name: obj1.name, deposit_through_account: obj1.hid, deposit_through_account_category: obj.cid, payment_mode: "Cash", invoice_currency: selectedCurrency, configuration_obj: { denomination: [], notes: "" }, currency_rate: (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label] }])
        }

    }, [categoryList, selectedCurrency, currencyExchangeRates])

    const newHeadSubmit = () => {
        //Name check
        if (newHead.uq_ac == "") {
            swal("Warning!", "Select a Category", "warning");
            return
        }
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (newHead.name == "") {
            swal("Warning!", "Head must have name", "warning");
            return
        }
        if (!newHead.name.match(nexp)) {
            swal("Warning!", "Head name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        accountheadList.map((head) => {
            if (head.name.trim().toLowerCase() == newHead.name.toLowerCase()) {
                flag = true
            }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }

        let flag2 = false
        categoryList.map((head) => {
            if (head.category_name.trim().toLowerCase() == newHead.name.toLowerCase()) {
                flag = true
            }
        })
        if (flag2) {
            swal("Warning!", "Name Cannot be same of any Category", "warning");
            return
        }

        //Code check
        if (newHead.code == "") {
            swal("Warning!", "Head must have a code", "warning");
            return
        }
        let flag3 = false
        accountheadList.map((head) => {
            if (head.code.trim().toLowerCase() == newHead.code.toLowerCase()) {
                flag3 = true
            }
        })
        if (flag3) {
            swal("Warning!", "Code name already take", "warning");
            return
        }
        //Sub Parent check
        if (newHead.sub && !String(newHead.parent).trim().length) {
            swal("Warning!", "Sub-Head must have a parent", "warning");
            return
        }
        if (!newHead.sub && String(newHead.parent).trim().length) {
            swal("Warning!", "non Sub-Head should have a parent", "warning");
            return
        }

        let object = { ...newHead, page_name: page_name, organization_id: organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-account-head`, object, headers).then((res) => {
            SetSubmitButton(false)
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success");
                selectiveaccounthead("newly_added", newHead.name, Payment_Type)
                setAddAccHeadModalShow(false)
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    async function getFinalizeInvoiceListBySearch(search_val, val, user) {
        try {
            if (invoiceListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setInvoiceListApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-finalized-invoice-payment-by-search`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_val, "user": user, "flag": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.operation == "success") {
                setInvoiceListApiState(false)
                setInvoiceList(body.data);
            }
            else {
                throw "finalized invoice api list error"
            }
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getPaymentUser(search_value, key) {
        let api = "";
        if (key == "Customer") {
            api = "get-customer-by-search";
        }
        if (key == "Dealer") {
            api = "get-dealer-by-search";
        }
        setPaymentUserApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation === "success") {
            setTotalpaymentValueList(body.data.list);
            let value = body.data.list;
            {
                (key == "Customer") ?
                    value = value.map(x => { return { label: x.customer_display_name, value: x.cid } })
                    :
                    value = value.map(x => { return { label: x.dealer_display_name, value: x.cid } })
            }
            setpaymentValueList(value);
            setPaymentUserApiState(false);
            if (props.match.params.type !== undefined && props.match.params.id !== undefined) {
                let selected_user = body.data.list[0]
                if (props.match.params.type == "customer") {
                    selected_user = body.data.list.find(elem => elem.cid == props.match.params.id);
                    getFinalizeInvoiceListBySearch("", "Customer", selected_user.cid);
                }
                setpaymentValue({ label: selected_user.customer_display_name, value: selected_user.cid });
                getPaymentUserGstinDetails(selected_user.cid, "Customer");
                getUserAddressDetails(selected_user.cid, "Customer")
            }
        }
        else {
            throw "payment user list api error"
        }
    }

    async function getUserAddressDetails(val, key) {
        setAddressListApiState(true);
        let api = "";
        if (key == "Customer") {
            api = "get-customer-addresses";
        }
        if (key == "Dealer") {
            api = "get-dealer-addresses";
        }

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            console.log(body.data[0].address_details);
            setTotalAddressList(body.data[0].address_details);
            setAddressListApiState(false);
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing"))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping"))
        }
        else {
            throw "address details api error"
        }
    }

    async function getPaymentUserGstinDetails(val, key) {
        setGstinListApiState(true);
        let api = "";
        if (key == "Customer") {
            api = "get-customer-tax-details";
        }
        if (key == "Dealer") {
            api = "get-dealer-tax-details";
        }
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setTotalGstinList(body.data);
            setGstinListApiState(false);
            let t = body.data.find(x => x.flag == "Primary")
            if (t) {
                setSelectedGstin(t)
                setgstTreatment({ label: t.gst_treatment, value: t.gst_treatment });
                setGSTINNumber(t.GstIn);
                //setSelectedPlaceOfSupply({ label: t.place_of_supply, value: t.place_of_supply })
                setSelectedDestinationPlace({ label: t.place_of_supply, value: t.place_of_supply })
            }
            else {
                let temp = body.data
                temp.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                let temp2 = temp[0]
                setSelectedGstin(temp2)
                setgstTreatment({ label: temp2.gst_treatment, value: temp2.gst_treatment });
                setGSTINNumber(temp2.GstIn);
                //setSelectedPlaceOfSupply({ label: temp2.place_of_supply, value: temp2.place_of_supply });
                setSelectedDestinationPlace({ label: temp2.place_of_supply, value: temp2.place_of_supply });
            }
            setExpenseGstinList(body.data);
        }
        else {
            throw "tax details api error"
        }
    }

    async function getCurrencies() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length != 0) {
            setCurrencyList(body.data)
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation == "success") {
            setBranchlist(body.data);
            let temp = body.data.filter(option => option.default_branch == "yes")
            if (temp.length > 0) {
                if ((props.match.params.type === undefined) || (props.match.params.type !== undefined && props.match.params.type == "customer")) {
                    setSelectedBranch({ label: temp[0].branch_name, value: temp[0].branch_id });
                }
                setSelectedPlaceOfSupply({ label: temp[0].branch_address_state, value: temp[0].branch_address_state });
            }
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    let timezone_change = body.data[0].timezone_value;
                    set_timezone_change(timezone_change);
                    setPayment_date(moment().utcOffset(timezone_change).format('YYYY-MM-DD'));
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    async function selectiveaccounthead(flag, name, type) {
        let arr = [
            { "category_name": "Assets", "headArray": [] },
            { "category_name": "Equity", "headArray": [] },
            { "category_name": "Liability", "headArray": [] },
            { "category_name": "Bank", "headArray": [] }
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            let temp_dropdown = body.data.map((obj) => ({
                label: obj.category_name,
                id: obj.cid,
                options: obj.headArray.map((obj1) => {
                    let temp_obj = { label: obj1.name, value: obj1.hid, code: obj1.code };
                    if (flag == "newly_added" && obj1.name == name) {
                        if (type == "Invoice Payment") {
                            let temp = invoiceArray.map(a => { return { ...a } })
                            temp[selectedIndex].deposit_through_account = obj1.hid;
                            temp[selectedIndex].deposit_through_account_name = obj1.name;
                            temp[selectedIndex].deposit_through_account_category = obj.cid;
                            setInvoiceArray(temp)
                        }
                        else {
                            setDepositThroughAccount(temp_obj);
                        }
                    }
                    return temp_obj;
                })
            }));

            setDepositThroughAccountlist(temp_dropdown);
            setCategoryList(body.data);
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val, key) {
        let sub_series_name = "";
        if (key == "Customer") {
            sub_series_name = "customer_payment";
        }
        if (key == "Dealer") {
            sub_series_name = "dealer_payment";
        }

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": sub_series_name, "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        console.log(body.data)

        if (body.operation == "success") {
            setPaymentNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
        }
        else {
            swal("Alert!", body.message, "warning")
            return false
        }
    }

    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length != 0) {
            setOrganizationDetails(body.find(x => x.organization_id == getCookie("organization_id")))
        }
    }

    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyExchangeRates(body[0])
        }
    }

    useEffect(() => {
        if ((organizationDetails != null) && (currencyList.length > 0)) {
            let t = currencyList.find(x => x.currency_id == organizationDetails.currency)
            setSelectedCurrency({ label: t.currency_code, value: t.currency_id });
            setBaseCurrency(t);
            Reset_Denomination(t.currency_code, "Set");
        }
    }, [organizationDetails, currencyList])

    useEffect(() => {
        if (props.match.params.type !== undefined && props.match.params.type == "invoice" && invoiceList.length > 0 && branchList.length > 0 && transactionSeriesList.length > 0) {
            let inv_d = invoiceList.find(x => x.invoice_id == props.match.params.id);
            getPaymentUser(inv_d.customer_id, "Customer")
            const due_amount = Number(inv_d.invoice_amount) - Number(inv_d.paid_amount)
            if (due_amount > 0) {
                let t = invoiceArray.map(x => { return { ...x } });
                const temp_transaction_series = transactionSeriesList.find(elem => elem.transaction_series_id == inv_d.transaction_series_id);
                const temp_branch = branchList.find(elem => elem.branch_id == inv_d.branch_id);
                const invoice_currency = typeof (inv_d.invoice_currency) == "string" ? JSON.parse(inv_d.invoice_currency) : inv_d.invoice_currency;
                t[0].invoice_number = inv_d.invoice_number
                t[0].invoice_id = inv_d.invoice_id
                t[0].invoice_amount = inv_d.invoice_amount
                t[0].due_amount = due_amount > 0 ? due_amount : 0
                t[0].payment_amount = due_amount
                t[0].invoice_date = moment(inv_d.invoice_date).format("YYYY-MM-DD")
                t[0].invoice_new = false
                t[0].billing_address = typeof (inv_d.billing_address) == "string" ? JSON.parse(inv_d.billing_address) : inv_d.billing_address
                t[0].shipping_address = typeof (inv_d.shipping_address) == "string" ? JSON.parse(inv_d.shipping_address) : inv_d.shipping_address
                t[0].gstin_details = typeof (inv_d.gstin_details) == "string" ? JSON.parse(inv_d.gstin_details) : inv_d.gstin_details
                t[0].invoice_currency = invoice_currency
                t[0].adjust_amount_arr = [];
                t[0].currency_rate = (invoice_currency.currency_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + invoice_currency.currency_code]
                setInvoiceArray(t);
                setSelectedTransactionSeries({ label: temp_transaction_series.series_name, value: inv_d.transaction_series_id });
                getSubSeriesData(inv_d.transaction_series_id, paymentFor.value);
                setSelectedBranch({ label: temp_branch.branch_name, value: temp_branch.branch_id });
                setSelectedPlaceOfSupply({ label: temp_branch.branch_address_state, value: temp_branch.branch_address_state });
                setstate(1);
            }
            else {
                swal("Oops!", `This invoice can't select because invoice amount already processed`, "warning");
                return
            }
        }
    }, [invoiceList, branchList, transactionSeriesList])

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getTransactionSeriesList()
            let p2 = getBranchDetails()
            let p3 = getTimezoneInformation()
            let p4 = selectiveaccounthead("basic", "", "Invoice Payment")
            let p5 = getCurrencies()
            let p6 = getOrganizationDetails()
            let p7 = getCurrencyExchangeRates()
            Promise.all([p1, p2, p3, p4, p5, p6, p7])
                .then(async () => {
                    if (props.match.params.type !== undefined && props.match.params.type == "invoice") {
                        await getFinalizeInvoiceListBySearch(props.match.params.id, "Customer", "");
                    }
                    if ((props.match.params.type === undefined) || (props.match.params.type !== undefined && props.match.params.type == "customer")) {
                        await getPaymentUser("", "Customer");
                        setstate(1)
                        //console.log("all apis done")
                    }
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { window.location.href = "/payment-received-list" })
                })
        }
    }, [user, visibility, read, write])


    useEffect(() => {
        if (Payment_Type == "Invoice Payment" && invoiceArray.length > 0 && invoiceArray.every(invoice => invoice.invoice_number !== null)) {
            let total_amount = invoiceArray.reduce((p, o) => {
                if (!o.invoice_new) {
                    return p + ((Number(o.invoice_currency.exchange_rate.organization.rate) / Number(o.invoice_currency.exchange_rate.invoice.rate)) * Number(o.payment_amount))
                }
                else {
                    return p + Number(o.payment_amount);
                }
            }, 0);
            let adjustable_amount = invoiceArray.reduce((p1, o1) => {
                if (!o1.invoice_new) {
                    return p1 + o1.adjust_amount_arr.reduce((p2, o2) => { return p2 + (Number(o1.invoice_currency.exchange_rate.organization.rate) / Number(o1.invoice_currency.exchange_rate.invoice.rate)) * Number(o2.adjustable_balance) }, 0);
                }
                else {
                    return p1 + o1.adjust_amount_arr.reduce((p2, o2) => { return p2 + Number(o2.adjustable_balance) }, 0);
                }
            }, 0)
            let payment_amount = Number(total_amount) - Number(adjustable_amount);
            setCalculation_info({ "total_amount": total_amount, "adjustable_amount": adjustable_amount, "payment_amount": payment_amount });
        }
    }, [invoiceArray, Payment_Type])


    const Reset_Denomination = (val, type) => {
        if (val === "INR") {
            setamtArray([
                { "category": 500, "count": 0, "amount": 0 },
                { "category": 200, "count": 0, "amount": 0 },
                { "category": 100, "count": 0, "amount": 0 },
                { "category": 50, "count": 0, "amount": 0 },
                { "category": 20, "count": 0, "amount": 0 },
                { "category": 10, "count": 0, "amount": 0 }
            ]);
        }
        else {
            setamtArray([
                { "category": 500, "count": 0, "amount": 0 },
                { "category": 200, "count": 0, "amount": 0 },
                { "category": 100, "count": 0, "amount": 0 },
                { "category": 50, "count": 0, "amount": 0 },
                { "category": 20, "count": 0, "amount": 0 },
                { "category": 10, "count": 0, "amount": 0 }
            ]);
        }
    }

    const newGstInSubmit = () => {

        var object = {};

        if (upsertGstInNumber === null) {
            swal("Warning!", "GSTIN cannot be empty", "warning");
            return
        }
        if (upsertGstInLegalName === null) {
            swal("Warning!", "Business Legal Name cannot be empty", "warning");
            return
        }
        if (upsertGstInSelectedPlaceOfSupply === null) {
            swal("Warning!", "Supply place cannot be empty", "warning");
            return
        }

        object.GstIn = upsertGstInNumber;
        let api = "";
        if (paymentFor.value == "Customer") {
            object.dealer_id = paymentValue.value;
            api = "save-dealer-tax-details";
        }
        else if (paymentFor.value == "Dealer") {
            object.dealer_id = paymentValue.value;
            api = "save-dealer-tax-details";
        }

        object.gstTreatment = upsertGstInTreatment.value;
        object.place_of_supply = upsertGstInSelectedPlaceOfSupply.value;
        object.legal_name = upsertGstInLegalName;
        object.trade_name = upsertGstInTradeName;
        object.id = upsertGstInId;

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object)
        setUpsertGstInSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-dealer-tax-details`, object, headers).then((res) => {
            setUpsertGstInSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Gstin details updated successfully!", "success");
                setUpsertGstInModalShow(false);
                getPaymentUserGstinDetails(paymentValue.value);
                setUpsertGstInId("");
                setUpsertGstInTreatment(null)
                setUpsertGstInNumber("");
                setUpsertGstInLegalName("");
                setUpsertGstInTradeName("");
                setUpsertGstInSelectedPlaceOfSupply(null)
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;

        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames]
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if (status === "error_file_size") {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const getUploadParams2 = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId);
        body.append('organization_id', organization_id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }

        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-payment-received-documents`, headers, body }
    }

    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(name)
        return (

            <div className="preview-box">
                <img src={previewUrl} style={{ maxHeight: "80px", maxWidth: "80px", borderRadius: "4px", marginRight: "10px" }} />
                <span title={name} className="name">{name.length >= 10 ? name.slice(0, 10) + "...." : name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" onClick={remove} >
                    <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                        <g>
                            <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                        </g>
                    </svg>
                </span>}
                {
                    status === "done" &&
                    <span className="dzu-previewButton remove" onClick={remove} >
                        <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                            <g>
                                <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                            </g>
                        </svg>
                    </span>
                }
            </div>
        )
    }

    const load_configuration_info = (val, index) => {
        let invoice_info = [...invoiceArray];
        invoice_info[index].payment_mode = val;
        if (val == "Cheque") {
            invoice_info[index].configuration_obj = { cheque_no: "", bank_name: "", cheque_date: "", notes: "" };
        }
        else if (val == "Bank Transfer") {
            invoice_info[index].configuration_obj = { date: "", bank_name: "", transaction_id: "", notes: "" };
        }
        else if (val == "Credit Card") {
            invoice_info[index].configuration_obj = { card_no: "", card_holder_name: "", expiry_date: "", notes: "" };
        }
        else {
            Reset_Denomination(baseCurrency.currency_code, "Set");
            invoice_info[index].configuration_obj = { denomination: [], notes: "" };
        }
        setInvoiceArray(invoice_info);
        setconfigurationIndex(index);
    }


    useEffect(() => {
        let state_arr = s_a.s_a[country_arr.country_arr.findIndex((obj) => obj == "India")].split("|");
        let obj_array = [];
        for (let i = 0; i < state_arr.length; i++) {
            obj_array.push({ value: state_arr[i], label: state_arr[i] });
        }
        setSupplyStateOptions(obj_array);
    }, []);

    useEffect(() => {
        if (paymentValue !== null) {
            let object = {};
            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }
            object.organization_id = organization_id;
            object.page_name = page_name;
            object.payment_user_id = paymentValue.value;
            axios.post(`${process.env.REACT_APP_SERVER_URL}/check-user-wise-advance-payment-list`, object, headers).then((res) => {
                if (res.data.operation === "success") {
                    let temp_arr = [];
                    res.data.data.map((obj) => {
                        temp_arr.push({ unique_id: obj.unique_id, Payment_id: obj.transaction_number, adjustable_balance: 0, available_balance: obj.available_amount, total_balance: obj.payment_amount, payment_received_currency: (obj.payment_received_currency) });
                    })
                    setAdvancePaymentList(temp_arr);
                }
            })
        }
    }, [paymentValue]);

    const newPaymentSubmit = (val) => {
        var object = {};
        object.organization_id = organization_id;
        object.file_names = fileNames;
        object.unique_id = fileSessionUniqueId
        object.paymentFor = paymentFor.value;
        object.payment_type = Payment_Type;
        object.payment_user_id = paymentValue.value;
        object.branch_id = selectedBranch.value;
        object.transaction_series_id = selectedTransactionSeries.value;
        object.payment_number = PaymentNumber;
        object.payment_date = new Date(Payment_date);
        object.invoice = referenceNumber.trim();
        object.payment_amount = PaymentAmount;
        object.base_exchange_rate = baseExchangeRate;
        object.notes = Notes;
        if (Payment_Type == "Advance Payment") {
            object.billing_address = selectedBillingAddress;
            object.shipping_address = selectedShippingAddress;
            object.tax_details = selectedGstin;
            object.deposit_through_account = DepositThroughAccount.value;
            object.deposit_through_account_category = DepositThroughAccountlist.find(x => x.options.map(a => a.value).includes(DepositThroughAccount.value)).id;
            object.payment_mode = PaymentMode.value;
            object.configuration_obj = Configuration_obj;
            let org_c_code = baseCurrency.currency_code
            let org_c_rate = (org_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + org_c_code]
            let ord_c_rate = (baseCurrency.currency_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + baseCurrency.currency_code]
            let exchange_rate = {
                organization: { base_curr: "USD", quote_curr: org_c_code, rate: org_c_rate },
                payment_received: { base_curr: "USD", quote_curr: baseCurrency.currency_code, rate: ord_c_rate },
            }

            let ic = baseCurrency
            object.payment_received_currency = { currency_id: ic.currency_id, currency_symbol: ic.currency_symbol, currency_code: ic.currency_code, format: ic.format, decimal_place: ic.decimal_place, exchange_rate: exchange_rate }
        }
        else {
            object.invoice_info = invoiceArray;
        }

        object.Calculation_info = Calculation_info;
        object.page_name = page_name;
        object.advance_payment_list = object.advance_payment_list = advancePaymentList.filter(elem => elem.adjustable_balance > 0);;

        object.save_flag = val
        object.organization_id = organization_id
        object.page_name = page_name;

        //console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-payment-received`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Payment received created as ${res.data.data.saved_ref} and saved successfully!`, "success").then((value) => { window.location.href = `/payment-received-list` });
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Reference Number already exists!!", "error");
            }
            else if (res.data.message === "invoice_already_paid") {
                swal("Oops!", `This ${res.data.data.join(",")} Invoice Number already paid!!`, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const validatePayment = () => {
        if (paymentFor == null) {
            swal("Oops!", "Choose an Payment For", "error");
            return
        }
        if (paymentValue == null) {
            swal("Oops!", `Choose an ${paymentFor.value}`, "error");
            return
        }
        if (PaymentNumber == "") {
            swal("Oops!", `Transaction number can't be null`, "error");
            return
        }

        if (Payment_Type === "Advance Payment" && PaymentAmount === 0) {
            swal("Oops!", `Payment Amount should be greater than zero.`, "error");
            return
        }
        if (Payment_Type !== "Advance Payment") {
            if (Calculation_info.payment_amount <= 0) {
                swal("Oops!", `Payment Amount should be greater than zero.`, "error");
                return
            }
            for (let i = 0; i < invoiceArray.length; i++) {
                let obj = invoiceArray[i]
                if (obj.invoice_number == null) {
                    swal("Oops!", "Choose or Create an Invoice", "error");
                    return
                }
                if (obj.invoice_date == "") {
                    swal("Oops!", "Enter an Invoice Date", "error");
                    return
                }
                if (obj.payment_amount == 0) {
                    swal("Oops!", "Payment amount should be greater than zero", "error");
                    return
                }
            }
        }
        return true
    }

    const clear_invoice_details = () => {
        let temp = invoiceArray.map(x => { return { ...x } });
        temp.map((obj, index) => {
            obj.invoice_number = null
            obj.invoice_id = null
            obj.invoice_date = ""
            obj.invoice_amount = 0
            obj.due_amount = 0
            obj.payment_amount = 0
            obj.invoice_new = true
            obj.billing_address = null
            obj.shipping_address = null
            obj.gstin_details = null
            obj.invoice_currency = baseCurrency
            obj.adjust_amount_arr = [];
            obj.currency_rate = (baseCurrency.currency_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + baseCurrency.currency_code]
        })
        setInvoiceArray(temp);
    }

    const pay_full_amount = (obj, index, type) => {
        let temp = invoiceArray.map(x => { return { ...x } });
        if (type === "individual") {
            if (obj.due_amount > 0) {
                temp[index].payment_amount = obj.due_amount;
            }
            else {
                temp[index].payment_amount = obj.invoice_amount;
            }
        }
        setInvoiceArray(temp);
    }

    const handleAddressModalClose = () => {
        setAddressModalShow(false)
        setAddressType(null)
        setInvoiceIndexAddress(null)
        setAddressId(null)
    }

    const handleGstinModalClose = () => {
        setGstinModalShow(false)
        setInvoiceIndexGstin(null)
        setGstinId(null)
    }

    const Config_set_up = (val) => {
        if (val == "Cheque") {
            setConfiguration_obj({ cheque_no: "", bank_name: "", cheque_date: "", Notes: "" });
        }
        else if (val == "Bank_Transfer") {
            setConfiguration_obj({ date: "", bank_name: "", transaction_id: "", Notes: "" });
        }
        else if (val == "Credit_Card") {
            setConfiguration_obj({ card_no: "", card_holder_name: "", expiry_date: "", Notes: "" });
        }
        else {
            Reset_Denomination(baseCurrency.currency_code, "Set");
            setConfiguration_obj({ denomination: [], Notes: "" });
        }
    }

    const resetAdvanceAdjustment = () => {
        let advanceArr = advancePaymentList.map(x => { return { ...x } });
        advanceArr.forEach((obj) => {
            obj.adjustable_balance = 0;
            //obj.available_balance = obj.total_balance;
        })
        setAdvancePaymentList(advanceArr);
        let invoiceArr = invoiceArray.map(x => { return { ...x } })
        invoiceArr[invoiceIndexAdjustment].adjust_amount_arr = [];
        setInvoiceArray(invoiceArr);
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">New Payment Received

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 1 ?
                                        <>
                                            <div className="row mb-sm-5 gx-0 ">
                                                {paymentFor !== null &&
                                                    <div className="col-md-4 px-1 mb-2">
                                                        <label className="required fs-5 fw-bold mb-2">{paymentFor.value} Name</label>
                                                        <Select
                                                            options={paymentValueList}
                                                            onChange={(val) => {
                                                                setpaymentValue(val);
                                                                getFinalizeInvoiceListBySearch("", paymentFor.value, val.value);
                                                                getPaymentUserGstinDetails(val.value, paymentFor.value);
                                                                getUserAddressDetails(val.value, paymentFor.value);
                                                                clear_invoice_details();
                                                            }}
                                                            value={paymentValue}
                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                            isLoading={PaymentUserApiState}
                                                            onInputChange={(val) => { (val.length > search_length) && getPaymentUser(val, "Customer"); }}
                                                            isDisabled={props.match.params.id}
                                                        />
                                                    </div>}
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Payment Date</label>
                                                    <input type="date" className="form-control" placeholder="Sales Start Date" value={Payment_date}
                                                        onChange={(e) => {
                                                            setPayment_date(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {((props.match.params.type === undefined) || (props.match.params.type !== undefined && props.match.params.type == "customer")) &&
                                                    <div className="col-md-4">
                                                        <label className="fs-5 fw-bold mb-2">Payment Type</label>
                                                        <div className="d-flex align-items-center">
                                                            <div className={`rounded mx-2 px-6 py-2 cursor-pointer ${Payment_Type == "Invoice Payment" && "bg-primary"}`} onClick={() => { setPayment_Type("Invoice Payment"); }}>Invoice Payment</div>
                                                            <div className={`rounded mx-2 px-6 py-2 cursor-pointer ${Payment_Type == "Advance Payment" && "bg-primary"}`} onClick={() => { setPayment_Type("Advance Payment"); }}>Advance Payment</div>
                                                        </div>
                                                    </div>}
                                            </div>
                                            {Payment_Type == "Advance Payment" &&
                                                <>
                                                    <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: paymentValue != null ? "1" : "0", visibility: paymentValue != null ? "visible" : "hidden", height: paymentValue != null ? "auto" : "0", marginBottom: paymentValue != null ? "1rem" : "0" }} >
                                                        <div className="col-md-6 px-1">
                                                            <div className="card card-dashed p-6 flex-row">
                                                                <div className="d-flex flex-column flex-grow-1">
                                                                    <div className="d-flex align-items-center fs-5 fw-bold mb-5">Billing Address</div>
                                                                    {
                                                                        selectedBillingAddress != null ?
                                                                            <>
                                                                                {(selectedBillingAddress.address_line1 != "" && selectedBillingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.address_line1} ${selectedBillingAddress.address_line2}`}</div>}
                                                                                <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.city} ${selectedBillingAddress.state}, ${selectedBillingAddress.pincode} ${selectedBillingAddress.country}`}</div>
                                                                            </>
                                                                            :
                                                                            <div className='text-hover-primary'>Select an address</div>
                                                                    }
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <button onClick={(e) => { setAddressModalShow(true); setAddressList(totalAddressList); setAddressId(selectedBillingAddress == null ? null : selectedBillingAddress.id); setAddressType("billing") }} className="btn btn-sm px-3">
                                                                        <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 px-1">
                                                            <div className="card card-dashed p-6 flex-row">
                                                                <div className="d-flex flex-column flex-grow-1">
                                                                    <div className="d-flex align-items-center fs-5 fw-bold mb-5">Shipping Address</div>
                                                                    {
                                                                        selectedShippingAddress != null ?
                                                                            <>
                                                                                {(selectedShippingAddress.address_line1 != "" && selectedShippingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.address_line1} ${selectedShippingAddress.address_line2}`}</div>}
                                                                                <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.city} ${selectedShippingAddress.state}, ${selectedShippingAddress.pincode} ${selectedShippingAddress.country}`}</div>
                                                                            </>
                                                                            :
                                                                            <div className='text-hover-primary'>Select an address</div>
                                                                    }
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <button onClick={(e) => { setAddressModalShow(true); setAddressList(totalAddressList); setAddressId(selectedShippingAddress == null ? null : selectedShippingAddress.id); setAddressType("shipping") }} className="btn btn-sm px-3">
                                                                        <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: paymentValue != null ? "1" : "0", visibility: paymentValue != null ? "visible" : "hidden", height: paymentValue != null ? "auto" : "0", marginBottom: paymentValue != null ? "1rem" : "0" }} >
                                                        <div className="col-12">
                                                            <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 rounded">
                                                                <h5>GSTIN Details</h5>
                                                                <div className="d-flex flex-wrap align-items-center gap-1">
                                                                    {
                                                                        selectedGstin != null ?
                                                                            <>
                                                                                <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                                    <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.gst_treatment}</span>
                                                                                    <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                                </div>
                                                                                {selectedGstin.gst_treatment == "Registered Business" &&
                                                                                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                                        <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.GstIn}</span>
                                                                                        <div className="fw-semibold text-gray-400">GSTIN</div>
                                                                                    </div>}
                                                                            </> :
                                                                            <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                                <span className="fs-6 text-gray-700 fw-bold">No GSTIN selected</span>
                                                                            </div>
                                                                    }
                                                                    <div>
                                                                        <button onClick={(e) => { setGstinModalShow(true); setGstinId(selectedGstin == null ? null : selectedGstin.unique_id); setGstinList(totalGstinList); }} className="btn btn-sm px-3">
                                                                            <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="row mb-sm-5 gx-0 ">
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })}
                                                        onChange={(val) => {
                                                            swal("Alert!", "Please verify applicable taxes for selected items", "info")
                                                            setSelectedBranch(val);
                                                            setSelectedTransactionSeries(null)
                                                        }}
                                                        value={selectedBranch}
                                                        isDisabled={props.match.params.type !== undefined && props.match.params.type == "invoice"}
                                                        placeholder="Select Branch"
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={selectedBranch != null && transactionSeriesList.filter(x => branchList.find(a => a.branch_id == selectedBranch.value).transaction_series_id.split(",").includes(x.transaction_series_id)).map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                                        onChange={(val) => {
                                                            setSelectedTransactionSeries(val);
                                                            if (paymentFor == null) {
                                                                swal("Oops!", "Select payment for field first", "error");
                                                            }
                                                            else {
                                                                getSubSeriesData(val.value, paymentFor.value)
                                                            }
                                                        }
                                                        }

                                                        value={selectedTransactionSeries}
                                                        placeholder="Select Transaction Series"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={PaymentNumber} readOnly />
                                                </div>
                                            </div>
                                            {Payment_Type == "Advance Payment" &&
                                                <>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6">
                                                            <label className="required fs-5 fw-bold mb-2">Deposit Through</label>
                                                            <Select
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                options={DepositThroughAccountlist}
                                                                onChange={(event) => { setDepositThroughAccount(event); }}
                                                                value={DepositThroughAccount}
                                                                components={{
                                                                    MenuList: (props) => {
                                                                        return (
                                                                            <components.MenuList  {...props}>
                                                                                {props.children}
                                                                                <div className='d-flex justify-content-center'>
                                                                                    <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setAddAccHeadModalShow(true) }} >&#10011; Add  Account head</button>
                                                                                </div>
                                                                            </components.MenuList >
                                                                        )
                                                                    }
                                                                }}
                                                            //isDisabled={Payment_Type === "Advance Payment"}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="fs-5 fw-bold mb-2">Payment Mode</label>
                                                            <Select
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                options={PaymentModeList}
                                                                onChange={(val) => { Config_set_up(val.value); setPaymentMode(val) }}
                                                                value={PaymentMode}
                                                                isClearable
                                                            />
                                                            <a className="cursor-pointer" onClick={(e) => { setAdvanceConfigurationModalShow(true); }}>{PaymentMode != null && <span className="text-primary fs-6">Configure {PaymentMode.label} Mode</span>}</a>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="row mb-5">
                                                {Payment_Type == "Advance Payment" &&
                                                    <>
                                                        <div className="col-md-3">
                                                            <label className="fs-5 fw-bold mb-2 required">Currency</label>
                                                            <Select
                                                                options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id, currency_symbol: x.currency_symbol } })}
                                                                onChange={(val) => { setSelectedCurrency(val) }}
                                                                value={selectedCurrency}
                                                                // components={{
                                                                //     MenuList: (props) => {
                                                                //         return (
                                                                //             <components.MenuList  {...props}>
                                                                //                 {props.children}
                                                                //                 <div className='d-flex justify-content-center'>
                                                                //                     <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setCurrencyModalShow(true) }} >&#10011; Add New Currency</button>
                                                                //                 </div>
                                                                //             </components.MenuList >
                                                                //         )
                                                                //     }
                                                                // }}
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="required fs-5 fw-bold mb-2">Amount</label>
                                                            <div className="input-group input-group-solid mb-5">
                                                                {selectedCurrency !== null && <span className="input-group-text">{selectedCurrency.currency_symbol}</span>}
                                                                <input type="number" className="form-control" value={PaymentAmount.toString()} min="0"
                                                                    onChange={(e) => { setPaymentAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <hr />

                                            <div className="table-responsive">
                                                {Payment_Type == "Invoice Payment" &&
                                                    <>
                                                        <div className='container-fluid'>
                                                            {
                                                                invoiceArray.map((obj, i) => {
                                                                    return (
                                                                        <div key={i} className='container bg-secondary my-2 p-5 rounded'>
                                                                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                                <div className='fs-4 fw-bold'>Invoice: #{i + 1}</div>
                                                                                <div>
                                                                                    <button className={`btn btn-link text-hover-success mx-2 ${obj.billing_address == null && "required"}`}
                                                                                        onClick={() => {
                                                                                            if (paymentFor == null) {
                                                                                                swal("Oops!", `Select payment for field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            if (paymentValue == null) {
                                                                                                swal("Oops!", `Select a ${paymentFor.value} first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            if (obj.invoice_number == null) {
                                                                                                swal("Oops!", `Select Invoice No. first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                if (obj.invoice_new) {
                                                                                                    setAddressList(totalAddressList);
                                                                                                }
                                                                                                else {
                                                                                                    setAddressList(obj.billing_address == null ? [] : [obj.billing_address]);
                                                                                                }
                                                                                            }
                                                                                            setInvoiceIndexAddress(i);
                                                                                            setAddressId(obj.billing_address == null ? null : obj.billing_address.id);
                                                                                            setAddressType("billing");
                                                                                            setAddressModalShow(true);
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fs-2 px-0 fa-house"></i>
                                                                                    </button>
                                                                                    <button className={`btn btn-link text-hover-success mx-2 ${obj.shipping_address == null && "required"}`}
                                                                                        onClick={() => {
                                                                                            if (paymentFor == null) {
                                                                                                swal("Oops!", `Select payment for field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            if (paymentValue == null) {
                                                                                                swal("Oops!", `Select a ${paymentFor.value} first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            //getUserAddressDetails(paymentValue.value,paymentFor.value);
                                                                                            //console.log(addressListApiState);
                                                                                            //console.log(totalAddressList);
                                                                                            if (obj.invoice_number == null) {
                                                                                                swal("Oops!", `Select Invoice No. first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                if (obj.invoice_new) {
                                                                                                    setAddressList(totalAddressList);
                                                                                                }
                                                                                                else {
                                                                                                    setAddressList(obj.shipping_address == null ? [] : [obj.shipping_address]);
                                                                                                }
                                                                                            }
                                                                                            setInvoiceIndexAddress(i);
                                                                                            setAddressId(obj.shipping_address == null ? null : obj.shipping_address.id);
                                                                                            setAddressType("shipping");
                                                                                            setAddressModalShow(true);
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fs-2 px-0 fa-truck"></i>
                                                                                    </button>
                                                                                    <button className={`btn btn-link text-hover-success mx-2 ${obj.gstin_details == null && "required"}`}
                                                                                        onClick={() => {
                                                                                            if (paymentFor == null) {
                                                                                                swal("Oops!", `Select payment for field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            if (paymentValue == null) {
                                                                                                swal("Oops!", `Select a ${paymentFor.value} first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            if (obj.invoice_number == null) {
                                                                                                swal("Oops!", `Select Invoice No. first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                if (obj.invoice_new) {
                                                                                                    setGstinList(totalGstinList);
                                                                                                }
                                                                                                else {
                                                                                                    setGstinList([obj.gstin_details]);
                                                                                                }
                                                                                            }
                                                                                            //getPaymentUserGstinDetails(obj.dealer_id);
                                                                                            setInvoiceIndexGstin(i);
                                                                                            setGstinId(obj.gstin_details == null ? null : obj.gstin_details.unique_id);
                                                                                            setGstinModalShow(true);
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-solid fs-2 px-0 fa-coins"></i>
                                                                                    </button>
                                                                                    {
                                                                                        invoiceArray.length > 1 &&
                                                                                        <button className='btn btn-link text-hover-danger mx-3'
                                                                                            onClick={() => {
                                                                                                let t = invoiceArray.map(x => { return { ...x } })
                                                                                                t.splice(i, 1)
                                                                                                setInvoiceArray(t)
                                                                                            }}
                                                                                        >&#10006;</button>
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                            <div className="row mb-5 gx-0 ">
                                                                                <div className="col-md-4 px-1 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Invoice No.</label>
                                                                                    <CreatableSelect
                                                                                        options={invoiceList.map(x => { return { label: x.invoice_number, value: x.invoice_id } })}
                                                                                        value={obj.invoice_number == null ? null : { label: obj.invoice_number, value: obj.invoice_id }}
                                                                                        onChange={(val) => {
                                                                                            if (invoiceArray.find(a => a.invoice_number == val.label) !== undefined) {
                                                                                                swal("Warning!", "Invoice already selected", "warning");
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                if (val.hasOwnProperty("__isNew__") && val["__isNew__"] == true) {
                                                                                                    let t = invoiceArray.map(x => { return { ...x } })
                                                                                                    t[i].invoice_number = val.label
                                                                                                    t[i].invoice_id = null
                                                                                                    t[i].invoice_date = ""
                                                                                                    t[i].invoice_amount = 0
                                                                                                    t[i].due_amount = 0
                                                                                                    t[i].payment_amount = 0
                                                                                                    t[i].invoice_new = true
                                                                                                    t[i].billing_address = null
                                                                                                    t[i].shipping_address = null
                                                                                                    t[i].gstin_details = null
                                                                                                    t[i].invoice_currency = baseCurrency
                                                                                                    t[i].adjust_amount_arr = [];
                                                                                                    t[i].currency_rate = (baseCurrency.currency_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + baseCurrency.currency_code]
                                                                                                    setInvoiceArray(t);
                                                                                                }
                                                                                                else {
                                                                                                    let inv_d = invoiceList.find(x => x.invoice_id == val.value)
                                                                                                    const due_amount = Number(inv_d.invoice_amount) - Number(inv_d.paid_amount)
                                                                                                    if (due_amount > 0) {
                                                                                                        let t = invoiceArray.map(x => { return { ...x } })
                                                                                                        const invoice_currency = typeof (inv_d.invoice_currency) == "string" ? JSON.parse(inv_d.invoice_currency) : inv_d.invoice_currency;
                                                                                                        t[i].invoice_number = val.label
                                                                                                        t[i].invoice_id = val.value
                                                                                                        t[i].invoice_amount = inv_d.invoice_amount
                                                                                                        t[i].due_amount = due_amount > 0 ? due_amount : 0
                                                                                                        t[i].payment_amount = due_amount
                                                                                                        t[i].invoice_date = moment(inv_d.invoice_date).format("YYYY-MM-DD")
                                                                                                        t[i].invoice_new = false
                                                                                                        t[i].billing_address = typeof (inv_d.billing_address) == "string" ? JSON.parse(inv_d.billing_address) : inv_d.billing_address
                                                                                                        t[i].shipping_address = typeof (inv_d.shipping_address) == "string" ? JSON.parse(inv_d.shipping_address) : inv_d.shipping_address
                                                                                                        t[i].gstin_details = typeof (inv_d.gstin_details) == "string" ? JSON.parse(inv_d.gstin_details) : inv_d.gstin_details
                                                                                                        t[i].invoice_currency = invoice_currency
                                                                                                        t[i].adjust_amount_arr = [];
                                                                                                        t[i].currency_rate = (invoice_currency.currency_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + baseCurrency.currency_code]
                                                                                                        setInvoiceArray(t);
                                                                                                    }
                                                                                                    else {
                                                                                                        swal("Oops!", `This invoice can't select because invoice amount already processed`, "warning");
                                                                                                        return
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onInputChange={(val) => {
                                                                                            //getFinalizeInvoiceListBySearch(val,paymentFor.value)
                                                                                            if (paymentFor == null) {
                                                                                                swal("Oops!", `Select payment for field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else if (paymentValue == null) {
                                                                                                swal("Oops!", `Select payment value field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                getFinalizeInvoiceListBySearch(val, paymentFor.value, paymentValue.value)
                                                                                            }
                                                                                        }}
                                                                                        onMenuClose={() => {
                                                                                            //getFinalizeInvoiceListBySearch("",paymentFor.value)
                                                                                            if (paymentFor == null) {
                                                                                                swal("Oops!", `Select payment for field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else if (paymentValue == null) {
                                                                                                swal("Oops!", `Select payment value field first`, "warning")
                                                                                                return
                                                                                            }
                                                                                            else {
                                                                                                getFinalizeInvoiceListBySearch("", paymentFor.value, paymentValue.value)
                                                                                            }
                                                                                        }}
                                                                                        isLoading={invoiceListApiState}
                                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                        placeholder="Choose/Create the invoice"
                                                                                        isDisabled={props.match.params.type !== undefined && props.match.params.type == "invoice"}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-4 px-1 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Account</label>
                                                                                    <Select
                                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                        options={DepositThroughAccountlist}
                                                                                        onChange={(val) => {
                                                                                            let temp = invoiceArray.map(x => { return { ...x } });
                                                                                            temp[i].deposit_through_account = val.value;
                                                                                            temp[i].deposit_through_account_name = val.label;
                                                                                            temp[i].deposit_through_account_category = DepositThroughAccountlist.find(x => x.options.map(a => a.value).includes(val.value)).id;
                                                                                            setInvoiceArray(temp);

                                                                                        }}
                                                                                        isDisabled={props.match.params.type !== undefined && props.match.params.type == "invoice"}
                                                                                        value={obj.deposit_through_account ? { label: obj.deposit_through_account_name, value: obj.deposit_through_account } : null}
                                                                                        components={{
                                                                                            MenuList: (props) => {
                                                                                                return (
                                                                                                    <components.MenuList  {...props}>
                                                                                                        {props.children}
                                                                                                        <div className='d-flex justify-content-center'>
                                                                                                            <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setSelectedIndex(i); setAddAccHeadModalShow(true) }} >&#10011; Add  Account head</button>
                                                                                                        </div>
                                                                                                    </components.MenuList >
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-4 px-1 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Payment Mode</label>
                                                                                    <Select
                                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                        options={PaymentModeList}
                                                                                        onChange={(val) => {
                                                                                            load_configuration_info(val.value, i)
                                                                                        }}
                                                                                        value={{ label: obj.payment_mode, value: obj.payment_mode }}
                                                                                        isClearable
                                                                                    />
                                                                                    <a className="cursor-pointer" onClick={(e) => { setconfigurationIndex(i); setConfigurationModalShow(true); }}>{obj.payment_mode != null && <span className="text-primary fs-6">Configure {obj.payment_mode} Mode</span>}</a>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-5 gx-0 ">
                                                                                <div className="col-md-3 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Invoice Date</label>
                                                                                    <input type="date" className="form-control form-control-solid" value={obj.invoice_date} disabled={!obj.invoice_new} readOnly={!obj.invoice_new}
                                                                                        onChange={(e) => {
                                                                                            let t = invoiceArray.map(x => { return { ...x } })
                                                                                            t[i].invoice_date = e.target.value
                                                                                            setInvoiceArray(t);
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                                {/* {console.log(obj.invoice_currency)} */}
                                                                                <div className="col-md-3 px-1 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Invoice Amount</label>
                                                                                    <div className="input-group input-group-solid">
                                                                                        <span className="input-group-text" id="basic-addon1">{obj.invoice_currency.currency_symbol}</span>
                                                                                        <input type="number" className="form-control" value={obj.invoice_amount.toString()} disabled={!obj.invoice_new} readOnly={!obj.invoice_new}
                                                                                            onChange={(e) => {
                                                                                                let t = invoiceArray.map(x => { return { ...x } })
                                                                                                t[i].invoice_amount = e.target.value == "" ? 0 : parseFloat(e.target.value);
                                                                                                t[i].payment_amount = e.target.value == "" ? 0 : parseFloat(e.target.value);
                                                                                                setInvoiceArray(t);
                                                                                            }} />
                                                                                    </div>
                                                                                    {(!obj.invoice_new && obj.invoice_number !== null && obj.invoice_currency.exchange_rate.organization.quote_curr !== obj.invoice_currency.exchange_rate.invoice.quote_curr) &&
                                                                                        <span className="text-primary fs-6">1 {obj.invoice_currency.exchange_rate.organization.quote_curr} = {(Number(obj.invoice_currency.exchange_rate.organization.rate) / Number(obj.invoice_currency.exchange_rate.invoice.rate))} {obj.invoice_currency.exchange_rate.invoice.quote_curr}</span>}
                                                                                </div>
                                                                                {(obj.invoice_new) ?
                                                                                    <div className="col-md-3 px-1 mb-2">
                                                                                        <label className="fs-5 fw-bold mb-2 required">Currency</label>
                                                                                        <Select
                                                                                            options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id, currency_symbol: x.currency_symbol } })}
                                                                                            onChange={(val) => {
                                                                                                let temp = invoiceArray.map(x => { return { ...x } });
                                                                                                temp[i].invoice_currency = val;
                                                                                                temp[i].currency_rate = currencyExchangeRates.data.quotes["USD" + val.label];
                                                                                                setInvoiceArray(temp);
                                                                                            }}
                                                                                            value={obj.invoice_currency}
                                                                                            // components={{
                                                                                            //     MenuList: (props) => {
                                                                                            //         return (
                                                                                            //             <components.MenuList  {...props}>
                                                                                            //                 {props.children}
                                                                                            //                 <div className='d-flex justify-content-center'>
                                                                                            //                     <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setCurrencyModalShow(true) }} >&#10011; Add New Currency</button>
                                                                                            //                 </div>
                                                                                            //             </components.MenuList >
                                                                                            //         )
                                                                                            //     }
                                                                                            // }}
                                                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div className="col-md-3 px-1 mb-2">
                                                                                        <label className="fs-5 fw-bold mb-2 required">Due Amount</label>
                                                                                        <div className="input-group input-group-solid">
                                                                                            <span className="input-group-text" id="basic-addon1">{obj.invoice_currency.currency_symbol}</span>
                                                                                            <input type="number" className="form-control" value={obj.due_amount.toString()} disabled={!obj.invoice_new} readOnly={!obj.invoice_new} />
                                                                                        </div>
                                                                                        {!obj.invoice_new && <a onClick={(e) => { pay_full_amount(obj, i, "individual") }} className="cursor-pointer"><span className="text-primary fs-6">Pay full amount</span></a>}
                                                                                    </div>
                                                                                }

                                                                                <div className="col-md-3 px-1 mb-2">
                                                                                    <label className="fs-5 fw-bold mb-2 required">Payment Amount</label>
                                                                                    <div className="input-group input-group-solid">
                                                                                        <span className="input-group-text" id="basic-addon1">{obj.invoice_currency.currency_symbol}</span>
                                                                                        <input type="number" className="form-control" value={obj.payment_amount.toString()}
                                                                                            onChange={(event) => {
                                                                                                let temp = invoiceArray.map(x => { return { ...x } });
                                                                                                if (parseFloat(event.target.value) >= 0) {
                                                                                                    let amount = event.target.value == "" ? 0 : parseFloat(event.target.value);
                                                                                                    let warning_flag = false;
                                                                                                    if (obj.invoice_new == false && obj.due_amount !== 0 && amount > obj.due_amount) {
                                                                                                        swal("Oops!", "Amount cannot be greater than Due Amount", "warning");
                                                                                                        temp[i].payment_amount = obj.due_amount;
                                                                                                        warning_flag = true;
                                                                                                    }
                                                                                                    if (obj.invoice_new == false && obj.invoice_amount !== 0 && amount > obj.invoice_amount) {
                                                                                                        swal("Oops!", "Amount cannot be greater than Invoice Amount", "warning");
                                                                                                        temp[i].payment_amount = obj.due_amount;
                                                                                                        warning_flag = true;
                                                                                                    }
                                                                                                    if (warning_flag == false) {
                                                                                                        temp[i].payment_amount = amount;
                                                                                                    }
                                                                                                    setInvoiceArray(temp);
                                                                                                }
                                                                                            }}
                                                                                            readOnly={props.match.params.type !== undefined && props.match.params.type == "invoice"}
                                                                                            disabled={props.match.params.type !== undefined && props.match.params.type == "invoice"}
                                                                                        />
                                                                                    </div>
                                                                                    {obj.payment_amount > 0 && trimDecimalPlaces(advancePaymentList.filter(elem => elem.payment_received_currency.currency_code == obj.invoice_currency.currency_code).reduce((p, o) => { return p + (Number(o.available_balance)) }, 0), baseCurrency == null ? 3 : baseCurrency.decimal_place) > 0 &&
                                                                                        <a className="cursor-pointer" onClick={(e) => {
                                                                                            setInvoiceIndexAdjustment(i);
                                                                                            if (obj.invoice_new === false) {
                                                                                                setInvoiceIndexCurrency(obj.invoice_currency.currency_code);
                                                                                            }
                                                                                            else {
                                                                                                setInvoiceIndexCurrency(obj.invoice_currency.label);
                                                                                            }
                                                                                            setAdvanceAdjustmentModalShow(true)
                                                                                        }}>
                                                                                            {obj.adjust_amount_arr.reduce((p, o) => { return p + (Number(o.adjustable_balance)) }, 0) == 0 &&
                                                                                                <span className={`text-primary fs-6`}>Adjust amount</span>}
                                                                                        </a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {obj.adjust_amount_arr.reduce((p2, o2) => { return p2 + Number(o2.adjustable_balance) }, 0) > 0 &&
                                                                                <div className="row mb-5 gx-0 ">
                                                                                    <div className="col-md-3 px-1 mb-2">
                                                                                        <label className="fs-5 fw-bold mb-2 required">Adjustable Amount</label>
                                                                                        <div className="input-group input-group-solid">
                                                                                            <span className="input-group-text" id="basic-addon1">{obj.invoice_currency.currency_symbol}</span>
                                                                                            <input type="number" className="form-control" value={obj.adjust_amount_arr.reduce((p2, o2) => { return p2 + Number(o2.adjustable_balance) }, 0)} disabled={true} readOnly={true} />
                                                                                        </div>
                                                                                        <a className="cursor-pointer" onClick={() => {
                                                                                            setInvoiceIndexAdjustment(i);
                                                                                            //obj.adjust_amount_arr=advancePaymentList;
                                                                                            if (obj.invoice_new === false) {
                                                                                                setInvoiceIndexCurrency(obj.invoice_currency.currency_code);
                                                                                            }
                                                                                            else {
                                                                                                setInvoiceIndexCurrency(obj.invoice_currency.label);
                                                                                            }
                                                                                            setAdvanceAdjustmentModalShow(true)
                                                                                        }}>
                                                                                            <span className={`text-success fs-6`}>Edit Adjust amount</span></a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {((props.match.params.type === undefined) || (props.match.params.type !== undefined && props.match.params.type == "customer")) &&
                                                                <button className='btn btn-primary btn-sm m-3' onClick={() => { setInvoiceArray([...invoiceArray, { invoice_number: null, invoice_id: null, invoice_date: "", invoice_new: false, billing_address: null, shipping_address: null, gstin_details: null, invoice_amount: 0, invoice_currency: baseCurrency, due_amount: 0, payment_amount: 0, deposit_through_account: default_acc.value, deposit_through_account_category: default_acc.category, deposit_through_account_name: default_acc.label, payment_mode: "Cash", adjust_amount_arr: [], configuration_obj: { denomination: [], notes: "" } }]) }}>+ Add Invoice</button>}
                                                        </div>
                                                    </>
                                                }
                                                {/* {console.log(invoiceArray)} */}
                                                {(PaymentAmount > 0 || Calculation_info.payment_amount > 0) &&
                                                    <div className="d-flex flex-stack bg-success rounded-3 p-6 mb-11">
                                                        <div className="fs-6 fw-bold text-white">
                                                            {Payment_Type == "Invoice Payment" ?
                                                                <>
                                                                    {(Calculation_info.total_amount > 0 && Calculation_info.adjustable_amount > 0) &&
                                                                        <span className="d-block mb-2">Total Amount</span>}
                                                                    {Calculation_info.adjustable_amount > 0 &&
                                                                        <span className="d-block lh-1 mb-2">Adjustable Amount</span>
                                                                    }
                                                                    {Calculation_info.payment_amount > 0 &&
                                                                        <span className="d-block lh-1 mb-2">Payment Amount</span>
                                                                    }
                                                                </>
                                                                :
                                                                <span className="d-block lh-1 mb-2">Payment Amount</span>
                                                            }
                                                        </div>
                                                        <div className="fs-6 fw-bold text-white text-end">
                                                            {Payment_Type == "Invoice Payment" ?
                                                                <>
                                                                    {(Calculation_info.total_amount > 0 && Calculation_info.adjustable_amount > 0) &&
                                                                        <span className="d-block mb-2">{baseCurrency.currency_symbol}&nbsp;{formatNumber(Calculation_info.total_amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>}
                                                                    {Calculation_info.adjustable_amount > 0 &&
                                                                        <span className="d-block lh-1 mb-2">{baseCurrency.currency_symbol}&nbsp;{formatNumber(Calculation_info.adjustable_amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>}
                                                                    {Calculation_info.payment_amount > 0 &&
                                                                        <span className="d-block lh-1 mb-2">{baseCurrency.currency_symbol}&nbsp;{formatNumber(Calculation_info.payment_amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>}
                                                                </>
                                                                :
                                                                <>
                                                                    {PaymentAmount > 0 &&
                                                                        <span className="d-block lh-1 mb-2">{currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol}&nbsp;{formatNumber(PaymentAmount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>}
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                            <br />
                                            <div className="row mb-5">
                                                <div className="col-md-6 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Notes</label>
                                                    <textarea className="form-control form-control-solid" value={Notes} onChange={(e) => { setNotes(e.target.value) }} placeholder={""}></textarea>
                                                </div>
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Reference</label>
                                                    <textarea type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" onChange={(e) => { setReferences(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Attachments</label>
                                                    <Dropzone
                                                        getUploadParams={getUploadParams2}
                                                        onChangeStatus={handleChangeStatus2}
                                                        //LayoutComponent={CustomLayout}
                                                        PreviewComponent={Preview}
                                                        styles={{
                                                            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                            inputLabelWithFiles: { margin: '20px 3%' }
                                                        }}
                                                        maxFiles={3}
                                                        maxSizeBytes={1024 * 1024 * 2}
                                                        accept="image/*,.pdf"
                                                    />
                                                </div>
                                            </div>
                                            {
                                                write &&
                                                <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validatePayment() && newPaymentSubmit(false) }}>
                                                    <span className="indicator-label">Draft</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }

                                            {
                                                write &&
                                                <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validatePayment() && newPaymentSubmit("pending") }}>
                                                    <span className="indicator-label">Save</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <PageError />
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={advanceAdjustmentModalShow} onHide={() => { setAdvanceAdjustmentModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>Adjust Advance Amount</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAdvanceAdjustmentModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-column gap-3">
                            {(Payment_Type == "Invoice Payment" && invoiceIndexAdjustment !== null && advancePaymentList.length > 0 && invoiceArray.length > 0) &&
                                advancePaymentList.map((obj3, i) => {
                                    if (obj3.payment_received_currency.currency_code == invoiceArray[invoiceIndexAdjustment].invoice_currency.currency_code) {
                                        let current_available_balance = obj3.available_balance - trimDecimalPlaces(invoiceArray.filter(elem => elem.invoice_currency.currency_code == invoiceArray[invoiceIndexAdjustment].invoice_currency.currency_code).reduce((acc, curr) => acc + curr.adjust_amount_arr.reduce((pAcc, pCurr) => {
                                            if (pCurr.ref == obj3.Payment_id) {
                                                return pAcc + pCurr.adjustable_balance;
                                            }
                                            else {
                                                return pAcc;
                                            }
                                        }, 0), 0), baseCurrency == null ? 3 : baseCurrency.decimal_place);
                                        //let current_available_balance = obj3.available_balance - trimDecimalPlaces(invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.reduce((p, o) => { return p + Number(o.adjustable_balance) }, 0), baseCurrency == null ? 3 : baseCurrency.decimal_place)
                                        return (
                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <div className="d-flex align-items-center mb-6 me-4">
                                                        <span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
                                                        <div className="flex-grow-1 me-5">
                                                            <div className="text-gray-800 fw-semibold fs-2">
                                                                {obj3.Payment_id}
                                                            </div>
                                                            <div className="text-gray-400 fw-semibold fs-7 me-1">
                                                                Available Balance: <span className="text-primary opacity-75-hover fw-semibold">{obj3.payment_received_currency.currency_symbol} {current_available_balance}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group input-group-solid">
                                                        <span className="input-group-text">{obj3.payment_received_currency.currency_symbol}</span>
                                                        <input type="number" className="form-control" value={invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.find(x => x.ref === obj3.Payment_id) !== undefined ? invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.find(x => x.ref === obj3.Payment_id).adjustable_balance.toString() : 0} min="0"
                                                            onChange={(e) => {
                                                                let amount = e.target.value == "" ? 0 : parseFloat(e.target.value);
                                                                const current_amount = invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.find(x => x.ref === obj3.Payment_id) !== undefined ? invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.find(x => x.ref === obj3.Payment_id).adjustable_balance.toString() : 0
                                                                if (amount > (current_available_balance + Number(current_amount))) {
                                                                    swal("Oops!", "Amount cannot be greater than available balance", "warning");
                                                                }
                                                                else if (amount > invoiceArray[invoiceIndexAdjustment].payment_amount) {
                                                                    swal("Oops!", "Amount cannot be greater than payment amount", "warning");
                                                                }
                                                                else if (amount > 0) {
                                                                    let t = invoiceArray.map(x => { return { ...x } })
                                                                    let temp = t[invoiceIndexAdjustment].adjust_amount_arr.find(x => x.ref === obj3.Payment_id)
                                                                    if (temp !== undefined) {
                                                                        temp.adjustable_balance = amount;
                                                                    }
                                                                    else {
                                                                        t[invoiceIndexAdjustment].adjust_amount_arr.push({ ref: obj3.Payment_id, adjustable_balance: amount })
                                                                    }
                                                                    setInvoiceArray(t);
                                                                    let t1 = advancePaymentList.map(x => { return { ...x } });
                                                                    t1[i].adjustable_balance = trimDecimalPlaces(invoiceArray[invoiceIndexAdjustment].adjust_amount_arr.reduce((p, o) => { return p + Number(o.adjustable_balance) }, 0), baseCurrency == null ? 3 : baseCurrency.decimal_place);
                                                                    setAdvancePaymentList(t1);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <span className="text-gray fs-6">Adjustable amount</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary rounded me-auto" onClick={() => { resetAdvanceAdjustment(); }}>Reset</button>
                    <button className="btn btn-success rounded" onClick={() => { setAdvanceAdjustmentModalShow(false) }}>Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={addressModalShow} onHide={() => { handleAddressModalClose(); }} centered>
                <Modal.Header>
                    <Modal.Title>Select an Address</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddressModalClose(); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-column gap-3">
                            {
                                addressListApiState ? <div className='d-flex justify-content-center'><div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} ></div></div> :
                                    addressList.length == 0 ? <div className='fs-2 text-gray-600'>No Data Available</div> :
                                        addressList.length > 0 &&
                                        addressList.map((obj, indx) => {
                                            return (
                                                <>
                                                    <div key={indx} className="card card-dashed flex-wrap p-6" style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (Payment_Type == "Invoice Payment") {
                                                                let t = invoiceArray.map(x => { return { ...x } })
                                                                if (addressType == "billing") {
                                                                    t[invoiceIndexAddress].billing_address = obj
                                                                }
                                                                else {
                                                                    t[invoiceIndexAddress].shipping_address = obj
                                                                }
                                                                setInvoiceArray(t);
                                                            }
                                                            else {
                                                                if (addressType == "billing") {
                                                                    setSelectedBillingAddress(obj);
                                                                }
                                                                else {
                                                                    setSelectedShippingAddress(obj);
                                                                }

                                                            }

                                                            handleAddressModalClose()
                                                        }}
                                                    >
                                                        <div className="d-flex flex-column py-2">
                                                            <div className="fs-6 fw-semibold text-gray-600">
                                                                {obj.state}
                                                                <br />{obj.city},{obj.pincode}
                                                                <br />{obj.country}
                                                            </div>
                                                        </div>
                                                        {
                                                            (addressId === obj.id) &&
                                                            <div className='position-absolute' style={{ top: "1rem", right: "1rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            );
                                        })
                            }
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddressModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={advanceConfigurationModalShow} onHide={() => { setAdvanceConfigurationModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure {PaymentMode.label} Mode</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAdvanceConfigurationModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className='container'>
                        {PaymentMode.label == "Cash" ?
                            <>
                                <div className="table-responsive mb-8">
                                    <table className="table align-end gs-0 gy-4 my-0">
                                        <thead>
                                            <tr>
                                                <th className="min-w-100px">Amount</th>
                                                <th className="min-w-75px align-items-center text-center">Count</th>
                                                <th className="text-end min-w-75px">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                amtArray.map((obj3, indx3) => {
                                                    return (
                                                        <>
                                                            <tr key={indx3}>
                                                                <td className="py-0">
                                                                    <div className="" style={{ verticalAlign: "middle", lineHeight: "40px" }}>
                                                                        <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6 me-1">{Currency_symbol} {obj3.category}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="py-0">
                                                                    <div className="position-relative d-flex align-items-center justify-content-center" data-kt-dialer="true" data-kt-dialer-min="1" data-kt-dialer-max="10" data-kt-dialer-step="1" data-kt-dialer-decimals="0">
                                                                        <button type="button" className="btn btn-icon btn-sm btn-light btn-icon-gray-400"
                                                                            onClick={() => {
                                                                                //console.log(obj3.count);
                                                                                let temp = amtArray.map(a => { return { ...a } })
                                                                                if (obj3.count > 0) {
                                                                                    temp[indx3].count = parseInt(obj3.count) - 1;
                                                                                    temp[indx3].amount = temp[indx3].count * parseInt(obj3.category);
                                                                                }
                                                                                //Denomination_calculation(temp[indx].count)
                                                                                setamtArray(temp)
                                                                                setConfiguration_obj({ ...Configuration_obj, denomination: temp });
                                                                            }}>
                                                                            <span className="svg-icon svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                                            </svg>
                                                                            </span>
                                                                        </button>
                                                                        <input type="text" className="form-control border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-30px" placeholder="Amount" name="manageBudget" readonly="" min="0" value={obj3.count} />
                                                                        <button type="button" className="btn btn-icon btn-sm btn-light btn-icon-gray-400"
                                                                            onClick={() => {
                                                                                //console.log(obj3.count);
                                                                                let temp = amtArray.map(a => { return { ...a } })
                                                                                temp[indx3].count = parseInt(obj3.count) + 1;
                                                                                temp[indx3].amount = temp[indx3].count * parseInt(obj3.category);
                                                                                setamtArray(temp)
                                                                                setConfiguration_obj({ ...Configuration_obj, denomination: temp });
                                                                            }}>
                                                                            <span className="svg-icon svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                                            </svg></span>
                                                                        </button>
                                                                    </div>

                                                                </td>

                                                                <td className="text-end py-0">
                                                                    <div style={{ verticalAlign: "middle", lineHeight: "40px" }}>
                                                                        <span className="fw-bold text-primary fs-4">{Currency_symbol} {obj3.amount}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table><hr />
                                    <div className="row">
                                        <div className="col-3">
                                            <span className="fw-bold text-primary fs-3">Subtotal</span>
                                        </div>
                                        <div className="col-3"></div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3 text-end">
                                            <span className="fw-bold text-primary fs-3">{Currency_symbol} {trimDecimalPlaces(amtArray.reduce((p, o) => { return p + (o.count * parseInt(o.category)) }, 0), 3)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 my-2">
                                        <label className="fs-5 fw-bold mb-2">Notes</label>
                                        <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                            onChange={(e) => {
                                                setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                            }}
                                            placeholder={""}>
                                        </textarea>
                                    </div>
                                </div>
                            </>
                            :
                            PaymentMode.label == "Cheque" ?
                                <>
                                    <div className="row mb-5">
                                        <div className="col-md-10">
                                            <label className="fs-5 fw-bold mb-2">Bank Name</label>
                                            <input type="text" className="form-control form-control-solid" value={Configuration_obj.bank_name}
                                                onChange={(e) => {
                                                    setConfiguration_obj({ ...Configuration_obj, bank_name: e.target.value })
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-5">
                                            <label className="required fs-5 fw-bold mb-2">Cheque Number</label>
                                            <input type="text" className="form-control form-control-solid" value={Configuration_obj.cheque_no}
                                                onChange={(e) => {
                                                    setConfiguration_obj({ ...Configuration_obj, cheque_no: e.target.value })
                                                }} />
                                        </div>
                                        <div className="col-md-5">
                                            <label className="required fs-5 fw-bold mb-2">Cheque Date</label>
                                            <input type="date" className="form-control form-control-solid" max={new Date().toISOString().split('T')[0]} value={Configuration_obj.cheque_date}
                                                onChange={(e) => {
                                                    setConfiguration_obj({ ...Configuration_obj, cheque_date: e.target.value })
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 my-2">
                                            <label className="fs-5 fw-bold mb-2">Notes</label>
                                            <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                                onChange={(e) => {
                                                    setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                                }}
                                                placeholder={""}>
                                            </textarea>
                                        </div>
                                    </div>
                                </>
                                :
                                PaymentMode.label == "Bank Transfer" ?
                                    <>
                                        <div className="row mb-5">
                                            <div className="col-md-10">
                                                <label className="fs-5 fw-bold mb-2">Bank Name</label>
                                                <input type="text" className="form-control form-control-solid" value={Configuration_obj.bank_name}
                                                    onChange={(e) => {
                                                        setConfiguration_obj({ ...Configuration_obj, bank_name: e.target.value })
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-5">
                                                <label className="required fs-5 fw-bold mb-2">Transaction ID</label>
                                                <input type="text" className="form-control form-control-solid" value={Configuration_obj.transaction_id}
                                                    onChange={(e) => {
                                                        setConfiguration_obj({ ...Configuration_obj, transaction_id: e.target.value })
                                                    }} />
                                            </div>
                                            <div className="col-md-5">
                                                <label className="required fs-5 fw-bold mb-2">Date</label>
                                                <input type="date" className="form-control form-control-solid" max={new Date().toISOString().split('T')[0]} value={Configuration_obj.date}
                                                    onChange={(e) => {
                                                        setConfiguration_obj({ ...Configuration_obj, date: e.target.value })
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-12 my-2">
                                                <label className="fs-5 fw-bold mb-2">Notes</label>
                                                <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                                    onChange={(e) => {
                                                        setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                                    }}
                                                    placeholder={""}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    PaymentMode.label == "Credit Card" ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-5">
                                                    <label className="required fs-5 fw-bold mb-2">Reference Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={Configuration_obj.ref_number}
                                                        onChange={(e) => {
                                                            setConfiguration_obj({ ...Configuration_obj, ref_number: e.target.value })
                                                        }} />
                                                </div>

                                                <div className="col-md-5">
                                                    <label className="fs-5 fw-bold mb-2">CVV</label>
                                                    <input type="text" className="form-control form-control-solid" value={Configuration_obj.cvv}
                                                        onChange={(e) => {
                                                            setConfiguration_obj({ ...Configuration_obj, cvv: e.target.value })
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-10">
                                                    <label className="fs-5 fw-bold mb-2">Card Type</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        name="category"
                                                        id="category"
                                                        options={debitcardlist}
                                                        onChange={(val) => {
                                                            setConfiguration_obj({ ...Configuration_obj, card_type: val.value })
                                                        }}
                                                        value={{ label: Configuration_obj.card_type, value: Configuration_obj.card_type }}
                                                        placeholder="--Choose a Type--"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-12 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Notes</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                                        onChange={(e) => {
                                                            setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                                        }}
                                                        placeholder={""}>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        invoiceArray[configurationindex].payment_mode == "Debit Card" ?
                                            <>
                                                <div className="row mb-5">
                                                    <div className="col-md-5">
                                                        <label className="required fs-5 fw-bold mb-2">Reference Number</label>
                                                        <input type="text" className="form-control form-control-solid" value={Configuration_obj.ref_number}
                                                            onChange={(e) => {
                                                                setConfiguration_obj({ ...Configuration_obj, ref_number: e.target.value })
                                                            }} />
                                                    </div>

                                                    <div className="col-md-5">
                                                        <label className="fs-5 fw-bold mb-2">CVV</label>
                                                        <input type="text" className="form-control form-control-solid" value={Configuration_obj.cvv}
                                                            onChange={(e) => {
                                                                setConfiguration_obj({ ...Configuration_obj, cvv: e.target.value })
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-10">
                                                        <label className="fs-5 fw-bold mb-2">Card Type</label>
                                                        <Select
                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={debitcardlist}
                                                            onChange={(val) => {
                                                                setConfiguration_obj({ ...Configuration_obj, card_type: val.value })
                                                            }}
                                                            value={{ label: Configuration_obj.card_type, value: Configuration_obj.card_type }}
                                                            placeholder="--Choose a Type--"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 my-2">
                                                        <label className="fs-5 fw-bold mb-2">Notes</label>
                                                        <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                                            onChange={(e) => {
                                                                setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                                            }}
                                                            placeholder={""}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            invoiceArray[configurationindex].payment_mode == "UPI" &&
                                            <>
                                                <div className="col-md-5">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Ref. ID</label>
                                                    <input type="text" className="form-control form-control-solid" value={Configuration_obj.transaction_id}
                                                        onChange={(e) => {
                                                            setConfiguration_obj({ ...Configuration_obj, transaction_id: e.target.value })
                                                        }} />
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 my-2">
                                                        <label className="fs-5 fw-bold mb-2">Notes</label>
                                                        <textarea className="form-control form-control-solid" rows="3" value={Configuration_obj.notes}
                                                            onChange={(e) => {
                                                                setConfiguration_obj({ ...Configuration_obj, notes: e.target.value })
                                                            }}
                                                            placeholder={""}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary rounded me-auto" onClick={() => { Config_set_up(PaymentMode.value); }}>Reset</button>
                    <button className="btn btn-success rounded" onClick={() => { setAdvanceConfigurationModalShow(false); }}>Save</button>
                </Modal.Footer>
            </Modal>
            <Modal show={ConfigurationModalShow} onHide={() => { setConfigurationModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{invoiceArray.length > 0 && <>Configure {invoiceArray[configurationindex].payment_mode} Mode</>}</Modal.Title>
                    <div>
                        <button onClick={(e) => { setCopyMode(invoiceArray[configurationindex].payment_mode); setCopyIndex(configurationindex); setCopyData(invoiceArray[configurationindex].configuration_obj) }} className={(copyData !== null && copyMode == invoiceArray[configurationindex].payment_mode && configurationindex == copyIndex) ? `btn btn-success btn-icon btn-sm btn-outline-light mx-2` : `btn btn-primary btn-icon btn-sm btn-outline-light mx-2`}>
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </button>
                        {(copyData !== null && copyMode == invoiceArray[configurationindex].payment_mode && configurationindex !== copyIndex) &&
                            <button onClick={(e) => {
                                let temp_invoice = invoiceArray.map(a => { return { ...a } });
                                temp_invoice[configurationindex].configuration_obj = copyData;
                                setInvoiceArray(temp_invoice);
                            }} className={`btn btn-primary btn-icon btn-sm btn-outline-light mx-2`}>
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M3 21h5v-1H4V4h2v2h10V4h2v3h.4a.989.989 0 0 1 .6.221V3h-3V2h-3a2 2 0 0 0-4 0H6v1H3zM7 3h3V1.615A.615.615 0 0 1 10.614 1h.771a.615.615 0 0 1 .615.615V3h3v2H7zm4 14h9v1h-9zM9 8v16h13V11.6L18.4 8zm12 15H10V9h7v4h4zm0-11h-3V9h.31L21 11.69zm-10 2h9v1h-9zm0 6h7v1h-7z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
                                </span>
                            </button>}
                        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setConfigurationModalShow(false) }}>
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {invoiceArray.length > 0 &&
                        <div className='container'>
                            {invoiceArray[configurationindex].payment_mode == "Cash" ?
                                <>
                                    <div className="table-responsive mb-8">
                                        <table className="table align-end gs-0 gy-4 my-0">
                                            <thead>
                                                <tr>
                                                    <th className="min-w-100px">Amount</th>
                                                    <th className="min-w-75px align-items-center text-center">Count</th>
                                                    <th className="text-end min-w-75px">Total Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    amtArray.map((obj3, indx3) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td className="py-0">
                                                                        <div className="" style={{ verticalAlign: "middle", lineHeight: "40px" }}>
                                                                            <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-6 me-1">{Currency_symbol} {obj3.category}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="py-0">
                                                                        <div className="position-relative d-flex align-items-center justify-content-center" data-kt-dialer="true" data-kt-dialer-min="1" data-kt-dialer-max="10" data-kt-dialer-step="1" data-kt-dialer-decimals="0">
                                                                            <button type="button" className="btn btn-icon btn-sm btn-light btn-icon-gray-400"
                                                                                onClick={() => {
                                                                                    //console.log(obj3.count);
                                                                                    let temp = amtArray.map(a => { return { ...a } })
                                                                                    if (obj3.count > 0) {
                                                                                        temp[indx3].count = parseInt(obj3.count) - 1;
                                                                                        temp[indx3].amount = temp[indx3].count * parseInt(obj3.category);
                                                                                    }
                                                                                    //Denomination_calculation(temp[indx].count)
                                                                                    setamtArray(temp)

                                                                                    let temp_invoice = invoiceArray.map(a => { return { ...a } });
                                                                                    temp_invoice[configurationindex].configuration_obj.denomination = temp;
                                                                                    setInvoiceArray(temp_invoice);
                                                                                }}>
                                                                                <span className="svg-icon svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                                                </svg>
                                                                                </span>
                                                                            </button>
                                                                            <input type="text" className="form-control border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-30px" placeholder="Amount" name="manageBudget" readonly="" min="0" value={obj3.count} />
                                                                            <button type="button" className="btn btn-icon btn-sm btn-light btn-icon-gray-400"
                                                                                onClick={() => {
                                                                                    //console.log(obj3.count);
                                                                                    let temp = amtArray.map(a => { return { ...a } })
                                                                                    temp[indx3].count = parseInt(obj3.count) + 1;
                                                                                    temp[indx3].amount = temp[indx3].count * parseInt(obj3.category);
                                                                                    setamtArray(temp);

                                                                                    let temp_invoice = invoiceArray.map(a => { return { ...a } });
                                                                                    temp_invoice[configurationindex].configuration_obj.denomination = temp;
                                                                                    setInvoiceArray(temp_invoice);
                                                                                }}>
                                                                                <span className="svg-icon svg-icon-3x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                                                </svg></span>
                                                                            </button>
                                                                        </div>

                                                                    </td>

                                                                    <td className="text-end py-0">
                                                                        <div style={{ verticalAlign: "middle", lineHeight: "40px" }}>
                                                                            <span className="fw-bold text-primary fs-4">{Currency_symbol} {obj3.amount}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table><hr />
                                        <div className="row">
                                            <div className="col-3">
                                                <span className="fw-bold text-primary fs-3">Subtotal</span>
                                            </div>
                                            <div className="col-3"></div>
                                            <div className="col-3">
                                            </div>
                                            <div className="col-3 text-end">
                                                <span className="fw-bold text-primary fs-3">{Currency_symbol} {trimDecimalPlaces(amtArray.reduce((p, o) => { return p + (o.count * parseInt(o.category)) }, 0), 3)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 my-2">
                                            <label className="fs-5 fw-bold mb-2">Notes</label>
                                            <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                onChange={(e) => {
                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                    temp[configurationindex].configuration_obj.notes = e.target.value
                                                    setInvoiceArray(temp)
                                                }}
                                                placeholder={""}>
                                            </textarea>
                                        </div>
                                    </div>
                                </>
                                :
                                invoiceArray[configurationindex].payment_mode == "Cheque" ?
                                    <>
                                        <div className="row mb-5">
                                            <div className="col-md-10">
                                                <label className="fs-5 fw-bold mb-2">Bank Name</label>
                                                <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.bank_name}
                                                    onChange={(e) => {
                                                        let temp = invoiceArray.map(a => { return { ...a } })
                                                        temp[configurationindex].configuration_obj.bank_name = e.target.value
                                                        setInvoiceArray(temp)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-5">
                                                <label className="required fs-5 fw-bold mb-2">Cheque Number</label>
                                                <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.cheque_no}
                                                    onChange={(e) => {
                                                        let temp = invoiceArray.map(a => { return { ...a } })
                                                        temp[configurationindex].configuration_obj.cheque_no = e.target.value
                                                        setInvoiceArray(temp)
                                                    }} />
                                            </div>
                                            <div className="col-md-5">
                                                <label className="required fs-5 fw-bold mb-2">Cheque Date</label>
                                                <input type="date" className="form-control form-control-solid" max={new Date().toISOString().split('T')[0]} value={invoiceArray[configurationindex].configuration_obj.cheque_date}
                                                    onChange={(e) => {
                                                        let temp = invoiceArray.map(a => { return { ...a } })
                                                        temp[configurationindex].configuration_obj.cheque_date = e.target.value
                                                        setInvoiceArray(temp)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-12 my-2">
                                                <label className="fs-5 fw-bold mb-2">Notes</label>
                                                <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                    onChange={(e) => {
                                                        let temp = invoiceArray.map(a => { return { ...a } })
                                                        temp[configurationindex].configuration_obj.notes = e.target.value
                                                        setInvoiceArray(temp)
                                                    }}
                                                    placeholder={""}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    invoiceArray[configurationindex].payment_mode == "Bank Transfer" ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-10">
                                                    <label className="fs-5 fw-bold mb-2">Bank Name</label>
                                                    <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.bank_name}
                                                        onChange={(e) => {
                                                            let temp = invoiceArray.map(a => { return { ...a } })
                                                            temp[configurationindex].configuration_obj.bank_name = e.target.value
                                                            setInvoiceArray(temp)
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-5">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction ID</label>
                                                    <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.transaction_id}
                                                        onChange={(e) => {
                                                            let temp = invoiceArray.map(a => { return { ...a } })
                                                            temp[configurationindex].configuration_obj.transaction_id = e.target.value
                                                            setInvoiceArray(temp)
                                                        }} />
                                                </div>
                                                <div className="col-md-5">
                                                    <label className="required fs-5 fw-bold mb-2">Date</label>
                                                    <input type="date" className="form-control form-control-solid" max={new Date().toISOString().split('T')[0]} value={invoiceArray[configurationindex].configuration_obj.date}
                                                        onChange={(e) => {
                                                            let temp = invoiceArray.map(a => { return { ...a } })
                                                            temp[configurationindex].configuration_obj.date = e.target.value
                                                            setInvoiceArray(temp)
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-12 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Notes</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                        onChange={(e) => {
                                                            let temp = invoiceArray.map(a => { return { ...a } })
                                                            temp[configurationindex].configuration_obj.notes = e.target.value
                                                            setInvoiceArray(temp)
                                                        }}
                                                        placeholder={""}>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        invoiceArray[configurationindex].payment_mode == "Credit Card" ?
                                            <>
                                                <div className="row mb-5">
                                                    <div className="col-md-5">
                                                        <label className="required fs-5 fw-bold mb-2">Reference Number</label>
                                                        <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.ref_number}
                                                            onChange={(e) => {
                                                                let temp = invoiceArray.map(a => { return { ...a } })
                                                                temp[configurationindex].configuration_obj.ref_number = e.target.value
                                                                setInvoiceArray(temp)
                                                            }} />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label className="fs-5 fw-bold mb-2">CVV</label>
                                                        <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.cvv}
                                                            onChange={(e) => {
                                                                let temp = invoiceArray.map(a => { return { ...a } })
                                                                temp[configurationindex].configuration_obj.cvv = e.target.value
                                                                setInvoiceArray(temp)
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-10">
                                                        <label className="fs-5 fw-bold mb-2">Card Type</label>
                                                        <Select
                                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={creditcardlist}
                                                            onChange={(val) => {
                                                                let temp = invoiceArray.map(a => { return { ...a } })
                                                                temp[configurationindex].configuration_obj.card_type = val.value
                                                                setInvoiceArray(temp)
                                                            }}
                                                            value={{ label: invoiceArray[configurationindex].configuration_obj.card_type, value: invoiceArray[configurationindex].configuration_obj.card_type }}
                                                            placeholder="--Choose a Type--"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 my-2">
                                                        <label className="fs-5 fw-bold mb-2">Notes</label>
                                                        <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                            onChange={(e) => {
                                                                let temp = invoiceArray.map(a => { return { ...a } })
                                                                temp[configurationindex].configuration_obj.notes = e.target.value
                                                                setInvoiceArray(temp)
                                                            }}
                                                            placeholder={""}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            invoiceArray[configurationindex].payment_mode == "Debit Card" ?
                                                <>
                                                    <div className="row mb-5">
                                                        <div className="col-md-5">
                                                            <label className="required fs-5 fw-bold mb-2">Reference Number</label>
                                                            <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.ref_number}
                                                                onChange={(e) => {
                                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                                    temp[configurationindex].configuration_obj.ref_number = e.target.value
                                                                    setInvoiceArray(temp)
                                                                }} />
                                                        </div>

                                                        <div className="col-md-5">
                                                            <label className="fs-5 fw-bold mb-2">CVV</label>
                                                            <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.cvv}
                                                                onChange={(e) => {
                                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                                    temp[configurationindex].configuration_obj.cvv = e.target.value
                                                                    setInvoiceArray(temp)
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-10">
                                                            <label className="fs-5 fw-bold mb-2">Card Type</label>
                                                            <Select
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                name="category"
                                                                id="category"
                                                                options={debitcardlist}
                                                                onChange={(val) => {
                                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                                    temp[configurationindex].configuration_obj.card_type = val.value
                                                                    setInvoiceArray(temp)
                                                                }}
                                                                value={{ label: invoiceArray[configurationindex].configuration_obj.card_type, value: invoiceArray[configurationindex].configuration_obj.card_type }}
                                                                placeholder="--Choose a Type--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 my-2">
                                                            <label className="fs-5 fw-bold mb-2">Notes</label>
                                                            <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                                onChange={(e) => {
                                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                                    temp[configurationindex].configuration_obj.notes = e.target.value
                                                                    setInvoiceArray(temp)
                                                                }}
                                                                placeholder={""}>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                invoiceArray[configurationindex].payment_mode == "UPI" &&
                                                <>
                                                    <div className="col-md-5">
                                                        <label className="required fs-5 fw-bold mb-2">Transaction Ref. ID</label>
                                                        <input type="text" className="form-control form-control-solid" value={invoiceArray[configurationindex].configuration_obj.transaction_id}
                                                            onChange={(e) => {
                                                                let temp = invoiceArray.map(a => { return { ...a } })
                                                                temp[configurationindex].configuration_obj.transaction_id = e.target.value
                                                                setInvoiceArray(temp)
                                                            }} />
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 my-2">
                                                            <label className="fs-5 fw-bold mb-2">Notes</label>
                                                            <textarea className="form-control form-control-solid" rows="3" value={invoiceArray[configurationindex].configuration_obj.notes}
                                                                onChange={(e) => {
                                                                    let temp = invoiceArray.map(a => { return { ...a } })
                                                                    temp[configurationindex].configuration_obj.notes = e.target.value
                                                                    setInvoiceArray(temp)
                                                                }}
                                                                placeholder={""}>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </>
                            }
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary rounded me-auto" onClick={() => { load_configuration_info(PaymentMode.value, configurationindex); }}>Reset</button>
                    <button className="btn btn-success rounded" onClick={() => { setConfigurationModalShow(false); }}>Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={addAccHeadModalShow} onHide={() => { setAddAccHeadModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Head</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddAccHeadModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Account Category</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                    options={categoryList.map(x => { return { label: x.category_name, value: x.cid, headArray: x.headArray } })}
                                    onChange={(val) => {
                                        setAccountHeadList(val.headArray);
                                        setNewHead({ ...newHead, uq_ac: val.value, parent: "" })
                                    }}
                                    placeholder="Select Category"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    onChange={(e) => { setNewHead({ ...newHead, name: e.target.value }) }}
                                    placeholder='Name of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Code</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    onChange={(e) => { setNewHead({ ...newHead, code: e.target.value }) }}
                                    placeholder='Codename of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Sub</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="checkbox" className=""
                                    onChange={(e) => { setNewHead({ ...newHead, sub: e.target.checked ? 1 : 0 }) }}
                                />
                            </div>
                        </div>
                        {
                            newHead.sub ?
                                <>
                                    <div className="d-flex justify-content-between mb-5">
                                        <div className="col-3 my-auto">
                                            <label className="required fs-5 mb-2">Parent Head</label>
                                        </div>
                                        <div className='col-9 my-auto'>
                                            <Select
                                                className="form-control form-control-solid"
                                                styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                options={accountheadList.map(x => { return { label: x.name, value: x.hid } })}
                                                onChange={(val) => {
                                                    setNewHead({ ...newHead, parent: val.value })
                                                }}
                                                placeholder="Select Parent Head"
                                            />
                                        </div>
                                    </div>
                                </>
                                : null}
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="fs-5 mb-2">Description</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <textarea type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewHead({ ...newHead, description: e.target.value }) }}
                                    placeholder='Description for account head'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setAddAccHeadModalShow(false) }}>Close</button>
                    {
                        // write  &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { newHeadSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={gstinModalShow} onHide={() => { handleGstinModalClose() }} centered>
                <Modal.Header>
                    <Modal.Title>Gstin List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleGstinModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f4f6f9" }}>
                    <div className='container'>
                        {
                            gstinListApiState ? <div className='d-flex justify-content-center'><div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} ></div></div> :
                                gstinList.length === 0 ?
                                    <div className="overflow-auto pb-5">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                    <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                <div className="mb-3 mb-md-0 fw-semibold">
                                                    <h4 className="text-gray-900 fw-bold">No Data Available.</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex flex-column gap-5">
                                        {
                                            gstinList.sort((a, b) => a.add_date_time - b.add_date_time).map((obj, index) => {
                                                return (
                                                    <div key={index} className="position-relative fv-row w-100 flex-md-root" style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (Payment_Type == "Invoice Payment") {
                                                                let t = invoiceArray.map(x => { return { ...x } })
                                                                t[invoiceIndexGstin].gstin_details = obj
                                                                setInvoiceArray(t);
                                                            }
                                                            else {
                                                                setSelectedGstin(obj);
                                                            }
                                                            handleGstinModalClose();
                                                        }}>
                                                        <div className="card card-flush h-lg-100">
                                                            <div className="card-header">
                                                                <div className="card-title"><h3 className="fw-bolder mb-1">{obj.GstIn}</h3></div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="card-body p-9">
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.legal_name}</div>
                                                                        <div className="fw-semibold text-gray-400">Business Legal Name</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                        <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.place_of_supply}</div>
                                                                        <div className="fw-semibold text-gray-400">Place of Supply</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            gstinId === obj.unique_id &&
                                                            <div className='position-absolute' style={{ top: "1.5rem", right: "2rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleGstinModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={upsertGstInModalShow} onHide={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add GstIn Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-wrap gap-5 mb-2">
                            <div className="fv-row w-100 flex-md-root">
                                <label className="form-label">GST Treatment</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    options={upsertGstInTreatmentList}
                                    value={upsertGstInTreatment}
                                    onChange={(val) => { setUpsertGstInTreatment(val) }}
                                    components={{
                                        Option: (props) => {
                                            return (
                                                <components.Option  {...props}>
                                                    {props.children}
                                                    <span className="fw-semibold text-gray-400 d-block fs-8">{props.data.description}</span>
                                                </components.Option >
                                            )
                                        }
                                    }}
                                />
                            </div>

                        </div>
                        <div className="d-flex flex-wrap gap-5">

                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">GSTIN</label>
                                <input id="GSTIN" type="text" className="form-control mb-2" placeholder="" name="GSTIN" value={upsertGstInNumber} onChange={(e) => { setUpsertGstInNumber(e.target.value) }} />
                            </div>
                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">Business Legal Name</label>
                                <input id="Business_Legal_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Legal_Name" value={upsertGstInLegalName} onChange={(e) => { setUpsertGstInLegalName(e.target.value) }} />
                            </div>

                        </div>
                        <div className="d-flex flex-wrap gap-5">
                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">Place Of Supply</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    options={SupplyStateOptions}
                                    onChange={(val) => { setUpsertGstInSelectedPlaceOfSupply(val) }}
                                    value={upsertGstInSelectedPlaceOfSupply}
                                    isClearable
                                />
                            </div>
                            <div className="fv-row w-100 flex-md-root">
                                <label className="form-label">Business Trade Name</label>
                                <input id="Business_Trade_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Trade_Name" value={upsertGstInTradeName} onChange={(e) => { setUpsertGstInTradeName(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-info rounded" data-kt-indicator={upsertGstInSubmitButtonState ? "on" : "off"} disabled={upsertGstInSubmitButtonState} onClick={() => { newGstInSubmit() }}>
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>);

};

export default CreatePaymentReceived;
