import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from './theme-provider.js';
import PageError from '../common_component/PageError.js';

export const ViewGoodsReceive = (props) => {
	const [goodsReceivedDetails, setGoodsReceivedDetails] = useState([]);

	const [approveFinalButtonShow, setApproveFinalButtonShow] = useState(false)
	const [approveButtonShow, setApproveButtonShow] = useState(false)
	const [cancelButtonShow, setCancelButtonShow] = useState(false)

	const [submitButtonState, setSubmitButtonState] = useState(false);

	const [state, setstate] = useState(2);
	const { theme, dropdownStyleDark } = useTheme()
	const history = useHistory()

	var page_name = "view-goods-receive";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		async function getGoodsReceivedDetails() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-goods-received-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "gr_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.operation === "success") {
				setGoodsReceivedDetails(body.data);

				if (user_role === "Admin") {
					setApproveFinalButtonShow(true)
					setCancelButtonShow(true)
				}

				let t1 = body.data.approval_info
				let t2 = body.data.add_status
				let t3 = body.data.approval_level
				// let t4 = t1.value.length
				if (t2 === "GOODS_RECEIVED_PENDING_APPROVAL") {
					if (t1.type === "multilevel_approval") {

						let tl = t1.value.find(x => x.level === (t3 == null ? 1 : t3 + 1)).user_value
						if (tl.includes(user.email)) {
							setApproveButtonShow(true)
						}

						let tl2 = t1.value.map(x => x.user_value).flat(1)
						if (tl2.includes(user.email)) {
							setCancelButtonShow(true)
						}
					}
					else if (t1.type === "custom_approval") {
						let ty = t1.value[0]
						if (ty.user_type === "Group User") {
							if (ty.user_value.includes(user_role)) {
								setApproveButtonShow(true)
								setCancelButtonShow(true)
							}
						}
						else if (ty.user_type === "Individual User") {
							if (ty.user_value.includes(user.email)) {
								setApproveButtonShow(true)
								setCancelButtonShow(true)
							}
						}
					}
				}

				setstate(1);
			}
			else {
				setstate(3);
			}
		}

		if ((read || write) && (user.email && visibility)) {
			getGoodsReceivedDetails();
		}
	}, [user, visibility, read, write])

	const approveGoodsReceiveSubmit = (final_flag, cancel_flag, cancel_msg) => {

		var object = {};

		object.final_flag = final_flag
		object.cancel_flag = cancel_flag
		object.cancel_msg = cancel_msg
		object.gr_id = props.match.params.id
		object.user_email = user.email
		object.user_role = user_role

		object.organization_id = organization_id
		object.page_name = page_name;

		// console.log(object);
		setSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-goods-receive`, object, headers).then((res) => {
			setSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", "Goods Receive Entry reviewed successfully!", "success").then(() => { history.push("/goods-received-list") })
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Goods Received Entry
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
								<li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">Goods Received List</li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">View Goods Receive</li>
							</ul>
						</div>

						{
							state === 1 &&
							<Navbar collapseOnSelect expand="lg" className="me-5 position-relative">
								<Navbar.Toggle />
								<Navbar.Collapse>
									<Nav className="align-items-end end-0 gap-3 navbar-nav p-5 position-absolute z-index-1">
										{
											approveFinalButtonShow && goodsReceivedDetails.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-success btn-hover-scale btn-sm border border-success' title='Final Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal("Alert!", "Are you sure you want to proceed?", {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" },
																approve: { text: "Approve", className: "btn btn-danger" },
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-goods-receive/${props.match.params.id}`); break;
																	case "approve": approveGoodsReceiveSubmit(true, false, ""); break;
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-shield-check p-0 fs-1"></i></span>
													<span className="indicator-progress">
														<span className="spinner-border spinner-border-sm align-middle h-20px w-20px"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											approveButtonShow && goodsReceivedDetails.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-success btn-hover-scale btn-sm border border-success' title='Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal("Alert!", "Are you sure you want to proceed?", {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" },
																approve: { text: "Approve", className: "btn btn-danger" },
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-goods-receive/${props.match.params.id}`); break;
																	case "approve": approveGoodsReceiveSubmit(false, false, ""); break;
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-check-circle p-0 fs-1"></i></span>
													<span className="indicator-progress">
														<span className="spinner-border spinner-border-sm align-middle h-20px w-20px"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											cancelButtonShow && goodsReceivedDetails.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-danger btn-hover-scale btn-sm border border-danger' title='Cancel' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal({
															title: "Alert!",
															text: "Please type a reason before cancelling",
															icon: "warning",
															content: "input",
															buttons: true,
															dangerMode: true,
														})
															.then((msg) => {
																if (msg === "") {
																	swal("Alert!", "Message cannot be empty", "warning")
																} else if (msg) {
																	approveGoodsReceiveSubmit(false, true, msg);
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-x-circle p-0 fs-1"></i></span>
													<span className="indicator-progress">
														<span className="spinner-border spinner-border-sm align-middle h-20px w-20px"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											(goodsReceivedDetails.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" || goodsReceivedDetails.add_status === "GOODS_RECEIVED_DRAFT") &&
											<Nav.Item>
												<button className='btn btn-light-warning btn-hover-scale btn-sm border border-warning' title='Edit' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal("Alert!", "Are you sure you want to proceed?", {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" }
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-goods-receive/${props.match.params.id}`); break;
																}
															});
													}}
												>
													<i className="bi bi-pencil-square fs-1 p-0"></i>
												</button>
											</Nav.Item>
										}
									</Nav>
								</Navbar.Collapse>
							</Navbar>
						}
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state === 1 ?
							<>
								<div className="d-flex flex-column gap-7 gap-lg-10">
									<div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
										<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#goods_receive_summary" aria-selected="true" role="tab">Goods Receive Entry Summary</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#goods_receive_history" aria-selected="false" role="tab" tabIndex="-1">Goods Receive History</a>
											</li>
										</ul>
									</div>
									<div className="tab-content">
										<div className="tab-pane fade active show" id="goods_receive_summary" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-row-fluid">
														<div className="card-header">
															<div className="card-title">
																<h2>Goods Receive Entry Details (#{goodsReceivedDetails.gr_number})</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Status
																				</div>
																			</td>
																			<td className="fw-bold text-end">
																				<span className={`badge badge-${goodsReceivedDetails.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" ? "warning" : "secondary"} me-auto rounded`}>
																					{goodsReceivedDetails.add_status.replace("GOODS_RECEIVED_", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																				</span>
																			</td>
																		</tr>
																		{
																			goodsReceivedDetails.associated_bill !== null &&
																			<tr>
																				<td className="text-muted">
																					<div className="d-flex align-items-center">
																						<span className="svg-icon svg-icon-2 me-2">
																							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
																								<path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
																							</svg>
																						</span>Associated Bill
																					</div>
																				</td>
																				<td className="fw-bold text-end">
																					<Link to={`/view-bill/${goodsReceivedDetails.associated_bill.bill_id}`}>{goodsReceivedDetails.associated_bill.bill_number}</Link>
																				</td>
																			</tr>
																		}
																		{
																			goodsReceivedDetails.purchase_order_id !== null &&
																			<tr>
																				<td className="text-muted">
																					<div className="d-flex align-items-center">
																						<span className="svg-icon svg-icon-2 me-2">
																							<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																								<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																							</svg>
																						</span>Purchase Order Number
																					</div>
																				</td>
																				<td className="fw-bold text-end">
																					<Link to={`/view-purchase-order/${goodsReceivedDetails.purchase_order_id}`}>{goodsReceivedDetails.purchase_order_number}</Link>
																				</td>
																			</tr>
																		}
																	</tbody>
																</table>
															</div>
														</div>
													</div>

												</div>

												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>User Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>User Type
																				</div>
																			</td>
																			<td className="fw-bold text-end">{goodsReceivedDetails.user_type}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
																							<path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
																							<rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
																						</svg>
																					</span>User Name
																				</div>
																			</td>
																			<td className="fw-bold text-end">{goodsReceivedDetails.doc_user_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>User Email
																				</div>
																			</td>
																			<td className="fw-bold text-end">{goodsReceivedDetails.doc_user_email}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>Other Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Branch
																				</div>
																			</td>
																			<td className="fw-bold text-end">{goodsReceivedDetails.branch_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Warehouse
																				</div>
																			</td>
																			<td className="fw-bold text-end">{goodsReceivedDetails.warehouse_name}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>

												<div className="card flex-row-fluid">
													<div className="card-header align-items-center py-5 gap-2 gap-md-5">
														<div className="card-title">
															<h2>Goods Receive #{goodsReceivedDetails.gr_number}</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="d-flex justify-content-between flex-column">
															<div className="table-responsive border-bottom mb-9">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="border-bottom fs-6 fw-bold text-muted">
																			<th className="min-w-100px">Item</th>
																			<th className="min-w-80px">Received Quantity</th>
																			<th className="min-w-100px text-end pb-2">Item Data</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{
																			goodsReceivedDetails.item_array.map((obj, index) => {
																				return (
																					<tr key={index}>
																						<td><div className="fw-bold">{obj.item_name}{obj.item_stock_type === "Track Serial Number" ? " - Serial" : obj.item_stock_type === "Track Batches" ? " - Batch" : obj.item_stock_type === "None" ? " - None" : ""}</div></td>
																						<td>{obj.received_qty}</td>
																						<td className='d-flex gap-1 flex-wrap'>
																							{
																								obj.item_stock_type !== "Track Serial Number" && obj.item_stock_type !== "Track Batches" ? <span>N/A</span> :
																									obj.item_data.map((obj2, indx2) => (
																										<div key={indx2}>
																											<span className="badge badge-primary fs-3">
																												{
																													obj.item_stock_type === "Track Serial Number" ? obj2 :
																														obj.item_stock_type === "Track Batches" && obj2.batch_reference + " - " + obj2.batch_quantity
																												}
																											</span>
																										</div>
																									))
																							}
																						</td>
																					</tr>
																				)
																			})}

																	</tbody>
																</table>
															</div>

														</div>
													</div>
												</div>

											</div>
										</div>

										<div className="tab-pane fade" id="goods_receive_history" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="card">
													<div className="card-header">
														<div className="card-title">
															<h2>Goods Receive Entry History</h2>
														</div>
													</div>

													<div className="card-body py-4">
														{goodsReceivedDetails.gr_status_logs.length > 0 &&
															<div className="table-responsive">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
																			<th className="min-w-100px">Date Added</th>
																			<th className="min-w-175px">Comment</th>
																			<th className="min-w-70px">GR Status</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{goodsReceivedDetails.gr_status_logs.map((Obj, index) => {
																			let c
																			if ((Obj.gr_status === "GOODS_RECEIVED_FINALIZED") || (Obj.gr_status === "GOODS_RECEIVED_CONFIRMED")) {
																				c = "success"
																			}
																			else if ((Obj.gr_status === "GOODS_RECEIVED_PENDING_APPROVAL") || (Obj.gr_status === "GOODS_RECEIVED_DRAFT")) {
																				c = "warning"
																			}
																			else if (Obj.gr_status === "GOODS_RECEIVED_CANCELLED") {
																				c = "danger"
																			}
																			else {
																				c = "danger"
																			}

																			let s = Obj.gr_status.replace("GOODS_RECEIVED_", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

																			return (
																				<tr key={index}>
																					<td>{moment(Obj.timestamp).format("ll")}</td>
																					<td>{Obj.operation_message}</td>
																					<td>
																						<div className={`badge badge-${c} me-auto rounded`}> {s} </div>
																					</td>
																				</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</>
							: state === 2 ?
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
								:
								<PageError />
						}
					</div>
				</div>
			</div>
		</>);

};

export default ViewGoodsReceive;
