import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import moment from 'moment';

import { Modal } from 'react-bootstrap';
import { Pagination } from "antd";
import { useTheme } from "./theme-provider.js"

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from "../common_component/PageError.js";
import StatusBadge from "../common_component/StatusBadge.js";

function AccountHead(props) {
    const { theme, dropdownStyleDark } = useTheme()
    const [state, setState] = useState(2);

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [addAccHeadModalShow, setAddAccHeadModalShow] = useState(false)
    const [editAccHeadModalShow, setEditAccHeadModalShow] = useState(false)

    const [newHead, setNewHead] = useState({ name: "", code: "", uq_ac: "", sub: 0, parent: "", description: "" })
    const [categoryList, setCategoryList] = useState([])
    const [tempAccountHeadList, setTempAccountHeadList] = useState([])
    const [accountheadList, setAccountHeadList] = useState([])

    const [editHead, SetEditHead] = useState({ name: "", code: "", uq_ac: "", sub: 0, parent: "", description: "" })

    const [submitButton, SetSubmitButton] = useState(false)

    var page_name = "account-head";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getcategories() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-account-category`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setCategoryList(body.data)
        }
    }
    async function getaccountheads() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-account-head`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setTempAccountHeadList(body.data)
        }
    }

    useEffect(() => {
        if (categoryList.length && tempAccountHeadList.length) {
            let tempHead = tempAccountHeadList
            let temp = tempAccountHeadList.map((head) => {
                return {
                    ...head,
                    sub_badge: head.sub ? <span className="badge badge-primary me-auto rounded">Sub-Head</span> : <span className="badge badge-secondary me-auto rounded">Head</span>,
                    category: <span className="badge badge-info me-auto rounded">{categoryList.find(aCat => aCat.uq_ac == head.uq_ac) == undefined ? "" : categoryList.find(aCat => aCat.uq_ac == head.uq_ac).name}</span>,
                    parent_badge: <span className="badge badge-success me-auto rounded">{tempHead.find(tHead => tHead.uq_ah == head.parent) == undefined ? "" : tempHead.find(tHead => tHead.uq_ah == head.parent).name}</span>,
                    default_badge: head.default ? <span className="badge badge-secondary me-auto rounded">Default</span> : <span className="badge badge-warning me-auto rounded">Custom</span>,
                    add_date_time_format: moment(head.add_date_time).format('hh:mm A, DD MMM YYYY'),
                    // actions: head.default ? null :
                    //     <>
                    //         <div class="dropdown">
                    //             <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    //                 <i className="fas fa-bars px-0"></i>
                    //             </button>
                    //             <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    //                 <li><a onClick={(e) => { editSetup(head) }} data-bs-toggle="modal" data-bs-target="#EditDetails" class="dropdown-item">Edit details</a></li>
                    //             </ul>
                    //         </div>
                    //     </>
                }
            })
            //console.log(temp);
            setAccountHeadList(temp)
        }

    }, [categoryList, tempAccountHeadList])


    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getcategories();
            let p2 = getaccountheads();
            Promise.all([p1, p2])
                .then(() => {
                    setState(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setState(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const editSetup = (head) => {


        SetEditHead(head)
        setEditAccHeadModalShow(true);
    }

    const newHeadSubmit = () => {
        //Name check
        if (newHead.uq_ac == "") {
            swal("Warning!", "Select a Category", "warning");
            return
        }
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (newHead.name == "") {
            swal("Warning!", "Head must have name", "warning");
            return
        }
        if (!newHead.name.match(nexp)) {
            swal("Warning!", "Head name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        accountheadList.map((head) => {
            // if (head.wid != object.wid) {
            if (head.name.trim().toLowerCase() == newHead.name.toLowerCase()) {
                flag = true
            }
            // }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }

        let flag2 = false
        categoryList.map((category) => {
            // if (category.wid != object.wid) {
            if (category.name.trim().toLowerCase() == newHead.name.toLowerCase()) {
                flag2 = true

            }
            // }
        })
        if (flag2) {
            swal("Warning!", "Name Cannot be same of any Category", "warning");
            return
        }

        //Code check
        if (newHead.code == "") {
            swal("Warning!", "Head must have a code", "warning");
            return
        }
        let flag3 = false
        accountheadList.map((head) => {
            // if (head.wid != object.wid) {
            if (head.code.trim().toLowerCase() == newHead.code.toLowerCase()) {
                flag3 = true
            }
            // }
        })
        if (flag3) {
            swal("Warning!", "Code name already take", "warning");
            return
        }
        //Sub Parent check
        if (newHead.sub && !String(newHead.parent).trim().length) {
            swal("Warning!", "Sub-Head must have a parent", "warning");
            return
        }
        if (!newHead.sub && String(newHead.parent).trim().length) {
            swal("Warning!", "non Sub-Head should have a parent", "warning");
            return
        }
        let object = { ...newHead, page_name: page_name, organization_id: organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-account-head`, object, headers).then((res) => {
            SetSubmitButton(false)
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    // window.location.reload();
                    getaccountheads()
                    setAddAccHeadModalShow(false)
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const editHeadSubmit = () => {
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (editHead.name == "") {
            swal("Warning!", "Head must have name", "warning");
            return
        }
        if (!editHead.name.match(nexp)) {
            swal("Warning!", "Head name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        accountheadList.map((head) => {
            if (head.uq_ah != editHead.uq_ah) {
                if (head.name.trim().toLowerCase() == editHead.name.toLowerCase()) {
                    flag = true
                }
            }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }

        let flag2 = false
        categoryList.map((category) => {
            // if (category.wid != object.wid) {
            if (category.name.trim().toLowerCase() == editHead.name.toLowerCase()) {
                flag2 = true

            }
            // }
        })
        if (flag2) {
            swal("Warning!", "Name Cannot be same of any Category", "warning");
            return
        }

        //Code check
        if (editHead.code == "") {
            swal("Warning!", "Head must have a code", "warning");
            return
        }
        let flag3 = false
        accountheadList.map((head) => {
            if (head.uq_ah != editHead.uq_ah) {
                if (head.code.trim().toLowerCase() == editHead.code.toLowerCase()) {
                    flag3 = true
                }
            }
        })
        if (flag3) {
            swal("Warning!", "Code name already take", "warning");
            return
        }
        //Sub Parent check
        if (editHead.sub && !String(editHead.parent).trim().length) {
            swal("Warning!", "Sub-Head must have a parent", "warning");
            return
        }
        if (!editHead.sub && String(editHead.parent).trim().length) {
            swal("Warning!", "non Sub-Head should not have a parent", "warning");
            return
        }
        if (editHead.parent == editHead.uq_ah) {
            swal("Warning!", "Invalid parent", "warning");
            return
        }
        let object = { ...editHead, page_name: page_name };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-account-head`, object, headers).then((res) => {
            SetSubmitButton(false)
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    // window.location.reload();
                    getaccountheads()
                    setEditAccHeadModalShow(false)
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }
    const setupDefault = () => {
        let headRequest = [
            { "category_name": "Expense", "headArray": ["Advertising", "Bank Fees and Charges", "Janitorial Expenses"] },
            { "category_name": "Assets", "headArray": ["Stock", "Cash"] },
            { "category_name": "Equity", "headArray": [] }
        ]
        let object = { page_name: page_name, headRequest: headRequest, organization_id: organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/selective-account-head`, object, headers).then((res) => {
            SetSubmitButton(false)

            // if (res.data.operation === "success") {
            //     swal("Great!", res.data.message, "success").then((value) => {
            //         // window.location.reload();
            //         // getaccountheads()
            //         // setEditAccHeadModalShow(false)
            //     });
            // }
            // else {
            //     swal("Oops!", res.data.message.substring(0, 30), "error");
            // }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const filteredList = useMemo(() => {
        if (categoryList.length && tempAccountHeadList.length) {
            if (searchValue) {
                return tempAccountHeadList.filter((x) => {
                    let r = new RegExp(searchValue, "gi")
                    if (r.test(x.name) ||
                        r.test(x.add_date_time) ||
                        r.test(x.tax_status)
                    ) {
                        return x
                    }
                })
            }
            else {
                return tempAccountHeadList
            }
        }
    }, [categoryList, tempAccountHeadList, searchValue])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Account Head

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success' onClick={(e) => { setAddAccHeadModalShow(true) }}>
                                <i class="las la-plus"></i>
                                Add New
                            </button>
                            <button className="btn btn-success rounded" onClick={() => { setupDefault() }}>
                                <span className="indicator-label">set Default</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>

                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='mb-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                            </div>

                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    showQuickJumper={true}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Name</th>
                                                            <th className="min-w-150px">Code</th>
                                                            <th className="min-w-150px">Type</th>
                                                            <th className="min-w-150px">Parent-Head</th>
                                                            <th className="min-w-150px">Category</th>
                                                            <th className="min-w-150px">Default</th>
                                                            <th className="min-w-150px">Add Date</th>
                                                            <th className="min-w-150px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredList.length > 0 ?
                                                                filteredList.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((obj) => {
                                                                    return (
                                                                        <tr key={obj.uq_ah}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.name}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-light-info">{obj.code}</span>
                                                                            </td>
                                                                            <td>
                                                                                {obj.sub ? <span className="badge badge-primary me-auto rounded">Sub-Head</span> : <span className="badge badge-secondary me-auto rounded">Head</span>}
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-success me-auto rounded">{tempAccountHeadList.find(tHead => tHead.uq_ah == obj.parent)?.name ?? ""}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-info me-auto rounded">{categoryList.find(aCat => aCat.uq_ac == obj.uq_ac)?.name ?? ""}</span>
                                                                            </td>
                                                                            <td>
                                                                                {obj.default ? <span className="badge badge-secondary me-auto rounded">Default</span> : <span className="badge badge-warning me-auto rounded">Custom</span>}
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.add_date_time).format('hh:mm A, DD MMM YYYY')}</span>
                                                                            </td>
                                                                            <td>
                                                                                <StatusBadge status={obj.status} entity={""} />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                            obj.default ?
                                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li className="dropdown-item">No Options</li>
                                                                                            </ul>
                                                                                            :
                                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li><span onClick={() => { editSetup(obj) }} className="dropdown-item cursor-pointer">Edit details</span></li>
                                                                                            </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={5} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    state == 3 ?
                                        <PageError>
                                            No Account Head Available
                                        </PageError>
                                        :
                                        <PageError>
                                            Please Refresh this page
                                        </PageError>
                        }
                    </div>
                </div>

            </div>
            <Modal show={addAccHeadModalShow} onHide={() => { setAddAccHeadModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Head</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddAccHeadModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Account Category</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                    options={categoryList.map(x => { return { label: x.name, value: x.uq_ac } })}
                                    // value={newWarehouse.branch}
                                    onChange={(val) => {
                                        setNewHead({ ...newHead, uq_ac: val.value, parent: "" })
                                    }}
                                    placeholder="Select Category"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewHead({ ...newHead, name: e.target.value }) }}
                                    placeholder='Name of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Code</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewHead({ ...newHead, code: e.target.value }) }}
                                    placeholder='Codename of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Sub</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="checkbox" className=""
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewHead({ ...newHead, sub: e.target.checked ? 1 : 0 }) }}
                                // placeholder='Name of Head'
                                />
                            </div>
                        </div>
                        {
                            newHead.sub ?
                                <>
                                    <div className="d-flex justify-content-between mb-5">
                                        <div className="col-3 my-auto">
                                            <label className="required fs-5 mb-2">Parent Head</label>
                                        </div>
                                        <div className='col-9 my-auto'>
                                            <Select
                                                className="form-control form-control-solid"
                                                styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                options={accountheadList.filter(h => h.uq_ac == newHead.uq_ac).map(x => { return { label: x.name, value: x.uq_ah } })}
                                                //options={accountheadList.map(x => { return { label: x.name, value: x.uq_ah } })}
                                                // value={newWarehouse.branch}
                                                onChange={(val) => {
                                                    setNewHead({ ...newHead, parent: val.value })
                                                }}
                                                placeholder="Select Parent Head"
                                            />
                                        </div>
                                    </div>
                                </>
                                : null}
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="fs-5 mb-2">Description</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <textarea type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewHead({ ...newHead, description: e.target.value }) }}
                                    placeholder='Description for account head'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setAddAccHeadModalShow(false) }}>Close</button>
                    {
                        // write  &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { newHeadSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={editAccHeadModalShow} onHide={() => { setEditAccHeadModalShow(false) }} size="lg" centered>
                {/* <Modal show={addAccHeadModalShow} size="lg" centered> */}
                <Modal.Header>
                    <Modal.Title>Edit Head</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setEditAccHeadModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Account Category</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                    options={categoryList.map(x => { return { label: x.name, value: x.uq_ac } })}
                                    value={categoryList.map(x => { return { label: x.name, value: x.uq_ac } }).find(y => y.value == editHead.uq_ac)}
                                    onChange={(val) => {
                                        SetEditHead({ ...editHead, uq_ac: val.value })
                                    }}
                                    placeholder="Select Category"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    value={editHead.name}
                                    onChange={(e) => { SetEditHead({ ...editHead, name: e.target.value }) }}
                                    placeholder='Name of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Code</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    value={editHead.code}
                                    onChange={(e) => { SetEditHead({ ...editHead, code: e.target.value }) }}
                                    placeholder='Codename of Head'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Sub</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="checkbox" className=""
                                    checked={editHead.sub}
                                    onChange={(e) => { SetEditHead({ ...editHead, sub: e.target.checked, parent: e.target.checked ? editHead.parent : "" }) }}
                                // placeholder='Name of Head'
                                />
                            </div>
                        </div>
                        {
                            editHead.sub ?
                                <>
                                    <div className="d-flex justify-content-between mb-5">
                                        <div className="col-3 my-auto">
                                            <label className="required fs-5 mb-2">Parent Head</label>
                                        </div>
                                        <div className='col-9 my-auto'>
                                            <Select
                                                className="form-control form-control-solid"
                                                styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                options={accountheadList.filter(w => w.uq_ah != editHead.uq_ah).map(x => { return { label: x.name, value: x.uq_ah } })}
                                                value={accountheadList.map(x => { return { label: x.name, value: x.uq_ah } }).find(x => x.value == editHead.parent)}
                                                onChange={(val) => {
                                                    SetEditHead({ ...editHead, parent: val.value })
                                                }}
                                                placeholder="Select Parent Head"
                                            />
                                        </div>
                                    </div>
                                </>
                                : null}

                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="fs-5 mb-2">Description</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <textarea type="text" className="form-control form-control-solid"
                                    value={editHead.description}
                                    onChange={(e) => { SetEditHead({ ...editHead, description: e.target.value }) }}
                                    placeholder='Description for account head'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setEditAccHeadModalShow(false) }}>Close</button>
                    {
                        // write  &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { editHeadSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

        </>
    );
}
export default AccountHead;