import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ToWords } from 'to-words'
import { Modal, Navbar, Nav } from 'react-bootstrap';

import formatNumber from '../common_component/formatNumber.js';
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';
import CustomerRecurringInvoiceList from './customer-recurring-invoice-list.js';

export const ViewCustomerRecurringInvoice = (props) => {
    const [state, setstate] = useState(2);
    const history = useHistory()

    const [recurringInvoiceDetails, setRecurringInvoiceDetails] = useState(null)
    const [customerRecurringInvoiceHistoryDetails, setCustomerRecurringInvoiceHistoryDetails] = useState([]);
    const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState(null);
    const [branchList, setBranchList] = useState([])

    const [printPdfTemplate, setPrintPdfTemplate] = useState("")
    const [printBillTemplate, setPrintBillTemplate] = useState("")
    const [printModalShow, setPrintModalShow] = useState(false)
    const [downloadModalShow, setDownloadModalShow] = useState(false)
    const [loading, setLoading] = useState(false);

    const [itemDetails, setItemDetails] = useState([]);
    const [taxDetails, setTaxDetails] = useState([]);
    const [billingAddress, setBillingAddress] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [gstDetails, setGstDetails] = useState({});

    const [currencyCode, setCurrencyCode] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [decimalPlace, setDecimalPlace] = useState();

    const [itemsModalShow, setItemsModalShow] = useState(false)
    const [itemsData, setItemsData] = useState([])
    const [adjustmentValue, setAdjustmentValue] = useState("");
    const [adjustmentText, setAdjustmentText] = useState("");

    const [subTotal, setSubTotal] = useState(0)

    const [image, setImage] = useState("");


    var page_name = "view-customer-invoice";

    const { user, read, write, visibility, organization_id, user_role, organization_name } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getCustomerRecurringInvoiceDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-recurring-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "recurring_invoice_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setCustomerInvoiceDetails(body.data.customer_invoice_details);
            setRecurringInvoiceDetails(body.data);


            setCurrencyCode(body.data.customer_invoice_details.invoice_currency.currency_code);
            let decimal = Math.pow(10, parseInt(body.data.customer_invoice_details.invoice_currency.decimal_place));
            setDecimalPlace(decimal);
            setCurrencySymbol(body.data.customer_invoice_details.invoice_currency.currency_symbol);

            setAdjustmentValue(body.data.customer_invoice_details.adjustment.value);
            setAdjustmentText(body.data.customer_invoice_details.adjustment.text);

            let temp = body.data.customer_invoice_details.item_array;
            setItemDetails(temp);

            let arr = [];
            temp.forEach((obj, index) => {
                if (obj.taxable == 0) {
                    obj.taxable_amount = 0;
                }
                else {
                    obj.total_taxable_amount = Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100)) * decimal) / decimal;
                    obj.taxable_amount = Number(obj.rate) * obj.tax_group_percentage / 100;
                }

                if (obj.tax_calculation_method == "TAX_Exclusive") {
                    obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
                    obj.TAX_Exclusive_Price = obj.rate;
                }
                else {
                    obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
                    obj.TAX_Exclusive_Price = obj.rate;
                }

                obj.total_tax = obj.tax_group_details == null ? 0 : obj.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);
                if (obj.tax_group_details != null) {
                    obj.tax_group_details.map((obj1, index1) => {
                        if (arr.find(x => x.tax_id == obj1.tax_id)) {
                            let p = arr.find(x => x.tax_id == obj1.tax_id);
                            p.tax_amount = p.tax_amount + Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
                        }
                        else {
                            let json = {};
                            json.tax_id = obj1.tax_id;
                            json.tax_type = obj1.tax_type;
                            json.tax_name = obj1.tax_name;
                            json.tax_percentage = obj1.tax_percentage;
                            json.tax_amount = Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
                            arr.push(json);
                        }
                    });
                }
            })
            setTaxDetails(arr);

            setSubTotal(Math.round(body.data.customer_invoice_details.item_array.reduce((p, o) => { return (p + (((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable === 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100)))); }, 0) * decimal / decimal));
            setBillingAddress(body.data.customer_invoice_details.billing_address);
            setShippingAddress(body.data.customer_invoice_details.shipping_address);
            setGstDetails(body.data.customer_invoice_details.gstin_details);
        }
    }

    async function getCustomerRecurringInvoiceHistorydetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-recurring-invoice-history-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "recurring_invoice_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setCustomerRecurringInvoiceHistoryDetails(body.data);
        }
    }

    async function getBranchList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setBranchList(body.data);
        }
        else {
            throw "branch list api error"
        }
    }

    async function getCustomerInvoiceTemplate() {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-customer-invoice-pdf-template`, { method: 'POST', body: JSON.stringify({ "email": user.email, "category_type": "customer_invoice", "format_type": "pdf_type", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation === "success") {
                setPrintPdfTemplate(body.data);
            } else {
                throw "Invoice template API error";
            }
        } catch (error) {
            console.error("Invoice template API call:", error);
        }
    }

    async function getCustomerInvoiceBillTemplate() {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-customer-invoice-bill-template`, { method: 'POST', body: JSON.stringify({ "email": user.email, "category_type": "customer_invoice", "format_type": "bill_type", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation === "success") {
                setPrintBillTemplate(body.data);
            } else {
                throw "invoice template for bill API error";
            }
        } catch (error) {
            console.error("Invoice template for bill API call:", error);
        }
    }

    async function getorganizationdetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "id": organization_id, "flag": "details" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            if ((body[0].file_name !== undefined) && (typeof body[0].file_name === "string")) {
                setImage(`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/organization_logo/${body[0].file_name}`);
            }
        }
        else {
            console.log("Invalid organization image api call")
            return null
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let parr = [
                getCustomerRecurringInvoiceDetails(),
                getCustomerRecurringInvoiceHistorydetails(),
                getBranchList(),
                getCustomerInvoiceTemplate(),
                getCustomerInvoiceBillTemplate(),
                getorganizationdetails()
            ]

            Promise.all([parr])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])


    const handleOperation = (operationType) => {

        let tbr = branchList.find(x => x.branch_id == customerInvoiceDetails.branch_id)
        let gst = (customerInvoiceDetails.gstin_details)

        let head_details =
            `<div class="card-body p-9">
			<div class="text-end">
       			<span class="fw-semi-bold fs-10">SALES INVOICE</span>
    		</div>
			${image ? `<div>
				    <img src="${image}" alt="Organization Logo" class="rounded float-left" style=" height: 200px; object-fit: contain;">
				</div>` : `<div class="fw-bold"><h2>${organization_name}</h2></div>`}
				<div class="row mb-7">				
					<span class="fw-semibold text-gray-800 fs-6">
						Org GST: ${tbr.GstIn}
					</span>				
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						Branch Details: ${tbr.branch_name}-${tbr.branch_address_street1}-${tbr.branch_address_city}-${tbr.branch_address_state}-${tbr.branch_address_zipcode}
					</span>
				</div>
				<div class="row mb-7">	
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${tbr.branch_address_phone}
					</span>					
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						IRN: NA
					</span>					
				</div>				
				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Way Bill No: NA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Way Bill Date: NA
					</span>
				</div>
			</div >`

        let part_One =
            `<div class="card-body p-9">
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reference Number: ${customerInvoiceDetails.reference_number ? customerInvoiceDetails.reference_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Number: ${customerInvoiceDetails.customer_order_number ? customerInvoiceDetails.customer_order_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Date: ${customerInvoiceDetails.order_date ? moment(customerInvoiceDetails.order_date).format('ll') : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Invocie Number: ${customerInvoiceDetails.customer_invoice_number ? customerInvoiceDetails.customer_invoice_number : "NA"}
					</span>
				</div >
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Invoice Date: ${customerInvoiceDetails.invoice_date ? moment(customerInvoiceDetails.invoice_date).format('ll') : "NA"}
					</span>
				</div >
			</div>`

        let part_two =
            `<div class="card-body p-9">
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Transportation Mode: ${customerInvoiceDetails.transportation_mode ? customerInvoiceDetails.transportation_mode : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Vehicle Number: ${customerInvoiceDetails.vehicle_number ? customerInvoiceDetails.vehicle_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Date of Supply: NA
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Place of Supply: ${customerInvoiceDetails.place_of_supply ? customerInvoiceDetails.place_of_supply : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Payment Terms: ${customerInvoiceDetails.term ? customerInvoiceDetails.term : "NA"}
					</span>
				</div>
			</div>`

        let part_three =
            `<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Customer Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Name: ${customerInvoiceDetails.customer_name}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${customerInvoiceDetails.customer_workphone ? customerInvoiceDetails.customer_workphone : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						email: ${customerInvoiceDetails.customer_email ? customerInvoiceDetails.customer_email : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						GST: ${gst.GstIn === "" ? "NA" : gst.GstIn}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						PAN: NA
					</span>
				</div>
			</div>`

        let tb = (customerInvoiceDetails.billing_address)
        let ts = (customerInvoiceDetails.shipping_address)

        let part_four =
            `<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Address Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reciever Address (Billing): ${tb.address_line1 == "" ? "" : tb.address_line1 + ", "}${tb.address_line2 == "" ? "" : tb.address_line2 + ", "}${tb.city == "" ? "" : tb.city + ", "}${tb.state == "" ? "" : tb.state + ", "}${tb.country == "" ? "" : tb.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Consignee Address (Shipping): ${ts.address_line1 == "" ? "" : ts.address_line1 + ", "}${ts.address_line2 == "" ? "" : ts.address_line2 + ", "}${ts.city == "" ? "" : ts.city + ", "}${ts.state == "" ? "" : ts.state + ", "}${ts.country == "" ? "" : ts.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Due Date: ${moment(customerInvoiceDetails.due_date).format('ll')}
					</span>
				</div>				
			</div>`

        let table_details =

            `<table class="text-center table table-bordered">
				<thead>
					<tr>
						<th>Sl No.</th>
						<th>Description of Goods</th>
						<th>HSN/SAC</th>
						<th>Quantity</th>
						<th>Rate</th>
						<th>Disc %</th>
						<th>Disc Amt.</th>
						<th>Tax Type</th>
						<th>Tax %</th>
						<th>Tax Inc.</th>
						<th>Tax Exc.</th>							
						<th>Amount</th>
					</tr>						
				</thead>
				<tbody>
					##
					<tr>
						<td>{{sl_no}}</td>
						<td>{{description_of_goods}}</td>
						<td>{{product_tax_code}}</td>
						<td>{{quantity}}</td>
						<td>{{rate}}</td>
						<td>{{discount_percentage}}</td>
						<td>{{discount_amount}}</td>							
						<td>{{tax_group}}</td>							
						<td>{{taxable_percentage}}</td>							
						<td>{{tax_inclusive}}</td>							
						<td>{{tax_exclusive}}</td>							
						<td>{{amount}}</td>
					</tr>
					##
				</tbody>									
			</table>`

        let td = taxDetails.reduce((p, o) => {
            if (p.hasOwnProperty(o.tax_type)) {
                p[o.tax_type] += o.tax_amount
            }
            else {
                p[o.tax_type] = o.tax_amount
            }
            return p
        }, {})

        let cd = customerInvoiceDetails.invoice_currency

        let decimalPlace = cd.decimal_place;

        let rs = table_details.indexOf("##")
        let re = table_details.lastIndexOf("##")
        let r_code = table_details.substring(rs + 2, re)


        let item = customerInvoiceDetails.item_array.map((obj, i) => {

            let temp = r_code
            temp = temp.replace("{{sl_no}}", i + 1)
            temp = temp.replace("{{description_of_goods}}", obj.item_name)
            temp = temp.replace("{{product_tax_code}}", obj.tax_code)
            temp = temp.replace("{{quantity}}", obj.quantity)
            temp = temp.replace("{{rate}}", Math.round(obj.rate * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
            temp = temp.replace("{{discount_percentage}}", obj.discount_type == "percentage" ? obj.discount_unit : Math.round((Number(obj.discount_unit) / (obj.rate * obj.quantity)) * 100 * decimalPlace) / decimalPlace)
            temp = temp.replace("{{discount_amount}}", obj.discount_type == "amount" ? obj.discount_unit : Math.round((obj.rate * obj.quantity * Number(obj.discount_unit) / 100) * decimalPlace) / decimalPlace)

            let taxGroupString = obj.tax_group_id === null ? "NA" : obj.tax_group_details.map((Obj1) => {
                return `${Obj1.tax_name}`;
            }).join(', ');

            temp = temp.replace("{{tax_group}}", obj.tax_group_id === null ? "NA" : taxGroupString)
            temp = temp.replace("{{taxable_percentage}}", obj.tax_group_id === null ? 0 : obj.tax_group_percentage)
            temp = temp.replace("{{tax_inclusive}}", Math.round(itemDetails.find(x => x.item_id == obj.item_id).TAX_Inclusive_Price * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
            temp = temp.replace("{{tax_exclusive}}", Math.round(itemDetails.find(x => x.item_id == obj.item_id).TAX_Exclusive_Price * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
            temp = temp.replace("{{amount}}", Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) + (obj.taxable == 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace)

            let tcd = ""
            if (td.hasOwnProperty("CGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "CGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("SGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "SGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("UTGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "UTGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("IGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "IGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }

            temp = temp.replace("{{tax_column_details}}", tcd)
            return temp

        }).join("")

        table_details = table_details.substring(0, rs) + item + table_details.substring(re + 2, table_details.length);

        let table_footer =
            `<table class="text-center table table-bordered">		
				<tfoot>
					<tr>
						<td colspan="9" rowspan="{{footer_row_count}}">
							Total in Words <br> {{total_in_words}} <br><br>
							Customer Note <br> {{customer_note}} <br><br>
							Terms and Conditions <br> {{terms_and_conditions}} <br><br>
						</td>
						<td colspan="2">SubTotal</td>
						<td>{{subtotal}}</td>
					</tr>
						{{tax_total_details}}
						{{tds_tcs_details}}
					<tr>
						<td colspan="2">{{adjustment_text}}</td>
						<td>{{adjustment_value}}</td>
					</tr>
					<tr>
						<td colspan="9">Invoice Grand Total</td>
						<td colspan="3">{{document_total}}</td>
					</tr>
				</tfoot>					
			</table>`

        function round(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
        }

        let roundedSubTotal = round(subTotal, decimalPlace);

        table_footer = table_footer.replace("{{subtotal}}", roundedSubTotal)


        let footer_row_count = 3
        let roundedFd1 = Object.entries(td).map(([taxType, amount]) => {
            footer_row_count++
            return `<tr>
				<td colspan="2">${taxType} Total</td>
				<td>${round(amount, decimalPlace)}</td>
			</tr>`
        }).join('');

        let roundedFd2 = `<tr>
			  <td colspan="2">TDS/TCS Total</td>
			  <td>${round(Math.round((customerInvoiceDetails.tax_category == "TDS" ? customerInvoiceDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerInvoiceDetails.tax_id == null ? 0 : parseFloat(customerInvoiceDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace, decimalPlace)}</td>
		  </tr>`

        table_footer = table_footer.replace("{{footer_row_count}}", footer_row_count)
        table_footer = table_footer.replace("{{tax_total_details}}", roundedFd1);
        table_footer = table_footer.replace("{{tds_tcs_details}}", roundedFd2);

        table_footer = table_footer.replace("{{adjustment_text}}", customerInvoiceDetails.adjustment.text)
        table_footer = table_footer.replace("{{adjustment_value}}", customerInvoiceDetails.adjustment.value)

        let tw = new ToWords()
        table_footer = table_footer.replace("{{total_in_words}}", tw.convert(customerInvoiceDetails.grand_total) + " Only")
        table_footer = table_footer.replace("{{customer_note}}", customerInvoiceDetails.customer_notes)
        table_footer = table_footer.replace("{{terms_and_conditions}}", customerInvoiceDetails.terms_and_conditions)
        table_footer = table_footer.replace("{{document_total}}", customerInvoiceDetails.invoice_currency.currency_symbol + " " + customerInvoiceDetails.grand_total)

        let modifiedTemplate = printPdfTemplate
        modifiedTemplate = modifiedTemplate.replace("$head_details", head_details)
        modifiedTemplate = modifiedTemplate.replace("$part_1", part_One)
        modifiedTemplate = modifiedTemplate.replace("$part_2", part_two)
        modifiedTemplate = modifiedTemplate.replace("$part_3", part_three)
        modifiedTemplate = modifiedTemplate.replace("$part_4", part_four)
        modifiedTemplate = modifiedTemplate.replace("$table_details", table_details)
        modifiedTemplate = modifiedTemplate.replace("$table_footer", table_footer)

        if (operationType === 'print') {
            let nnww = window.open()
            nnww.document.body.parentNode.innerHTML = modifiedTemplate;

            setTimeout(() => {
                nnww.print();
            }, 1000);
        }
        else if (operationType === 'download') {
            return modifiedTemplate;
        }
    }

    const handleOperationBill = (operation) => {
        let tbr = branchList.find(x => x.branch_id === customerInvoiceDetails.branch_id)
        let gst = customerInvoiceDetails.gstin_details

        let organization_details =
            `<div class="card-body p-2">
				<div class="text-end">
					<span class="fw-semi-bold fs-7">SALES INVOICE</span>
				</div>
				${image ? `
			<div>
				<img src="${image}" alt="Organization Logo" class="rounded float-left" style="height: 100px; object-fit: contain;">
				<div class="fw-bold"><h2>${organization_name}</h2></div>
			</div>`
                :
                `<div class="fw-bold"><h5>${organization_name}</h5></div>`
            }
			</div>`

        let branch_details =
            `<div class="card-body p-2">
				<div class="row mb-7">				
					<span class="fw-semibold text-gray-800 fs-6">
						Org GST: ${tbr.GstIn}
					</span>				
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						Branch Details: ${tbr.branch_name}-${tbr.branch_address_street1}-${tbr.branch_address_city}-${tbr.branch_address_state}-${tbr.branch_address_zipcode}
					</span>
				</div>
				<div class="row mb-7">	
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${tbr.branch_address_phone}
					</span>					
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						IRN: NA
					</span>					
				</div>				
				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Way Bill No: NA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Way Bill Date: NA
					</span>
				</div>
			</div >`

        let document_details =
            `<div class="card-body p-2">
				<label class="fw-bold text-muted">Invoice Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reference Number: ${customerInvoiceDetails.reference_number ? customerInvoiceDetails.reference_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Number: ${customerInvoiceDetails.customer_order_number ? customerInvoiceDetails.customer_order_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Date: ${customerInvoiceDetails.order_date ? moment(customerInvoiceDetails.order_date).format('ll') : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Invocie Number: ${customerInvoiceDetails.customer_invoice_number ? customerInvoiceDetails.customer_invoice_number : "NA"}
					</span>
				</div >
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Invoice Date: ${customerInvoiceDetails.invoice_date ? moment(customerInvoiceDetails.invoice_date).format('ll') : "NA"}
					</span>
				</div >
			</div>`

        let tb = customerInvoiceDetails.billing_address
        let ts = customerInvoiceDetails.shipping_address

        let customer_details =
            `<div class="card-body p-2">
				<label class="fw-bold text-muted">Customer Details</label>				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Name: ${customerInvoiceDetails.customer_name}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${customerInvoiceDetails.customer_workphone ? customerInvoiceDetails.customer_workphone : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						email: ${customerInvoiceDetails.customer_email ? customerInvoiceDetails.customer_email : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						GST: ${gst.GstIn === "" ? "NA" : gst.GstIn}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						PAN: NA
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reciever Address (Billing): ${tb.address_line1 == "" ? "" : tb.address_line1 + ", "}${tb.address_line2 == "" ? "" : tb.address_line2 + ", "}${tb.city == "" ? "" : tb.city + ", "}${tb.state == "" ? "" : tb.state + ", "}${tb.country == "" ? "" : tb.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Consignee Address (Shipping): ${ts.address_line1 == "" ? "" : ts.address_line1 + ", "}${ts.address_line2 == "" ? "" : ts.address_line2 + ", "}${ts.city == "" ? "" : ts.city + ", "}${ts.state == "" ? "" : ts.state + ", "}${ts.country == "" ? "" : ts.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Due Date: ${moment(customerInvoiceDetails.due_date).format('ll')}
					</span>
				</div>				
			</div>`

        let table_details =

            `<table class="text-center table table-bordered">
				<thead>
					<tr>						
						<th rowspan="2">Item Name</th>						
						<th rowspan="2">Qty</th>												
						<th rowspan="2">Amt</th>
						<th rowspan="2">Tax</th>
					</tr>						
				</thead>
				<tbody>
					##
					<tr>						
						<td>{{description_of_goods}}</td>						
						<td>{{quantity}}</td>												
						<td>{{amount}}</td>
						<td>{{taxable_percentage}}</td>
					</tr>
					##
				<tbody>	
				<tfoot>
					<tr>							
						<td colspan="3">SubTotal</td>
						<td>{{subtotal}}</td>
					</tr>
					{{tax_total_details}}
					{{tds_tcs_details}}
					<tr>
						<td colspan="3">{{adjustment_text}}</td>
						<td>{{adjustment_value}}</td>
					</tr>
					<tr>
						<td colspan="3">Order Grand Total</td>
						<td colspan="3">{{document_total}}</td>
					</tr>
					<tr>
					<td colspan="4" style="text-align: left;">
						Total Items: 2<br>
						Total in Words: <br> Fifty Four Thousand Eight Hundred Point Zero Two Only <br><br>
						Customer Note: <br>  <br><br>
						Terms and Conditions: <br>  <br><br>
					</td>
					</tr>
				</tfoot>						
			</table>`

        let td = taxDetails.reduce((p, o) => {
            if (p.hasOwnProperty(o.tax_type)) {
                p[o.tax_type] += o.tax_amount
            }
            else {
                p[o.tax_type] = o.tax_amount
            }
            return p
        }, {})

        let tax_column_count = 1
        let tch1 = ""
        let tch2 = ""
        if (td.hasOwnProperty("CGST")) {
            tch1 += `<th colspan="2">CGST</th>`
            tch2 += `<th>%</th>
			<th>Amt.</th>`
            tax_column_count += 2
        }
        if (td.hasOwnProperty("SGST")) {
            tch1 += `<th colspan="2">SGST</th>`
            tch2 += `<th>%</th>
			<th>Amt.</th>`
            tax_column_count += 2
        }
        if (td.hasOwnProperty("UTGST")) {
            tch1 += `<th colspan="2">UTGST</th>`
            tch2 += `<th>%</th>
			<th>Amt.</th>`
            tax_column_count += 2
        }
        if (td.hasOwnProperty("IGST")) {
            tch1 += `<th colspan="2">IGST</th>`
            tch2 += `<th>%</th>
			<th>Amt.</th>`
            tax_column_count += 2
        }

        let footer_row_count = 2
        let fd1 = ""
        if (td.hasOwnProperty("CGST")) {
            fd1 += `<tr>
				<td colspan="${tax_column_count}">CGST Total</td>
				<td>${td["CGST"]}</td>
			</tr>`
            footer_row_count++
        }
        if (td.hasOwnProperty("SGST")) {
            fd1 += `<tr>
				<td colspan="${tax_column_count}">SGST Total</td>
				<td>${td["SGST"]}</td>
			</tr>`
            footer_row_count++
        }
        if (td.hasOwnProperty("UTGST")) {
            fd1 += `<tr>
				<td colspan="${tax_column_count}">UTGST Total</td>
				<td>${td["UTGST"]}</td>
			</tr>`
            footer_row_count++
        }
        if (td.hasOwnProperty("IGST")) {
            fd1 += `<tr>
				<td colspan="${tax_column_count}">IGST Total</td>
				<td>${td["IGST"]}</td>
			</tr>`
            footer_row_count++
        }

        let fd2 = ""
        if (customerInvoiceDetails.tax_category != null) {
            fd2 = `<tr>
				<td colspan="${tax_column_count}">TDS/TCS Total</td>
				<td>${Math.round((customerInvoiceDetails.tax_category == "TDS" ? customerInvoiceDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerInvoiceDetails.tax_id == null ? 0 : parseFloat(customerInvoiceDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace}</td>
			</tr>`
            footer_row_count++
        }

        let cd = customerInvoiceDetails.invoice_currency

        let decimalPlace = cd.decimal_place;

        function round(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
        }

        let roundedSubTotal = round(subTotal, decimalPlace);

        table_details = table_details.replace("{{subtotal}}", roundedSubTotal)

        let roundedFd1 = Object.entries(td).map(([taxType, amount]) => {
            return `<tr>
				<td colspan="${tax_column_count}">${taxType} Total</td>
				<td>${round(amount, decimalPlace)}</td>
			</tr>`
        }).join('');

        let roundedFd2 = `<tr>
			  <td colspan="${tax_column_count}">TDS/TCS Total</td>
			  <td>${round(Math.round((customerInvoiceDetails.tax_category == "TDS" ? customerInvoiceDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerInvoiceDetails.tax_id == null ? 0 : parseFloat(customerInvoiceDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace, decimalPlace)}</td>
		  </tr>`

        table_details = table_details.replace("{{footer_row_count}}", footer_row_count)
        table_details = table_details.replace("{{tax_total_details}}", roundedFd1);
        table_details = table_details.replace("{{tds_tcs_details}}", roundedFd2);

        table_details = table_details.replace("{{adjustment_text}}", customerInvoiceDetails.adjustment.text)
        table_details = table_details.replace("{{adjustment_value}}", customerInvoiceDetails.adjustment.value)

        let d = customerInvoiceDetails.item_array
        let tw = new ToWords()

        table_details = table_details.replace("{{total_items}}", d.length)
        table_details = table_details.replace("{{total_in_words}}", tw.convert(customerInvoiceDetails.grand_total) + " Only")
        table_details = table_details.replace("{{customer_note}}", customerInvoiceDetails.customer_notes)
        table_details = table_details.replace("{{terms_and_conditions}}", customerInvoiceDetails.terms_and_conditions)

        let rs = table_details.indexOf("##")
        let re = table_details.lastIndexOf("##")
        let r_code = table_details.substring(rs + 2, re)

        let item = customerInvoiceDetails.item_array.map((obj, i) => {

            let temp = r_code
            temp = temp.replace("{{description_of_goods}}", obj.item_name)
            temp = temp.replace("{{quantity}}", obj.quantity)
            temp = temp.replace("{{amount}}", Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) + (obj.taxable == 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace)
            temp = temp.replace("{{taxable_percentage}}", obj.tax_group_percentage === null ? 0 : obj.tax_group_percentage)

            let tcd = ""
            if (td.hasOwnProperty("CGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "CGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("SGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "SGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("UTGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "UTGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }
            if (td.hasOwnProperty("IGST")) {
                let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "IGST")
                if (v != undefined) {
                    tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
                }
                else {
                    tcd += `<td>--</td>
					<td>--</td>`
                }
            }

            temp = temp.replace("{{tax_column_details}}", tcd)
            table_details = table_details.replace("{{document_total}}", customerInvoiceDetails.invoice_currency.currency_symbol + " " + customerInvoiceDetails.grand_total)
            return temp
        }).join("")
        table_details = table_details.substring(0, rs) + item + table_details.substring(re + 2, table_details.length);

        let modifiedBillTemplate = printBillTemplate
        modifiedBillTemplate = modifiedBillTemplate.replace("{{organization_details}}", organization_details)
        modifiedBillTemplate = modifiedBillTemplate.replace("{{branch_details}}", branch_details)
        modifiedBillTemplate = modifiedBillTemplate.replace("{{main_details}}", document_details)
        modifiedBillTemplate = modifiedBillTemplate.replace("{{customer_details}}", customer_details)
        modifiedBillTemplate = modifiedBillTemplate.replace("{{item_details}}", table_details)

        // console.log(modifiedTemplate);

        if (operation === 'print') {
            let nnww = window.open()
            nnww.document.body.parentNode.innerHTML = modifiedBillTemplate;

            setTimeout(() => {
                nnww.print();
            }, 1000);
        }
        else if (operation === 'download') {
            return modifiedBillTemplate;
        }
    }

    const generateDownload = async () => {

        setLoading(true);
        const htmlContent = handleOperation('download');

        let object = {};
        object.organizaiton_name = organization_name
        object.html_code = htmlContent
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setDownloadModalShow(true)


        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/download-invoice-details-pdf`, object, headers).then((res) => {
            setDownloadModalShow(false)
            if (res.data.operation === "success") {
                swal("File Created!", "Go to File.", "success").then(() => {
                    window.open(res.data.data.file_url, "_blank");
                    setLoading(false);
                });
            } else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
                setLoading(false);
            });
    }

    const calculateUpcomingInvoiceDatesAgainstFrequency = (startDate, endDate, frequencyDetails, noDays) => {
        const start = moment(startDate);
        const end = moment(endDate);
        const invoiceDates = [];

        let currentInvoiceDate = start;
        switch (frequencyDetails.time_period) {
            case 'week':
                currentInvoiceDate = currentInvoiceDate.day(frequencyDetails.week_day);
                if (currentInvoiceDate.isBefore(start)) {
                    currentInvoiceDate.add(1, 'week');
                }
                break;
            case 'month':
                currentInvoiceDate = currentInvoiceDate.date(frequencyDetails.month_date);
                if (currentInvoiceDate.isBefore(start)) {
                    currentInvoiceDate.add(1, 'month');
                }
                break;
            case 'year':
                currentInvoiceDate = currentInvoiceDate.month(frequencyDetails.month).date(frequencyDetails.month_date);
                if (currentInvoiceDate.isBefore(start)) {
                    currentInvoiceDate.add(1, 'year');
                }
                break;
        }

        while (!currentInvoiceDate.isAfter(end) && invoiceDates.length < noDays) {
            if (currentInvoiceDate.isAfter(moment().startOf('day'))) {
                invoiceDates.push({ date: currentInvoiceDate.format('ll'), day: currentInvoiceDate.format('dddd') });
            }

            switch (frequencyDetails.time_period) {
                case 'day':
                    currentInvoiceDate.add(frequencyDetails.frequency, 'days');
                    break;
                case 'week':
                    currentInvoiceDate.add(frequencyDetails.frequency, 'weeks');
                    break;
                case 'month':
                    currentInvoiceDate.add(frequencyDetails.frequency, 'months');
                    break;
                case 'year':
                    currentInvoiceDate.add(frequencyDetails.frequency, 'years');
                    break;
            }
        }

        return invoiceDates;
    };


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Recurring Invoice
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>

                        {
                            state === 1 &&
                            <Navbar collapseOnSelect expand="lg" className="me-5 position-relative">
                                <Navbar.Toggle />
                                <Navbar.Collapse>
                                    <Nav className="align-items-end end-0 gap-3 navbar-nav p-5 position-absolute z-index-1">
                                        <Nav.Item>
                                            <button className='btn btn-hover-scale btn-sm btn-info' onClick={() => { setPrintModalShow(true) }}><i className="bi bi-printer fs-2x p-0"></i></button>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <button className='btn btn-hover-scale btn-sm btn-info' onClick={() => { setDownloadModalShow(true) }}><i className="bi bi-file-earmark-arrow-down fs-2x p-0"></i></button>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        }
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state === 1 ?
                            <>
                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                    <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoice_summary" aria-selected="true" role="tab">Recurring Summary</a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoice_history" aria-selected="false" role="tab" tabIndex="-1">Recurring History</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        {
                                            recurringInvoiceDetails &&
                                            <div className="tab-pane fade active show" id="kt_ecommerce_sales_invoice_summary" role="tab-panel">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                        <div className="card flex-equal">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Recurring Details (#{recurringInvoiceDetails.recurring_invoice_number})</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-4">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Status
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">
                                                                                <button className="py-2 me-1 rounded btn btn-sm btn-success">{recurringInvoiceDetails.recurring_invoice_status}</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Start Date
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{moment(recurringInvoiceDetails.start_date).format("ll")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>End Date
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{moment(recurringInvoiceDetails.end_date).format("ll")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Profile Name
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{recurringInvoiceDetails.profile_name !== "" ? recurringInvoiceDetails.profile_name : "NA"}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="card flex-equal">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>GSTIN Details</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-4">
                                                                <div className="table-responsive">
                                                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                        <tbody className="fw-semibold text-gray-600">
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                                <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                                <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>GST Treatment
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{gstDetails.gst_treatment}</td>
                                                                            </tr>
                                                                            {
                                                                                (gstDetails.gst_treatment !== "Consumer" && gstDetails.gst_treatment !== "Unregistered Business") &&
                                                                                <>
                                                                                    <tr>
                                                                                        <td className="text-muted">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                                        <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                                    </svg>
                                                                                                </span>GSTIN
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="fw-bold text-end">{gstDetails.GstIn}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-muted">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                                        <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                                        <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                                    </svg>
                                                                                                </span>Trade Name
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="fw-bold text-end">{gstDetails.trade_name !== "" ? gstDetails.trade_name : "No given trade name"}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-muted">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                                        <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                                        <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                                    </svg>
                                                                                                </span>Legal Name
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="fw-bold text-end">{gstDetails.legal_name}</td>
                                                                                    </tr>
                                                                                </>
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                        <div className="card flex-equal">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Customer Details</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-4">
                                                                <div className="table-responsive">
                                                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                        <tbody className="fw-semibold text-gray-600">
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
                                                                                                <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
                                                                                                <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                                                            </svg>
                                                                                        </span>Customer Name
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{customerInvoiceDetails.customer_name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Customer Email
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{customerInvoiceDetails.customer_email !== "" ? customerInvoiceDetails.customer_email : "No Email-address added"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Customer Phone
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{customerInvoiceDetails.customer_workphone ? customerInvoiceDetails.customer_workphone : "NA"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card flex-equal">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Associated Details</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-4">
                                                                <div className="table-responsive">
                                                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                        <tbody className="fw-semibold text-gray-600">
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                                <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Branch
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{recurringInvoiceDetails.branch_name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                                <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Warehouse
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{recurringInvoiceDetails.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="currentColor"></path>
                                                                                                <path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Place Of Supply
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{customerInvoiceDetails.place_of_supply}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-muted">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="svg-icon svg-icon-2 me-2">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z" fill="currentColor"></path>
                                                                                                <path d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>Currency
                                                                                    </div>
                                                                                </td>
                                                                                <td className="fw-bold text-end">{currencyCode}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                        <div className="card card-flush flex-equal">
                                                            <div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
                                                                <img src="/assets/media/icons/duotune/ecommerce/ecm001.svg" className="w-125px" alt="Location Icon" />
                                                            </div>
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Billing Address</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-3">
                                                                <div className="address-info">
                                                                    {
                                                                        billingAddress.address_line1 &&
                                                                        <div className="address">
                                                                            <i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line1}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        billingAddress.address_line2 &&
                                                                        <div className="address">
                                                                            <i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line2}
                                                                        </div>
                                                                    }
                                                                    <div className="location">
                                                                        <i className="fas fa-map-marker-alt"></i> {billingAddress.city}, {billingAddress.state}
                                                                    </div>
                                                                    <div className="pincode">
                                                                        <i className="fas fa-thumbtack"></i> {billingAddress.pincode}
                                                                    </div>
                                                                    <div className="country">
                                                                        <i className="fas fa-globe"></i> {billingAddress.country}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush flex-equal">
                                                            <div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
                                                                <img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-125px" alt="Location Icon" />
                                                            </div>
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Shipping Address</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-3">
                                                                <div className="address-info">
                                                                    {
                                                                        shippingAddress.address_line1 &&
                                                                        <div className="address">
                                                                            <i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line1}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        shippingAddress.address_line2 &&
                                                                        <div className="address">
                                                                            <i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line2}
                                                                        </div>
                                                                    }
                                                                    <div className="location">
                                                                        <i className="fas fa-map-marker-alt"></i> {shippingAddress.city}, {shippingAddress.state}
                                                                    </div>
                                                                    <div className="pincode">
                                                                        <i className="fas fa-thumbtack"></i> {shippingAddress.pincode}
                                                                    </div>
                                                                    <div className="country">
                                                                        <i className="fas fa-globe"></i> {shippingAddress.country}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card">
                                                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                                            <div className="card-title">
                                                                <h2>Invoiced Items</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-4">
                                                            {
                                                                itemDetails.length > 0 ?
                                                                    <div className="d-flex justify-content-between flex-column">
                                                                        <div className="table-responsive border-bottom mb-9">
                                                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                                <thead>
                                                                                    <tr className="border-bottom fs-6 fw-bold text-gray-700">
                                                                                        <th className="min-w-100px">Products</th>
                                                                                        <th className="min-w-70px">Rate<br />(Tax Exclusive)</th>
                                                                                        <th className="min-w-70px">Rate<br />(Tax Inclusive)</th>
                                                                                        <th className="min-w-80px">QTY</th>
                                                                                        <th className="min-w-70px">Discount</th>
                                                                                        <th className="min-w-70px">Total Amt.<br />Excl.</th>
                                                                                        <th className="min-w-70px">Tax Percentage</th>
                                                                                        <th className="min-w-70px">Tax Amount</th>
                                                                                        <th className="min-w-100px text-end pb-2">Total</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="fw-semibold text-gray-600">
                                                                                    {itemDetails.map((Obj, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>
                                                                                                    <div className="fw-bold">
                                                                                                        <div className="fw-bold">{Obj.item_name}</div>
                                                                                                        {Obj.tax_code !== "" && <span className="badge badge-light-primary me-2">{Obj.tax_code_type}: {Obj.tax_code}</span>}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{currencySymbol} {Math.round(Obj.TAX_Exclusive_Price * decimalPlace) / decimalPlace}</td>
                                                                                                <td>{currencySymbol} {Math.round(Obj.TAX_Inclusive_Price * decimalPlace) / decimalPlace}</td>
                                                                                                <td>{Obj.quantity}</td>
                                                                                                <td>
                                                                                                    <div className="fw-bold">
                                                                                                        <div className="fw-bold">
                                                                                                            {currencySymbol}{Obj.discount_type == "amount" ? <>{Obj.discount_unit}</> : <>{Math.round((Obj.rate * Obj.quantity * Number(Obj.discount_unit) / 100) * decimalPlace) / decimalPlace}</>}</div>
                                                                                                        {Obj.discount_type !== "amount" && <span className="badge badge-light-primary me-2">{Obj.discount_type !== "amount" && <>{Obj.discount_unit} %</>}</span>}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{currencySymbol} {Math.round(((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * decimalPlace) / decimalPlace}</td>
                                                                                                {Obj.taxable === 1 ? <td><div className="ms-5"><div>{Obj.tax_group_name} ({Obj.tax_group_percentage}%)</div><div className="mb-8">
                                                                                                    {Obj.tax_group_details.map((Obj1, index1) => {
                                                                                                        return (
                                                                                                            <span key={index1} className="badge badge-light-primary me-2">{Obj1.tax_name} ({Obj1.tax_percentage}%)</span>
                                                                                                        )
                                                                                                    })}
                                                                                                </div></div></td> : <td>---</td>}
                                                                                                <td>{currencySymbol} {Math.round((Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100)) * decimalPlace) / decimalPlace}</td>
                                                                                                <td className="text-end">{currencySymbol} {Math.round((((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) + (Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap justify-content-between">
                                                                            <div className="d-flex flex-column mw-md-300px">
                                                                                <div className="fw-semibold fs-5 mb-3 text-dark00">Tax Calculation</div>
                                                                                {taxDetails.map((Obj, index) => {
                                                                                    return (
                                                                                        <div key={index} className="d-flex flex-stack text-gray-800 mb-3 fs-6">
                                                                                            <div className="fw-semibold pe-5">{Obj.tax_name} ({Obj.tax_percentage}%):</div>
                                                                                            <div className="text-end fw-norma">{currencySymbol} {Obj.tax_amount}</div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>

                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="mw-300px">
                                                                                    <div className="d-flex flex-stack mb-3">
                                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {subTotal}</div>
                                                                                    </div>
                                                                                    <div className="d-flex flex-stack mb-3">
                                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Tax Percentage {customerInvoiceDetails.tax_percentage !== null && <>({customerInvoiceDetails.tax_name}-{customerInvoiceDetails.tax_percentage}%)</>}</div>
                                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {Math.round((customerInvoiceDetails.tax_category == "TDS" ? itemDetails.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerInvoiceDetails.tax_id == null ? 0 : parseFloat(customerInvoiceDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace}</div>
                                                                                    </div>
                                                                                    <div className="d-flex flex-stack mb-3">
                                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">{adjustmentText}</div>
                                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {adjustmentValue}</div>
                                                                                    </div>
                                                                                    <div className="d-flex flex-stack">
                                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
                                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {customerInvoiceDetails.grand_total}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='text-center'><span className='text-gray-600 fs-2tx'>No items present</span></div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Additional Information</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Term
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.term == null ? "No term period provided" : (customerInvoiceDetails.term.term_name + "---" + customerInvoiceDetails.term.term_days + " days")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Subject
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.subject !== "" ? customerInvoiceDetails.subject : "No subject added"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Customer Notes
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.customer_notes !== "" ? customerInvoiceDetails.customer_notes : "No notes added"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Terms and Conditions
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.terms_and_conditions !== "" ? customerInvoiceDetails.terms_and_conditions : "No terms or conditions provided"}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="tab-pane fade" id="kt_ecommerce_sales_invoice_history" role="tab-panel">
                                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                                <div className="card card-flush py-4 flex-row-fluid">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2>Upcoming Invoices</h2>
                                                        </div>
                                                    </div>

                                                    {
                                                        customerInvoiceDetails &&
                                                        <div className="card-body pt-0">
                                                            <div className='container'>
                                                                <div className='d-flex flex-wrap gap-3 mb-3'>
                                                                    <div className='bg-light-primary p-4 rounded d-flex align-items-center gap-4'>
                                                                        <div className='bg-primary rounded p-4'>
                                                                            <i className="bi bi-person-lines-fill px-0 fs-1 text-white"></i>
                                                                        </div>
                                                                        <div>
                                                                            <div className="fs-2">{customerInvoiceDetails.customer_name}</div>
                                                                            {customerInvoiceDetails.customer_email && <div className="fs-4 text-gray-600">{customerInvoiceDetails.customer_email}</div>}
                                                                            {customerInvoiceDetails.customer_workphone && <div className="fs-4 text-gray-600">{customerInvoiceDetails.customer_workphone}</div>}
                                                                        </div>
                                                                    </div>

                                                                    <div className='bg-light-primary p-4 rounded d-flex flex-wrap justify-content-between align-items-center flex-grow-1'>
                                                                        <span className="fs-2">
                                                                            <label className='fw-bold me-3'>Grand Total: </label>
                                                                            <span>{customerInvoiceDetails.invoice_currency.currency_symbol} {formatNumber(customerInvoiceDetails.grand_total, { ...formatNumberOptions, currencyFormat: customerInvoiceDetails.invoice_currency.format, decimalPlaces: customerInvoiceDetails.invoice_currency.decimal_place })}</span>
                                                                        </span>
                                                                        <StatusBadge status={recurringInvoiceDetails.recurring_invoice_status} entity={""} className={"fs-4"} />
                                                                    </div>
                                                                </div>
                                                                <div className='row gx-0'>
                                                                    {
                                                                        calculateUpcomingInvoiceDatesAgainstFrequency(recurringInvoiceDetails.start_date, recurringInvoiceDetails.end_date, recurringInvoiceDetails.frequency_details, 6).map((obj,i) => {
                                                                            return (
                                                                                <div key={i} className='col-md-4 p-2'>
                                                                                    <div className='p-4 shadow-sm rounded' style={{ backgroundColor: "#99d98c" }}>
                                                                                        <div className='fs-3'>{obj.date}</div>
                                                                                        <div className='fs-4 text-white'>{obj.day}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="card card-flush py-4 flex-row-fluid">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2>Recurring Invoice History</h2>
                                                        </div>
                                                    </div>

                                                    <div className="card-body pt-0">
                                                        {
                                                            customerRecurringInvoiceHistoryDetails.length > 0 &&
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                    <thead>
                                                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                                            <th className="min-w-100px">Date Added</th>
                                                                            <th className="min-w-175px">Comment</th>
                                                                            <th className="min-w-70px">Invoice Status</th>
                                                                            <th className="min-w-100px">Customer Notifed</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        {
                                                                            customerRecurringInvoiceHistoryDetails.map((Obj, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{moment(Obj.timestamp).format("ll")}</td>
                                                                                        <td>{Obj.operation_message}</td>
                                                                                        <td><div className="py-2 me-1 rounded badge badge-success">{Obj.recurring_invoice_status}</div></td>
                                                                                        <td>No</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                            : state == 2 ?
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                                :
                                <PageError />
                        }
                    </div>
                </div>
            </div >

            <Modal show={itemsModalShow} onHide={() => { setItemsModalShow(false); setItemsData([]); }} centered>
                <Modal.Header>
                    <Modal.Title>Item Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-danger" onClick={() => { setItemsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container d-flex flex-wrap gap-2'>
                        {
                            itemsData.map((obj, indx) => {
                                return (
                                    <div key={indx}>
                                        <span className='fw-bold fs-4 p-2 badge bg-primary'>
                                            {obj.serial_name ? obj.serial_name : null}
                                            {obj.batch_reference ? `${obj.batch_reference} - Qty: ${obj.unit}` : null}
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={printModalShow} onHide={() => { setPrintModalShow(false) }} centered className="bg-transparent">
                <Modal.Body className="position-relative">
                    <div className="position-absolute top-0 end-0 p-2">
                        <div onClick={() => { setPrintModalShow(false) }} className="cursor-pointer">
                            <i className="bi bi-x-circle fs-4 text-danger"></i>
                        </div>
                    </div>
                    <div className='fw-bold container d-flex align-items-center gap-5'>
                        <div>Choose file type to print :</div>
                        <button className='btn btn-sm btn-info' onClick={() => { handleOperation('print') }}>
                            <i className="bi bi-file-earmark-pdf fs-4 fw-bold"></i> A4
                        </button>
                        <button className='btn btn-sm btn-warning' onClick={() => { handleOperationBill('print') }}>
                            <i className="bi bi-receipt fs-4 fw-bold"></i>Bill
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={downloadModalShow} onHide={() => { setDownloadModalShow(false) }} centered className="bg-transparent">
                <Modal.Body className="position-relative">
                    <div className="position-absolute top-0 end-0 p-2">
                        <div onClick={() => { setDownloadModalShow(false) }} className="cursor-pointer">
                            <i className="bi bi-x-circle fs-4 text-danger"></i>
                        </div>
                    </div>
                    <div className='fw-bold container d-flex align-items-center gap-5'>
                        <div>Choose file type to Download :</div>
                        {
                            write &&
                            <button type="button" className="btn btn-sm btn-info" data-kt-indicator={loading ? "on" : "off"} disabled={loading} onClick={generateDownload}>
                                <span className="indicator-label"><i className="bi bi-file-earmark-pdf fs-4 fw-bold"></i>A4</span>
                                <span className="indicator-progress">Loading...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        }
                        <button className='btn btn-sm btn-warning'>Download as Other</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>);

};

export default ViewCustomerRecurringInvoice;

