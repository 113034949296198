import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const UpdateProfiles = (props) => {
    const [state, setState] = useState(2);
    const [profiles, setProfiles] = useState([]);
    const [newProfile, setNewProfile] = useState({ permissions: [] });
    const [tempProfile, setTempProfile] = useState({ permissions: [] });
    const [defaultProfile, setDefaultProfile] = useState({ permissions: [] });
    const [profile_name, setProfile_name] = useState("");
    const [Default_profile, setDefault_profile] = useState(false);
    const [profile_description, setProfile_description] = useState("");
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") === "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [buttonStat, setButtonStat] = useState(1)

    var page_name = "update-profile";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function get_profiles() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.REACT_APP_module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            setProfiles(body)
        }
    }

    async function get_default_profile() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-default-profile`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.REACT_APP_module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            //console.log(JSON.parse(body[0].user_profile_actions));
            setDefaultProfile(JSON.parse(body[0].user_profile_actions));
        }
    }

    async function get_profile_details() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-profile-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.REACT_APP_module_name, "id": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            setProfile_name(body[0].user_profile);
            setDefault_profile(body[0].default);
            setProfile_description(body[0].profile_description);
            setTempProfile(JSON.parse(body[0].user_profile_actions))
        }
        //setState(1) // setState commented - reason unknown
    }

    useEffect(() => {
        if (profiles.length > 0 && defaultProfile.permissions.length > 0) {
            handleProfile(props.match.params.id);
        }
    }, [profiles, defaultProfile]);

    const handleProfile = (id) => {
        let temp_profile = JSON.parse(profiles.find((profile) => profile.user_profile_id === id).user_profile_actions);
        console.log(temp_profile);
        console.log(defaultProfile);
        for (let i = 0; i < temp_profile.permissions.length; i++) {
            console.log(defaultProfile.permissions.find(x => x.key === temp_profile.permissions[i].key))
            const missing_items = findMissingObjects(defaultProfile.permissions.find(x => x.key === temp_profile.permissions[i].key).items, temp_profile.permissions[i].items, "key");
            if (missing_items.length > 0) {
                temp_profile.permissions[i].items = [...temp_profile.permissions[i].items, ...missing_items]
            }

        }
        let missing_section = findMissingObjects(defaultProfile.permissions, temp_profile.permissions, "key");
        if (missing_section.length > 0) {
            temp_profile.permissions = [...temp_profile.permissions, ...missing_section]
        }
        setNewProfile(temp_profile);
        //setNewProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id === event).user_profile_actions))
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p0 = get_profiles()
            let p1 = get_profile_details()
            let p2 = get_default_profile()

            Promise.all([p0, p1, p2])
                .then(async () => {
                    //setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    //setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { window.location.href = "/profiles" })
                })
        }
    }, [user, visibility, read, write])

    const findMissingObjects = (arr1, arr2, uniqueKey) => {
        const objMap = arr2.reduce((map, obj) => {
            map[obj[uniqueKey]] = obj;
            return map;
        }, {});

        return arr1.reduce((missing, obj) => {
            if (!objMap.hasOwnProperty(obj[uniqueKey])) {
                missing.push(obj);
            }
            return missing;
        }, []);
    };

    const processTitle = (title) => {
        return title.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    }
    const foreignCheck = (dependencies) => {
        const singleDependency = (dependency) => {
            let module_value = 0
            let item_value = 0
            let option_value = 0
            let final = 1
            let dependencies_tree = dependency.split(".")
            // length 1
            if (dependencies_tree.length >= 1) {
                newProfile.permissions.forEach((module) => {
                    if (module.key === dependencies_tree[0]) {
                        module_value = module.value
                    }
                })
                final = final * module_value
            }

            // length 2
            if (dependencies_tree.length >= 2 && module_value === 1) {
                newProfile.permissions.forEach((module) => {
                    if (module.key === dependencies_tree[0]) {
                        module.items.forEach((item) => {
                            if (item.key === dependencies_tree[1]) {
                                item_value = item.value
                            }
                        })
                    }
                })
                final = final * item_value
            }
            // length 3
            if (dependencies_tree.length >= 3 && module_value === 1 && item_value === 1) {
                newProfile.permissions.forEach((module) => {
                    if (module.key === dependencies_tree[0]) {
                        module.items.forEach((item) => {
                            if (item.key === dependencies_tree[1]) {
                                item.options.forEach((option) => {
                                    if (option.key === dependencies_tree[2]) {
                                        option_value = option.value
                                    }
                                })
                            }
                        })
                    }
                })
                final = final * option_value
            }
            return final
        }
        let foreignFlag = dependencies.map((dependency) => { return singleDependency(dependency) }).reduce((a, b) => a * b, 1)
        // console.log(dependencies,foreignFlag)

        return foreignFlag
    }


    const updateNewPermission = (module_name, item_name, option_name, check_value) => {
        // console.log(module_name, item_name, option_name, check_value)

        let temp = JSON.parse(JSON.stringify(newProfile))
        let temp2 = { permissions: [] }
        temp2.permissions = temp.permissions.map((module) => {
            if (module_name === module.key) {
                if (item_name === undefined) {
                    return { ...module, value: foreignCheck(module.foreign) && check_value ? 1 : 0 }
                }
                else {
                    let temp3 = module.items.map((item) => {
                        if (item_name === item.key) {
                            if (option_name === undefined) {
                                return { ...item, value: foreignCheck(item.foreign) && check_value ? 1 : 0 }
                            }
                            else {
                                let temp4 = item.options.map((option) => {
                                    if (option_name === option.key) {
                                        return { ...option, value: foreignCheck(option.foreign) && check_value ? 1 : 0 }
                                    }
                                    else {
                                        return { ...option }
                                    }
                                })
                                return { ...item, options: temp4 }
                            }
                        }
                        else {
                            return { ...item }
                        }
                    })
                    return { ...module, items: temp3 }
                }
            }
            else {
                return { ...module }
            }
        })
        // console.log("part1=>",temp2)

        // temp2.permissions= temp2.permissions.map((module) => {
        //         let module_value = foreignCheck(module.foreign) && module.value
        //         let temp_items = module.items.map((item) => {
        //             let item_value = foreignCheck(item.foreign) && item.value
        //             let temp_options = item.options.map((option) => {
        //                 let option_value = foreignCheck(option.foreign) && option.value
        //                 return { ...option, value: option_value }
        //             })
        //             return { ...item, value: item_value, options: temp_options }
        //         })
        //         return { ...module, value: module_value, items: temp_items }
        //     })
        // console.log("part2=>",temp2)

        // let mainList = []
        // let StackDep = []
        // // let x = module_name + (item_name==undefined)?"":("."+item_name) + (option_name==undefined)?"":("."+option_name)
        // let x =[module_name,item_name, option_name ].filter(x=>x!=undefined).join(".")
        // StackDep.push(x)
        // while(StackDep.length){
        //     let y = StackDep.pop()
        //     mainList.push(y)
        //     for(let i=0;i<temp2.permissions.length;i++){
        //         temp2.permissions[i].foreign.includes(y) && StackDep.push(temp2.permissions[i].key)
        //         for(let j=0;j<temp2.permissions[i].items.length;j++){
        //             temp2.permissions[i].items[j].foreign.includes(y) && StackDep.push(temp2.permissions[i].key+"."+temp2.permissions[i].items[j].key)
        //             for(let k=0;k<temp2.permissions[i].items[j].options.length;k++){
        //                 temp2.permissions[i].items[j].options[k].foreign.includes(y) && StackDep.push(temp2.permissions[i].key+"."+temp2.permissions[i].items[j].key+"."+temp2.permissions[i].items[j].options[k].key)
        //             }
        //         }
        //     }
        // }
        // console.log(mainList,StackDep)
        setNewProfile(temp2)
    }
    const updateNewPermission1 = (module_name, item_name, option_name, check_value) => {
        // console.log(module_name, item_name, option_name, check_value)
        console.log("bc");
        let temp = JSON.parse(JSON.stringify(newProfile))
        let temp2 = { permissions: [] }
        temp2.permissions = temp.permissions.map((module) => {
            if (module_name === module.key) {
                if (item_name === undefined) {

                    return { ...module, value: foreignCheck(module.foreign) && check_value ? 1 : 0 }
                }
                else {

                    let temp3 = module.items.map((item) => {
                        if ((item_name === item.key) && (option_name === undefined)) {
                            console.log("bc", check_value);
                            return { ...item, value: check_value }
                        }
                        else {
                            return { ...item }
                        }
                    })
                    return { ...module, items: temp3 }
                }
            }
            else {
                return { ...module }
            }
        })
        //console.log(temp2)
        setNewProfile(temp2)
    }

    const updateProfile = (e) => {
        e.preventDefault();
        var object = {};
        object.id = props.match.params.id;
        object.user_profile = profile_name.trim();
        object.profile_description = profile_description;
        object.user_profile_actions = JSON.stringify(newProfile)
        // console.log(newProfile)
        object.page_name = page_name;
        object.organization_id = organization_id
        object.service = process.env.REACT_APP_module_name

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9\/\\&$|~!@#()*\-+%{}[\]_.;,:"'<>=]*)?$/;  // purpose of this regex is unclear
        if (object.user_profile === "") {
            swal("Warning!", "Profile name cannot be a empty", "warning");
            return false;
        }
        else if (!object.user_profile.match(nexp)) {
            swal("Warning!", "Profile name cannot contain certain symbols ", "warning");
            return false;
        }
        let flag = 1;
        profiles.forEach((profile) => {
            if (profile.user_profile_id !== object.id) {
                if (profile.user_profile.trim().toLowerCase() === object.user_profile.toLowerCase()) {
                    flag = 0;
                    swal("Warning!", "Profile name already exists", "warning");
                    return false;
                }
                else if (JSON.stringify(JSON.parse(profile.user_profile_actions)) === object.user_profile_actions) {
                    //console.log("abc")
                    flag = 0;
                    swal("Warning!", `Permission setting already exists for ${profile.user_profile}`, "warning");
                    return false;
                }

            }

        })


        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if (flag === 1) {
            setButtonStat(0)
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-profile-details`, object, headers).then((res) => {

                if (res.data && res.data.operation === "success") {
                    swal("Great!", "Profile updated successfully!", "success").then((value) => {
                        window.location.href = "/profiles"
                    });
                    setButtonStat(1)
                }
                else if (res.data.operation === "same_entity") {
                    swal("Oops!", "Use a different profile name!!", "error");
                    setButtonStat(1)
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                    setButtonStat(1)
                }
            }).catch(function (error) {
                console.log(error)
            });
        }
    }
    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">

                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">

                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Update Profile
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div class="card h-100">
                            <div class="card-body">
                                <div className="overflow-auto pe-7 my-5">

                                    {profiles.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Name</label>
                                                    <input type="text" name="customer_name" className="form-control" id="customer_name" placeholder="Profile Name" disabled={Default_profile} value={profile_name}
                                                        onChange={(e) => setProfile_name(e.target.value)}
                                                    />
                                                    <span className="form-text text-muted">Please give a profile name.</span>

                                                </div>

                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Reference</label>
                                                    <Select
                                                        styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                                        class="form-select form-select-solid select2-accessible"
                                                        data-control="select2"
                                                        options={profiles.map((obj) => { return { label: obj.user_profile, value: obj.user_profile_id } })}
                                                        onChange={(event) => { handleProfile(event.value) }}
                                                    />
                                                    <span className="form-text text-muted">Select a already avaiable profile for reference</span>
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Description</label>
                                                    <input type="text" name="Description" className="form-control" id="Description" placeholder="Profile Description" value={profile_description}
                                                        onChange={(e) => setProfile_description(e.target.value)}
                                                    />
                                                    <span className="form-text text-muted">Please give a profile Description.</span>
                                                </div>
                                            </div>
                                            <div className="row mt-10">
                                                <div className="col-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Permissions</label>
                                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                        {newProfile.permissions.length > 0 ?
                                                            <>
                                                                {newProfile.permissions.map((module, index1) => {
                                                                    return (
                                                                        <div className={`card border border-border-2 rounded shadow m-3`} style={{ minHeight: "200px", minWidth: "300px" }} >
                                                                            <div className='card-header align-items-center' style={{ minHeight: "40px", padding: "10px" }}>
                                                                                {processTitle(module.key)}
                                                                                <div className="form-check form-switch form-check-custom form-check-solid form-check-warning mt-2">
                                                                                    <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(module.foreign) && module.value} onChange={(e) => updateNewPermission(module.key, undefined, undefined, e.target.checked)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className=' p-5' >
                                                                                <div className='d-flex justify-content-between flex-column mb-5'>

                                                                                    {module.items.map((item, index2) => {
                                                                                        return (
                                                                                            <>
                                                                                                {item.options.length > 0 ?
                                                                                                    <>
                                                                                                        <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                            <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                                <span>{processTitle(item.key)}</span>

                                                                                                                <div className="col-md-6 fv-row">
                                                                                                                    <Select
                                                                                                                        styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                                                                                                        class="form-select form-select-solid select2-accessible"
                                                                                                                        data-control="select2"
                                                                                                                        options={item.options.map((obj1) => { return { label: obj1.key, value: obj1.value } })}
                                                                                                                        value={{ label: item.value, value: item.value }}
                                                                                                                        onChange={(e) => updateNewPermission1(module.key, item.key, undefined, e.value)}
                                                                                                                    />
                                                                                                                </div>

                                                                                                                {/*<div class="dropdown">
                                                                                                                <div class="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                                                                    <span>{processTitle(item.key)}</span>
                                                                                                                    <i class="las la-angle-down px-2"></i>
                                                                                                                </div>
                                                                                                                <ul class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton1">
                                                                                                                    {item.options.map((option) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(option.key)}>
                                                                                                                                    <span>{processTitle(option.key)}</span>

                                                                                                                                    <input className="form-check-input" type="checkbox" checked={foreignCheck(option.foreign) && option.value} onChange={(e) => updateNewPermission(module.key, item.key, option.key, e.target.checked)} />
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </ul>*/}
                                                                                                            </div>



                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                            <span>{processTitle(item.key)}</span>
                                                                                                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                                <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(item.foreign) && item.value} onChange={(e) => updateNewPermission(module.key, item.key, undefined, e.target.checked)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button className='btn btn-primary mx-2' onClick={(e) => updateProfile(e)} data-kt-indicator={buttonStat ? "off" : "on"} disabled={write && buttonStat ? false : true} >
                                                    <span class="indicator-label">
                                                        Submit
                                                    </span>
                                                    <span class="indicator-progress">Please wait...
                                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {state === 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                :
                                                <>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 m-10">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateProfiles;