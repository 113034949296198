import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import 'react-dropzone-uploader/dist/styles.css'

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"


function UpdateRoles(props) {


    let [state, setstate] = useState(2);
    const [navtype, setnavtype] = useState("General");
    const [newAccess, setNewAccess] = useState({ access: [] });
    const [tempAccess, setTempAccess] = useState({ access: [] });
    const [defaultAccess, setDefaultAccess] = useState({ access: [] });

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") === "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    let [Role_name, setRole_name] = useState("");
    let [Description, setDescription] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [Default_role, setDefault_role] = useState(false);

    var page_name = "update-roles";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getDefaultRole() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-default-role`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.REACT_APP_module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            setDefaultAccess(JSON.parse(body[0].user_role_access));
        }
    }

    async function getRoleDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-role-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "id": props.match.params.id, "service": process.env.REACT_APP_module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            setTempAccess(JSON.parse(body[0].user_role_access));
            setRole_name(body[0].user_role);
            setDefault_role(body[0].default);
            setDescription(body[0].role_description);
        }
        else {
            setstate(0)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p0 = getDefaultRole()
            let p1 = getRoleDetails()

            Promise.all([p0, p1])
                .then(async () => {
                    //setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { window.location.href = "/roles" })
                })
        }
    }, [user, visibility, read, write])

    const findMissingObjects = (arr1, arr2, uniqueKey) => {
        const objMap = arr2.reduce((map, obj) => {
            map[obj[uniqueKey]] = obj;
            return map;
        }, {});

        return arr1.reduce((missing, obj) => {
            if (!objMap.hasOwnProperty(obj[uniqueKey])) {
                missing.push(obj);
            }
            return missing;
        }, []);
    };

    useEffect(() => {
        if (Default_role !== "true") {
            if (tempAccess.access.length > 0 && defaultAccess.access.length > 0) {
                handleRole(props.match.params.id);
            }
        }
        else {
            setstate(3);
        }

    }, [tempAccess, Default_role, defaultAccess]);

    const handleRole = () => {
        let temp_role = tempAccess;
        for (let i = 0; i < temp_role.access.length; i++) {
            const missing_items = findMissingObjects(defaultAccess.access.find(x => x.key === temp_role.access[i].key).inside_components, temp_role.access[i].inside_components, "key");
            if (missing_items.length > 0) {
                temp_role.access[i].inside_components = [...temp_role.access[i].inside_components, ...missing_items]
            }

        }
        let missing_section = findMissingObjects(defaultAccess.access, temp_role.access, "key");
        if (missing_section.length > 0) {
            temp_role.access = [...temp_role.access, ...missing_section]
        }
        setstate(1);
        setNewAccess(temp_role);
    }

    const updateNewAccess = (val, section, sub1, sub2, sub3, part) => {
        let temp = JSON.parse(JSON.stringify(newAccess))
        let temp2 = { access: [] }
        temp2.access = temp.access.map((obj) => {
            if (sub1 === undefined) {
                //root heading
                if (obj.key === section) {
                    return { ...obj, visibility: val ? 1 : 0 }
                    //console.log(obj.keyvisibility)
                }
                else {
                    return { ...obj }
                }
            }
            else {
                //console.log(obj.inside_components)
                let temp3 = obj.inside_components.map((obj2) => {
                    if (part === undefined) {
                        //branch head                        
                        if (Object.keys(obj2)[0] === "key") {
                            if (sub2 !== undefined) {
                                let temp4 = obj2.inside_components.map((obj3) => {
                                    if (Object.keys(obj3)[0] === "key") {
                                        if (obj3.key === sub2) {
                                            return { ...obj3, visibility: val ? 1 : 0 }
                                        }
                                        else {
                                            return { ...obj3 }
                                        }
                                    }
                                    else {
                                        return { ...obj3 }
                                    }
                                });
                                return { ...obj2, inside_components: temp4 }
                            }
                            else {
                                if (obj2.key === sub1) {
                                    return { ...obj2, visibility: val ? 1 : 0 }
                                }
                                else {
                                    return { ...obj2 }
                                }
                            }
                        }
                        else {

                            if ((Object.keys(obj2)[0]) === sub1) {
                                return { ...obj2, visibility: val ? 1 : 0 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                    }
                    else {
                        if (sub2 === undefined) {
                            //no nested branch
                            if (Object.keys(obj2)[0] === sub1) {
                                return { ...obj2, [part]: val ? 1 : 0 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                        else {
                            //nested1 branch
                            if (obj2.key === sub1) {
                                let temp4 = obj2.inside_components.map((obj3) => {
                                    if (Object.keys(obj3)[0] === sub2) {
                                        return { ...obj3, [part]: val ? 1 : 0 }
                                    }
                                    else {
                                        //return { ...obj3 }
                                        if (obj3.key === sub2) {
                                            //nested2 branch
                                            let temp5 = obj3.inside_components.map((obj4) => {
                                                if (Object.keys(obj4)[0] === sub3) {
                                                    return { ...obj4, [part]: val ? 1 : 0 }
                                                }
                                                else {
                                                    return { ...obj4 }
                                                }
                                            })
                                            return { ...obj3, inside_components: temp5 }
                                        }
                                        else {
                                            return { ...obj3 }
                                        }
                                    }
                                })
                                return { ...obj2, inside_components: temp4 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                    }
                })

                return { ...obj, inside_components: temp3 }
            }
        })
        //console.log(temp2.access)
        setNewAccess(temp2)
    }

    // const updateNewActions = (e, index1, index2) => {
    //     // console.log(val, section, sub1, sub2, part)
    //     const values1 = [...newActions];
    //     if (values1[0]["access"][index1]["actions"][index2].boolean_acceptability === 1) {
    //         if (e.target.checked) {
    //             values1[0]["access"][index1]["actions"][index2].value = 1;
    //         }
    //         else {
    //             values1[0]["access"][index1]["actions"][index2].value = 0;
    //         }
    //     }
    //     else {
    //         values1[0]["access"][index1]["actions"][index2].value = e.value;
    //     }
    //     setnewActions(values1)
    // }

    const register = () => {

        var object = {};
        if (Role_name === "") {
            swal("Warning!", "Select a Role name", "warning");
            return false;
        }
        object.unique_id = props.match.params.id;
        object.user_role = Role_name;
        object.role_description = Description;
        object.user_role_access = newAccess;
        object.user_role_actions = undefined // newActions[0]
        object.page_name = page_name;
        object.organization_id = organization_id;

        // if (object.user_access == {} || object.user_access == [] || object.user_access == { access: [] }) {
        //     swal("Oops!", "Access permission invalid, try again", "error");
        //     return false;
        // }
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-role-details`, object, headers).then((res) => {

            if (res.data.operation === "success") {

                swal("Great!", "Role details updated successfully!", "success").then((value) => {
                    window.location.href = "/roles"
                    setMainSubmitButtonState(false)
                });
            }
            else {
                swal("Oops!", `${res.data.message}!`, "error");
                setMainSubmitButtonState(false)
            }
        })
            .catch(function (error) {
                console.log(error);
                swal("Oops!", error, "error");
            });
    }

    function getElementByXpath(path) {
        return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    }

    function scrollToTop() {
        getElementByXpath("/html/body/div/div/div[2]/div[2]").scrollTo({ top: 0, behavior: 'smooth' });
    }

    function scrollToBottom() {
        const elem = getElementByXpath("/html/body/div/div/div[2]/div[2]");
        elem.scrollTo({ top: elem.scrollHeight, behavior: 'smooth' })
    }
    

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Update Role Details

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state === 1 ?
                            <>
                                <div className="form d-flex flex-column flex-lg-row">
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                            <li className="nav-item">
                                                <span className={`nav-link text-active-primary pb-4 cursor-pointer ${navtype === "General" && "active"}`} onClick={(e) => { setnavtype("General"); }}>General</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className={`nav-link text-active-primary pb-4 cursor-pointer ${navtype === "Advance" && "active"}`} onClick={(e) => { setnavtype("Advance"); }}>Permissions</span>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            {navtype === "General" &&
                                                <div className={`tab-pane fade ${navtype === "General" && "show active"}`} role="tab-panel">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>General</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="w-100">
                                                                    {/* {console.log(inputFields)} */}
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-12 fv-row">
                                                                            <label className="required fs-5 fw-bold mb-2">Role Name</label>
                                                                            <input id="first_name" type="text" className="form-control form-control-solid" placeholder="" name="first-name" disabled={Default_role} value={Role_name}
                                                                                onChange={(event) => { setRole_name(event.target.value); }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-12 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Description</label>
                                                                            <textarea id="email" type="email" className="form-control form-control-solid" placeholder="" name="email" value={Description}
                                                                                onChange={(event) => { setDescription(event.target.value); }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {navtype === "Advance" &&
                                                <div className={`tab-pane fade ${navtype === "Advance" && "show active"}`} role="tab-panel">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">

                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Permissions</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={scrollToBottom} title="Scroll to Bottom">
                                                                    <i className="las la-angle-double-down" style={{ "fontSize": "32px" }}></i>
                                                                </button>
                                                                <div className="w-100">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-row-dashed border-gray-100 align-middle gy-6">
                                                                            <tbody className="fs-6 fw-bold">
                                                                                <tr>
                                                                                    <td className="min-w-240px fs-3 fw-bolder">Pages</td>
                                                                                    <td className="min-w-240px fw-bolder">Visibility</td>
                                                                                    <td className="w-125px">
                                                                                        <label className="form-check-label " htmlFor="kt_settings_notification_email">Read</label>
                                                                                    </td>
                                                                                    <td className="w-125px">
                                                                                        <label className="form-check-label " htmlFor="kt_settings_notification_phone">Write</label>
                                                                                    </td>

                                                                                </tr>
                                                                                {newAccess["access"].length > 0 ? newAccess["access"].map((obj, index1) => {

                                                                                    return (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td className="fs-4 fw-bolder">{obj.key.toUpperCase()}</td>

                                                                                                <td>
                                                                                                    <div className="form-check form-check-solid">
                                                                                                        {/* {console.log(obj.visibility)} */}
                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, undefined, undefined, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj.visibility === 1} />
                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                            {obj.inside_components.length > 0 ? obj.inside_components.map((obj2, index2) => {
                                                                                                return (
                                                                                                    obj2.hasOwnProperty("key") && obj2.inside_components.length > 0 ?
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td className="fs-6 fw-bolder">{obj2.key.toUpperCase()}</td>

                                                                                                                <td>
                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                        {/* {console.log(obj.visibility)} */}
                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, undefined, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj2.visibility === 1} />
                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                    </div>
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                            {
                                                                                                                obj2.inside_components.map((obj3) => {
                                                                                                                    // console.log(Object.keys(obj3)[0],":",obj3[Object.keys(obj3)[0]])
                                                                                                                    return (
                                                                                                                        obj3.hasOwnProperty("key") && obj3.inside_components.length > 0 ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td className="fs-6 fw-bolder">{obj3.key.toUpperCase()}</td>

                                                                                                                                    <td>
                                                                                                                                        <div className="form-check form-check-solid">
                                                                                                                                            {/* {console.log(obj.visibility)} */}
                                                                                                                                            <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, obj3.key, undefined, undefined)} id={`visibility_${obj3.key.replace(/-/g, "_")}`} checked={obj3.visibility === 1} />
                                                                                                                                            <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                        </div>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                                {
                                                                                                                                    obj3.inside_components.map((obj4) => {
                                                                                                                                        // console.log(Object.keys(obj3)[0],":",obj3[Object.keys(obj3)[0]])
                                                                                                                                        return (
                                                                                                                                            <tr>
                                                                                                                                                <td className="fw-bold text-gray-600">{Object.keys(obj4)[0]}</td>
                                                                                                                                                <td>
                                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, obj3.key, Object.keys(obj4)[0], Object.keys(obj4)[0])} id={`visibility_${(Object.keys(obj4)[0]).replace(/-/g, "_")}`} checked={obj4[Object.keys(obj4)[0]] === 1} />
                                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, obj3.key, Object.keys(obj4)[0], "read")} id={`read_${(Object.keys(obj4)[0]).replace(/-/g, "_")}`} checked={obj4[Object.keys(obj4)[1]] === 1} />
                                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, obj3.key, Object.keys(obj4)[0], "write")} id={`write_${(Object.keys(obj4)[0]).replace(/-/g, "_")}`} checked={obj4[Object.keys(obj4)[2]] === 1} />
                                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <tr>
                                                                                                                                <td className="fw-bold text-gray-600">{Object.keys(obj3)[0]}</td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0], Object.keys(obj3)[0])} id={`visibility_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[0]] === 1} />
                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0], "read")} id={`read_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[1]] === 1} />
                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0], "write")} id={`write_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[2]] === 1} />
                                                                                                                                        <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <tr>
                                                                                                            <td className="fw-bold text-gray-600">{Object.keys(obj2)[0]}</td>
                                                                                                            <td>
                                                                                                                <div className="form-check form-check-solid">
                                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, undefined, Object.keys(obj2)[0])} id={`visibility_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[0]] === 1} />
                                                                                                                    <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className="form-check form-check-solid">
                                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, undefined, "read")} id={`read_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[1]] === 1} />
                                                                                                                    <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className="form-check form-check-solid">
                                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, undefined, "write")} id={`write_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[2]] === 1} />
                                                                                                                    <label className="form-check-label ps-2" htmlFor="billing1"></label>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                )
                                                                                            }) : null}
                                                                                        </>
                                                                                    );
                                                                                })
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={scrollToTop} title="Scroll to Top">
                                                                    <i className="las la-angle-double-up" style={{ "fontSize": "32px" }}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                        {navtype === "General" ?
                                            <div className="d-flex justify-content-end">
                                                <button type="button" onClick={() => { setnavtype("Advance"); scrollToTop(); }} className="btn btn-lg btn-primary me-3">
                                                    <span className="indicator-label">Continue<i className="ki-duotone ki-arrow-right fs-3 ms-2 me-0"><span className="path1"></span><span className="path2"></span></i></span>
                                                </button>
                                                {/* <button onClick={() => { setnavtype("Advance") }} className="btn btn-primary">Next</button> */}
                                            </div>
                                            :
                                            <div className="d-flex flex-stack pt-15">
                                                <div className="mr-2">
                                                    <button type="button" onClick={() => { setnavtype("General"); scrollToTop(); }} className="btn btn-lg btn-primary me-3">
                                                        <i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1"></span><span className="path2"></span></i>Back
                                                    </button>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { register(e) }} className="btn btn-primary">
                                                        <span className="indicator-label">Save Changes</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}
                            </>
                            :
                            <>
                                {state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :

                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className="fw-bolder">Update Roles</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">
                                                    </div>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                {state === 3 ? <h4 className="text-gray-800 fw-bolder">You cannot update a default Role.</h4> : <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>}
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default UpdateRoles;