import { useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import uuid from 'react-uuid';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { getCookie } from './cookie.js';
import { Modal } from 'react-bootstrap';
import { useModal } from "../providers/modal-provider.js";
import { useAuthData } from "../includes/auth-provider.js";

export default function SerialScannerModal() {
    const { displayModal, modalData, closeModal, setModalData, setModalView } = useModal()
    const [selectedCamera, setSelectedCamera] = useState("user");
    // const [selectedProductId, setSelectedProductId] = useState(null)
    const [serialInput, setSerialInput] = useState("")
    const [serialArray, setSerialArray] = useState([])
    const [serialSubmitButtonState, setSerialSubmitButtonState] = useState(false)
    const { write, organization_id } = useAuthData(modalData.page_name)


    const capture = (err, result) => {
        if (!result)
            return

        let tag = result.text;
        console.log(tag);

        console.log(serialArray)
        if (!serialArray.includes(tag)) {
            setSerialArray([...serialArray, tag]);
        }
    }

    function serialCheck(arr, element) {
        if (!element) {
            swal("Alert!", "Enter a valid serial", "warning")
            return true
        }
        if (arr.includes(element)) {
            swal("Alert!", "Duplicate serials cannot be added", "warning")
            return true
        }
        return false
    }

    const handleScannerModalClose = (serials = []) => {
        if(serials.length){
            modalData.cb(serials)
        }

        setSelectedCamera("user");
        setSerialInput("")
        setSerialArray([])

        closeModal()
        setModalView(null)
        setModalData(null)
    }

    const newSerialNumbersSubmit = () => {

        if (serialArray.length == 0) {
            swal("Alert!", "Add some serials for this product first", "warning")
            return
        }

        let object = {};

        object.id = modalData.id
        object.Tracking_inventory = "Track Serial Number"

        let serials = serialArray.map(x => { return { uid: uuid(), serialName: x } })
        object.Serial_number = serials
        object.serialOut = []

        object.sign = 1
        object.Stock_date = moment().format("yyyy-MM-DD")
        object.Stock_Account = {} //discuss
        object.Reference = ""
        object.operation = "warehouse processing"

        object.Branch_name = modalData.branch_id
        object.Warehouse_name = modalData.warehouse_id

        object.Quantity_adjust = serialArray.length
        object.Quantity_hand = serialArray.length

        object.organization_id = organization_id
        object.page_name = modalData.page_name

        object.save_flag = "Adjustment" // approval info

        setSerialSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-product-stock-information`, object, headers).then((res) => {
            setSerialSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                handleScannerModalClose(serials)
                swal("Great!", "Stock details updated successfully!", "success");
            }
            else if (res.data.message === "duplicate element present" && res.data?.element?.length) {
                swal("Oops!", `Duplicate Serials present - ${res.data.element.map(s => s.serialName).join(", ")}`, "error");
            } else {
                swal("Oops!", res.data.message, "error");
            }
        });
    }

    return (
        <Modal show={displayModal} onHide={() => { handleScannerModalClose() }} size="md" centered>
            <Modal.Header>
                <Modal.Title>Scan Serial Number</Modal.Title>
                <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleScannerModalClose() }}>
                    <span className="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                            </g>
                        </svg>
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='container px-1'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <BarcodeScannerComponent
                            width={350}
                            height={250}
                            onUpdate={capture}
                            videoConstraints=
                            {
                                {
                                    width: 1280,
                                    height: 720,
                                    facingMode: selectedCamera == "user" ? selectedCamera : { exact: selectedCamera }
                                }
                            }
                        />

                        <div className='d-flex align-items-center'>
                            <button className="btn bg-secondary rounded-pill" onClick={() => { selectedCamera == "user" ? setSelectedCamera("environment") : setSelectedCamera("user") }}>
                                <svg version="1.1" width="32" height="32" viewBox="0 0 1000 1000">
                                    <g transform="translate(0.000000,380.000000) scale(0.100000,-0.100000)">
                                        <path d="M3257.7,2389.6c-99.6-51.7-227.8-197.2-390.6-444.2c-225.9-340.8-55.5-300.6-1294.3-306.3l-1062.6-5.7l-103.4-57.4c-59.4-32.5-135.9-97.6-178.1-149.3c-139.8-178.1-132.1,19.1-126.4-3049.9l5.7-2755.1l40.2-88.1c88.1-189.6,247-308.3,461.4-342.7c149.3-24.9,8630.9-24.9,8780.3,0c214.4,34.5,373.3,153.2,461.4,342.7l40.2,88.1l5.7,2755.1c5.7,3069.1,13.4,2871.9-126.3,3049.9c-42.1,51.7-118.7,116.8-178,149.3l-103.4,57.4l-1062.6,5.7c-1238.7,5.8-1068.3-34.5-1294.3,306.3c-166.6,250.8-291,394.4-394.4,446.1c-68.9,34.5-135.9,36.4-1742.3,36.4C3403.2,2426,3320.9,2424.1,3257.7,2389.6z M6621.7,1983.7c30.6-34.5,128.3-168.5,214.4-296.8c162.7-243.1,268-354.2,377.2-400.1c51.7-21.1,254.6-26.8,1102.8-26.8c999.4,0,1041.5-1.9,1100.9-38.3c34.5-21.1,68.9-61.3,76.6-90c9.6-28.7,15.3-1259.8,15.3-2734c0-2552.1-1.9-2684.3-34.5-2734c-19.1-30.6-55.5-61.3-82.3-70.8c-65.1-24.9-8719-24.9-8784.1,0c-26.8,9.6-63.2,40.2-82.3,70.8c-32.5,49.8-34.5,181.9-34.5,2734c0,1474.2,5.7,2705.3,15.3,2734c7.7,28.7,42.1,68.9,76.6,90c59.4,36.4,101.5,38.3,1100.9,38.3c848.2,0,1051.1,5.7,1102.8,26.8c109.1,45.9,214.4,157,377.2,400.1c86.2,128.3,183.8,262.3,214.4,296.8l59.4,61.3H5000h1562.3L6621.7,1983.7z"></path>
                                        <path d="M4779.8,461.6c-254.6-30.6-538-126.4-758.2-256.6C3870.4,117,3832.1,71,3832.1-24.7c0-91.9,59.4-164.7,145.5-181.9c55.5-9.6,99.5,3.8,281.4,93.8c292.9,145.5,381,168.5,673.9,180c210.6,7.7,275.7,3.8,421.2-30.6c492.1-118.7,896-459.5,1089.4-917.1c47.9-113,88.1-262.3,116.8-434.6l11.5-76.6l-149.3,145.5c-178.1,174.2-227.8,208.7-296.8,208.7c-124.4,0-225.9-113-199.1-222.1c9.6-36.4,151.3-193.4,428.9-472.9L6771-2148l388.7,386.7c214.4,214.4,402.1,411.6,417.4,440.4c42.1,82.3,32.5,157-28.7,224c-45.9,47.9-70.8,59.4-135.9,59.4c-78.5,0-91.9-9.6-268-181.9l-185.7-183.8l-11.5,124.5c-46,482.5-325.5,997.5-716.1,1313.4C5830.9,358.2,5270,522.9,4779.8,461.6z"></path><path d="M2811.6-1052.8c-365.7-367.6-415.5-425-415.5-478.6c0-88.1,36.4-151.3,105.3-185.7c34.5-15.3,72.8-28.7,86.2-28.7c53.6,0,155.1,72.8,298.7,214.4l155.1,153.2l11.5-227.8c17.2-323.6,90-570.5,250.8-853.9c275.7-482.5,752.4-830.9,1296.2-943.9c218.3-47.9,585.9-47.9,800.3-1.9c225.9,47.9,465.2,149.3,664.3,279.5c229.8,151.2,298.7,225.9,302.5,327.4c1.9,68.9-7.7,88.1-63.2,137.9c-91.9,82.3-174.2,68.9-342.7-61.3c-622.2-480.6-1510.6-425-2067.8,130.2c-281.4,279.6-461.4,708.4-463.3,1093.3v124.4l178.1-174.2c101.5-99.6,201-181.9,231.7-189.5c122.5-30.6,264.2,111,233.6,233.6c-9.6,36.4-153.2,195.3-419.3,463.3c-222.1,222.1-409.7,405.9-415.5,405.9C3232.8-635.4,3041.4-823.1,2811.6-1052.8z"></path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className='d-flex gap-1 align-items-center my-2'>
                        <input style={{ flex: "9" }} type="text" className="form-control form-control-solid px-2" value={serialInput} onChange={(e) => { setSerialInput(e.target.value.trim()) }} onKeyDown={(e) => { if (e.key === 'Enter' && !serialCheck(serialArray, serialInput)) { setSerialArray([...serialArray, serialInput]); setSerialInput("") } }} />
                        <button className='btn btn-sm btn-success' style={{ flex: "1" }} onClick={() => { if (!serialCheck(serialArray, serialInput)) { setSerialArray([...serialArray, serialInput]); setSerialInput("") } }}>+ Add</button>
                    </div>

                    {
                        serialArray.length > 0 &&
                        <>
                            <div className="separator separator-dashed mb-2"></div>
                            <label className="form-label">Selected Serial Number:</label>
                        </>
                    }
                    <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                        {
                            serialArray.length > 0 && serialArray.map((val, i) => {
                                return (
                                    <div key={i} className="btn btn-light d-flex align-items-center px-2">
                                        <div className="text-gray-600 fw-bold">{val}</div>
                                        <span className="ms-3 text-hover-danger"
                                            onClick={() => {
                                                let t = [...serialArray]
                                                t.splice(i, 1)
                                                setSerialArray(t)
                                            }}
                                        >
                                            <i className="fa fa-trash fs-2 p-0" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger rounded" onClick={() => { handleScannerModalClose() }}>Close</button>
                {
                    write &&
                    <button className="btn btn-success rounded me-auto" data-kt-indicator={serialSubmitButtonState ? "on" : "off"} disabled={serialSubmitButtonState} onClick={() => { newSerialNumbersSubmit() }}>
                        <span className="indicator-label">Save and Continue</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                }
            </Modal.Footer>
        </Modal>
    )
}