import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import uuid from 'react-uuid';
import Dropzone from 'react-dropzone-uploader';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';
import flow_img from '../flow_images/Customer_order_flow.jpg';

function BulkUpdateItemPriceInformation() {
    const daterange_picker_ref = useRef();
    const [state, setstate] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());

    const [bulkUpdationModal, setBulkUpdationModal] = useState(false)
    const [bulkUpdationData, setBulkUpdationData] = useState([]);
    const [proceedUpdationData, setProceedUpdationData] = useState([]);
    const [proceedInsertionData, setProceedInsertionData] = useState([]);

    const [listCount, setListCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bulkListApiState, setBulkListApiState] = useState(false);

    const [searchFile, setSearchFile] = useState("");

    const [bulkDataModalShow, setBulkDataModalShow] = useState(false)
    const [bulkDataFile, setBulkDataFile] = useState(null)
    const [bulkDataExtracted, setBulkDataExtracted] = useState([])
    const [bulkDataVerifyButtonState, setBulkDataVerifyButtonState] = useState(false)
    const [bulkDataVerified, setBulkDataVerified] = useState(false)
    const [bulkDataSubmitButtonState, setBulkDataSubmitButtonState] = useState(false)

    const [flowModalShow, setFlowModalShow] = useState(false)

    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [timezone_change, set_timezone_change] = useState("+00:00");
    const [ItemExcelRequest, setItemExcelRequest] = useState([]);

    const [Err, setErr] = useState(null);
    const [ErrModalShow, setErrModalShow] = useState(false);

    var page_name = "bulk-update-item-price-information";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getUpdateItemPriceList(sd, ed, cp, rpp) {
        setBulkListApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-update-excel-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setItemExcelRequest(body.data.list);
            setListCount(body.data.count);
        }
        else {
            throw "get update excel information list api error"
        }

        setBulkListApiState(false);
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getUpdateItemPriceList(startDate, endDate, currentPage, rowsPerPage);
            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error")
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getUpdateItemPriceList(moment(startDate).utcOffset(timezone_change).format("YYYY-MM-DD"), moment(endDate).utcOffset(timezone_change).format("YYYY-MM-DD"), currentPage, rowsPerPage)
        }
    }, [startDate, endDate, currentPage, rowsPerPage])

    const handleChangeStatusExcel = (fileWithMeta, status, allFiles) => {

        if (status === 'done') {
            setBulkDataSubmitButtonState(false)

            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                setBulkDataFile(data)
            }
            reader.onerror = function (ex) {
                console.log(ex);
            }
            reader.readAsBinaryString(fileWithMeta.file);
        }
        else if (status === "removed") {
            setBulkDataSubmitButtonState(false)
            setBulkDataFile(null)
        }
        else if (status === "error_file_size") {
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;

        return (

            <div className="preview-box">
                <img src={previewUrl} style={{ maxHeight: "80px", maxWidth: "80px", borderRadius: "4px", marginRight: "10px" }} />
                <span title={name} className="name">{name.length >= 10 ? name.slice(0, 10) + "...." : name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" onClick={remove} >
                    <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                        <g>
                            <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                        </g>
                    </svg>
                </span>}
                {
                    status === "done" &&
                    <span className="dzu-previewButton remove" onClick={remove} >
                        <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                            <g>
                                <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                            </g>
                        </svg>
                    </span>
                }
            </div>
        )
    }

    const genTemplate = async () => {
        let object = {};
        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object);
        setBulkDataSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/download-excel-record`, object, headers).then((res) => {
            setBulkDataSubmitButtonState(false)
            if (res.data.operation === "success") {
                window.location.href = res.data.data;
                //window.open(res.data.data, "_blank");
                // swal("Great!", "Excel Generated successfully.now click on download button!", "success").then((value) => {
                //     window.location.reload();
                // });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }



    const bulkDataSubmitInitial = () => {
        let object = {};
        object.unique_id = fileSessionUniqueId
        object.organization_id = organization_id
        object.page_name = page_name;
        object.flag = false;

        setBulkDataSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-excel-information`, object, headers).then((res) => {
            setBulkDataSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Bulk Item Price updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                setBulkUpdationModal(true);
                setBulkUpdationData(res.data.err_list);
                setProceedUpdationData(res.data.update_record_list);
                setProceedInsertionData(res.data.insert_record_list);
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const bulkDataSubmitProceed = (flag) => {
        let object = {};
        object.unique_id = fileSessionUniqueId
        object.organization_id = organization_id
        object.page_name = page_name;
        object.flag = true;

        if (flag) {
            object.proceed_flag = 1;
            object.insert_record_list = proceedInsertionData;
            object.update_record_list = proceedUpdationData;
        }
        else {
            object.proceed_flag = 0;
        }
        setBulkDataSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-excel-information`, object, headers).then((res) => {
            setBulkDataSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Bulk Item Price updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                setBulkUpdationModal(true);
                setBulkUpdationData(res.data.removedRecord);

            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleBulkDataModalClose = () => {
        setBulkDataModalShow(false)
        setBulkDataFile(null)
        setBulkDataExtracted([])
        setBulkDataVerifyButtonState(false)
        setBulkDataVerified(false)
    }

    const getUploadParamsExcel = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId);
        body.append('organization_id', organization_id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }

        setMainSubmitButtonState(true)
        setBulkDataSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-excel-information`, headers, body }
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Bulk Update Item Price Information</h1>
                    <div className="d-flex align-items-center gap-5 mx-5 my-2">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <button className="btn btn-sm btn-primary me-3 rounded btn-hover-scale" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { setBulkDataModalShow(true) }}>
                            <span className="indicator-label">
                                <span className="d-md-none">
                                    <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                        <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff"></path>
                                    </svg>
                                </span>
                                <span className="d-none d-md-inline">Add Bulk Data</span>
                            </span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div>
                    {
                        state == 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <div className="d-flex align-items-center gap-5 mx-5 my-2">
                                            <DateRangePickerComponent
                                                ref={daterange_picker_ref}
                                                allowEdit={false}
                                                showClearButton={false}
                                                focus={() => { daterange_picker_ref.current.show() }}
                                                delayUpdate={true}
                                                presets={[
                                                    { label: 'Today', start: new Date(), end: new Date() },
                                                    { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                    { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                    { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                                ]}
                                                change={(e) => {
                                                    setStartDate(e.startDate);
                                                    setEndDate(e.endDate);
                                                }}
                                                startDate={new Date(startDate)}
                                                endDate={new Date(endDate)}
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchFile || ""} onChange={(e) => { setSearchFile(e.target.value) }} placeholder='Search...' /></div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                (() => {
                                                    let filteredList = ItemExcelRequest.filter((x) => {
                                                        if (searchFile != "") {
                                                            let r = new RegExp(searchFile, "gi")
                                                            if (r.test(x.original_file_name) || r.test(x.added_by) || r.test(x.status) || r.test(moment(x.add_date_time).format("ll"))) {
                                                                return x
                                                            }
                                                        }
                                                        else {
                                                            return x
                                                        }
                                                    })

                                                    return (
                                                        <>
                                                            {
                                                                bulkListApiState ?
                                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                                    :
                                                                    filteredList.length > 0 ?
                                                                        <>
                                                                            <div className="mb-5 d-flex justify-content-center">
                                                                                <Pagination
                                                                                    total={listCount}
                                                                                    onChange={(page, pageSize) => {
                                                                                        setCurrentPage(page);
                                                                                        setRowsPerPage(pageSize);
                                                                                    }}
                                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                                    showQuickJumper={true}
                                                                                    pageSize={rowsPerPage}
                                                                                    current={currentPage}
                                                                                    showSizeChanger={true}
                                                                                />
                                                                            </div>

                                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                                    <thead>
                                                                                        <tr className="fw-bold text-muted">
                                                                                            <th className="min-w-150px">File Name</th>
                                                                                            <th className="min-w-150px">Type</th>
                                                                                            <th className="min-w-150px">Created By</th>
                                                                                            <th className="min-w-150px">Created Date</th>
                                                                                            <th className="min-w-150px">Status</th>
                                                                                            {/* <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th> */}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            filteredList.map((obj) => {
                                                                                                return (
                                                                                                    <tr key={obj.unique_id}>
                                                                                                        <td><a href={`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/item_price_excel/` + obj.file_name} className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6" target="_blank">{obj.original_file_name}</a></td>
                                                                                                        <td><span className="badge badge-info">Excel</span></td>
                                                                                                        <td><span className="badge badge-secondary">{obj.added_by}</span></td>
                                                                                                        <td><span className="text-dark fw-bold text-hover-primary mb-1 fs-6">{moment(obj.add_date_time).format("ll")}</span></td>
                                                                                                        <td><StatusBadge status={obj.status} entity={""} /></td>

                                                                                                        {/* <td className="sticky-column">
                                                                                                            <div className="dropdown">
                                                                                                                <button disabled={!(write && obj.status === "failure")} className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                                                                {(write && obj.status === "failure") && 
                                                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                                    <li className="py-1"><span className="dropdown-item cursor-pointer" onClick={() => { setErr(obj.error); setErrModalShow(true); }} >View error</span></li>
                                                                                                                </ul>}
                                                                                                            </div>
                                                                                                        </td> */}
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className="mt-5 d-flex justify-content-center">
                                                                                <Pagination
                                                                                    total={listCount}
                                                                                    onChange={(page, pageSize) => {
                                                                                        setCurrentPage(page);
                                                                                        setRowsPerPage(pageSize);
                                                                                    }}
                                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                                    pageSize={rowsPerPage}
                                                                                    current={currentPage}
                                                                                    showSizeChanger={true}
                                                                                    showQuickJumper={true}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                                            </button> */}
                                                                        </div>
                                                            }
                                                        </>
                                                    )
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={ErrModalShow} onHide={() => { setErrModalShow(false) }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Denominations</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setErrModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {Err !== null &&
                        <div className="form-group row">
                            <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 mb-10">
                                {Err}
                            </div>

                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setErrModalShow(false); }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={bulkUpdationModal} onHide={() => { setBulkUpdationModal(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title as="h2">Proceed Insertion/updation </Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setBulkUpdationModal(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="notice bg-light-warning rounded border-warning border border-dashed  p-6 mb-2">
                            {
                                bulkUpdationData.map((obj, index) => {
                                    return (
                                        obj.pid_arr.length > 0 &&
                                        <>
                                            <label className="fs-5 fw-bold my-2">
                                                {obj.err_name == "sdp_greater_than_esp" ? "Special Deal Price is greater than Ecommerce Selling Price" :
                                                    obj.err_name == "esp_greater_than_mrp" ? "Ecommerce Selling Price is greater than MRP" :
                                                        obj.err_name == "cip_greater_than_csp" ? "Customer Invoice Price is greater than Customer Selling Price" :
                                                            obj.err_name == "csp_greater_than_mrp" ? "Customer Selling Price is greater than MRP" :
                                                                obj.err_name == "ip_greater_than_dp" ? "Special Deal Price is greater than Dealer Price" :
                                                                    obj.err_name == "dp_greater_than_mrp" ? "Dealer Price is greater than MRP" :
                                                                        obj.err_name.replace(/_/g, ' ').replace(/-/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                                                } : </label>
                                            <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                                                {
                                                    obj.pid_arr.map((obj1, index1) => {
                                                        return (
                                                            <div key={index1} className="btn btn-sm btn-light d-flex align-items-center">
                                                                <div className="text-gray-600 fw-bold">{obj1.product_name}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <h3>Do you want to proceed with all of this errors ?</h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { bulkDataSubmitProceed(false); setBulkUpdationModal(false) }}>Cancel</button>
                    {
                        write &&
                        <>
                            <button className="btn btn-primary rounded" data-kt-indicator={bulkDataSubmitButtonState ? "on" : "off"} disabled={bulkDataSubmitButtonState} onClick={() => { bulkDataSubmitProceed(true) }}>
                                <span className="indicator-label">Proceed</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </>
                    }

                </Modal.Footer>
            </Modal>

            <Modal show={bulkDataModalShow} onHide={() => { handleBulkDataModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title as="h2">Add Item Price Information in Bulk</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleBulkDataModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-6 my-auto'>
                                <label className="fs-5 fw-bold my-2">Download Excel file template:</label>
                            </div>
                            <div className='col-6'>
                                <button className='btn btn-info btn-lg rounded' data-kt-indicator={bulkDataSubmitButtonState ? "on" : "off"} disabled={bulkDataSubmitButtonState} onClick={() => { genTemplate() }}><i className="las la-file-download" style={{ fontSize: "32px" }}></i>
                                    <span className="indicator-label">Download</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold my-2 required">Select an Excel file with formatted data</label>
                                <Dropzone
                                    getUploadParams={getUploadParamsExcel}
                                    onChangeStatus={handleChangeStatusExcel}
                                    styles={{ inputLabelWithFiles: { margin: '20px 3%' } }}
                                    maxFiles={1}
                                    maxSizeBytes={1024 * 1024 * 2}
                                    PreviewComponent={Preview}
                                    accept=".xlsx, .xls"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleBulkDataModalClose() }}>Close</button>
                    {
                        write &&
                        <>
                            <button className="btn btn-success rounded" data-kt-indicator={bulkDataSubmitButtonState ? "on" : "off"} disabled={bulkDataSubmitButtonState} onClick={() => { bulkDataSubmitInitial() }}>
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </>
                    }

                </Modal.Footer>
            </Modal>

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default BulkUpdateItemPriceInformation;