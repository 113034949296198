import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useHistory } from 'react-router-dom'

import product_unit_arr from './common_arr/product-unit-arr.js';
import { useAuthData } from "./auth-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';

export const AddItem = () => {

    const [state, setstate] = useState(2);
    const [navtype, setnavtype] = useState("General");
    const { theme, dropdownStyleDark } = useTheme()
    const history = useHistory()

    const [HsnModalShow, setHsnModalShow] = useState(false);

    const [SubCategoryModalShow, setSubCategoryModalShow] = useState(false);
    const [CategoryModalShow, setCategoryModalShow] = useState(false);
    const [addBrandModalShow, setAddBrandModalShow] = useState(false);
    const [checkCode, setCheckCode] = useState("");

    const [categoryname, setCategoryname] = useState("");
    const [categorycode, setCategorycode] = useState("");
    const [description, setDescription] = useState("");
    const [CategorySubmitButtonState, setCategorySubmitButtonState] = useState(false)

    const [subcategorycode, setSubcategorycode] = useState("");
    const [subcategoryname, setSubcategoryname] = useState("");
    const [subcategorId, setsubcategorId] = useState("");
    const [Selected_Category, setSelected_Category] = useState("");
    const [subcategorydescription, setSubcategorydescription] = useState("");
    const [subcategorySubmitButtonState, setsubcategorySubmitButtonState] = useState(false)

    const [brandName, setBrandName] = useState("");
    const [brandDescription, setBrandDescription] = useState("");
    // const [brandImageFile, setBrandImageFile] = useState(null);
    const [brandSubmitButtonState, setBrandSubmitButtonState] = useState(false);

    const [TaxSelectionList, setTaxSelectionList] = useState([{ label: "Taxable", value: "Taxable" }, { label: "Non Taxable", value: "Non Taxable" }, { label: "Out of Scope", value: "Out of Scope" }, { label: "Non-GST Supply", value: "Non-GST Supply" }]);
    const [UnitSelectionList, setUnitSelectionList] = useState([]);
    const [Filter_arr, setFilter_arr] = useState([]);
    const [Filter_flag, setFilter_flag] = useState(false);
    const [Default_tax_preference, setDefault_tax_preference] = useState({ "Inter_State_Tax": "", "Intra_State_Tax": "" });

    const [Search_item, setSearch_item] = useState("");
    const [Unit, setUnit] = useState("8");
    const [TaxPreference, setTaxPreference] = useState("Taxable");
    const [SKU, setSKU] = useState("");
    const [Hsn_code, setHsn_code] = useState("");
    const [Sac_code, setSac_code] = useState("");

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [TaxGrouplist, setTaxGrouplist] = useState([]);
    const [Tax_methods, setTax_methods] = useState("TAX_Inclusive");

    const [BrandApiState, setBrandApiState] = useState(false);
    const [CategoryApiState, setCategoryApiState] = useState(false);
    const [SubCategoryApiState, setSubCategoryApiState] = useState(false);
    const [ManufacturerApiState, setManufacturerApiState] = useState(false);

    const [Product_type, setProduct_type] = useState("Goods");
    const [ItemName, setItemName] = useState("");
    const [Tracking_inventory, setTracking_inventory] = useState(false);
    const [Tracking_inventory_type, setTracking_inventory_type] = useState("");
    const [Shipping_flag, setShipping_flag] = useState(false);
    const [Shipping_info, setShipping_info] = useState([{ "Weight": "", "Dimension": { "Width": "", "Height": "", "Length": "" } }]);
    const [Category, setCategory] = useState("");
    const [CategoryList, setCategoryList] = useState([]);
    const [SubCategory, setSubCategory] = useState("");
    const [SubCategoryList, setSubCategoryList] = useState([]);
    // const [SubCategoryDetails, setSubCategoryDetails] = useState([]);
    const [Manufacturer, setManufacturer] = useState("");
    const [ManufacturerList, setManufacturerList] = useState([]);
    const [Brand, setBrand] = useState("");
    const [BrandList, setBrandList] = useState([]);
    const [UPC, setUPC] = useState("");
    const [EAN, setEAN] = useState("");
    const [ICBN, setICBN] = useState("");
    const [MPN, setMPN] = useState("");

    const [CategoryModal, setCategoryModal] = useState(null);
    const [CategoryModalList, setCategoryModalList] = useState([]);
    const [CategoryModalApiState, setCategoryModalApiState] = useState(false);

    var page_name = "add-item";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };


    async function gettaxgroupdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation === "success") && (body.data.length > 0)) {
            setTaxGrouplist(body.data);
        }
    }

    async function getcategorydetails(search_value, flag) {
        if (flag === "global") {
            setCategoryApiState(true);
        }
        else {
            setCategoryModalApiState(true);
        }
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-category-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        let temp_dropdown = [];
        if (body.operation === "success") {
            body.data.list.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.category_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            if (flag === "global") {
                setCategoryList(temp_dropdown);
                setCategoryApiState(false);
            }
            else {
                setCategoryModalList(temp_dropdown);
                setCategoryModalApiState(false);
            }

        }
    }

    async function getsubcategorydetails(category, search_value) {
        setSubCategoryApiState(true);
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-subcategory-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "category": category, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            let temp_dropdown = [];
            body.data.list.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.subcategory_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setSubCategoryList(temp_dropdown);
            setSubCategoryApiState(false);
            //setSubCategoryDetails(body.data); 
        }
    }

    async function getBranddetails(search_value) {
        setBrandApiState(true);
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-brands-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            let temp_dropdown = [];
            body.data.list.map((obj) => {

                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.brand_name;
                    temp_obj.value = obj.brand_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setBrandList(temp_dropdown);
            setBrandApiState(false);
        }
    }

    async function getmanufacturerdetails(search_value) {
        setManufacturerApiState(true);
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            let temp_dropdown = [];
            body.data.list.map((obj) => {

                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.manufacturer_display_name;
                    temp_obj.value = obj.cid;
                    temp_dropdown.push(temp_obj);
                }
            })
            setManufacturerList(temp_dropdown);
            setManufacturerApiState(false);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = gettaxgroupdetails();
            let p2 = getcategorydetails("", "global");
            let p3 = getBranddetails();
            let p4 = getmanufacturerdetails();
            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

    const submitItemDetails = (e) => {
        e.preventDefault();
        let Specification_info = { "MPN": MPN, "ICBN": ICBN, "UPC": UPC, "EAN": EAN };
        var values = {
            "page_name": page_name,
            "ItemName": ItemName,
            "Unit": Unit,
            "Sku": SKU,
            "Product_type": Product_type,
            "Manufacturer": Manufacturer.value,
            "SubCategory": SubCategory.value,
            "Category": Category.value,
            "Brand": Brand.value,
            "Hsn_code": Hsn_code,
            "Sac_code": Sac_code,
            "Tracking_inventory": Tracking_inventory,
            "Tracking_inventory_type": Tracking_inventory_type,
            "Shipping_flag": Shipping_flag,
            "Shipping_info": JSON.stringify(Shipping_info),
            "Specification_info": JSON.stringify(Specification_info),
            "TaxPreference": TaxPreference,
            "Default_tax_preference": JSON.stringify(Default_tax_preference),
            "organization_id": organization_id,
            "Tax_Calculation_Method": Tax_methods
        };

        if (ItemName.trim().length === 0) {
            swal("Warning!", "Please enter a Item Name", "warning");
            return false;
        }
        if (Unit.length === 0) {
            swal("Warning!", "Please select a unit", "warning");
            return false;
        }
        if (Shipping_flag) {
            if (isNaN(Shipping_info[0].Weight)) {
                swal("Warning!", "Width must be a number", "warning");
                return false;
            }
            if (isNaN(Shipping_info[0].Dimension.Height)) {
                swal("Warning!", "Height must be a number", "warning");
                return false;
            }
            if (isNaN(Shipping_info[0].Dimension.Width)) {
                swal("Warning!", "Width must be a number", "warning");
                return false;
            }
            if (isNaN(Shipping_info[0].Dimension.Length)) {
                swal("Warning!", "Length must be a number", "warning");
                return false;
            }
        }
        if (TaxPreference === "Taxable") {
            if ((Default_tax_preference.Inter_State_Tax === "") || (Default_tax_preference.Intra_State_Tax === "")) {
                swal("Warning!", "Enter Tax Details before submitting", "warning");
                return false;
            }
            if ((TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_percentage !== TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_percentage)) {
                swal("Warning!", "Both Inter State and Intra State tax percentages must be equal", "warning");
                return false;
            }
        }

        setMainSubmitButtonState(true);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/product`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation === "success") {
                swal("Great!", "Item details saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    useEffect(() => {
        var obj_array = [];
        var Productunitarr = product_unit_arr.product_unit_arr;
        for (let k = 0; k < Productunitarr.length; k++) {
            var obj2 = {};
            obj2.label = Productunitarr[k].Short_term + "(" + Productunitarr[k].Unit + ")";
            obj2.value = Productunitarr[k].id;
            obj_array.push(obj2);
        }
        setUnitSelectionList(obj_array);
    }, []);

    const submitSubCategorydetails = (e) => {
        e.preventDefault();
        //var id = subcategorId;
        var sub_category_name = subcategoryname.trim();
        var sub_category_code = subcategorycode.trim();
        var sub_category_description = sub_category_description;
        //var category = Category;
        var cexp = /^[0-9]+$/
        if (!CategoryModal) {
            swal("Warning!", "Need to select Category.", "warning");
            return false;
        }
        if (sub_category_name.length === 0) {
            swal("Warning!", "Please enter a SubCategory Name", "warning");
            return false;
        }
        if (sub_category_code.length === 0) {
            swal("Warning!", "Please enter a SubCategory Code", "warning");
            return false;
        }
        else if (!cexp.test(sub_category_code)) {
            swal("Warning!", "SubCategory Code can only be numbers!", "warning");
            return false;
        }
        if (checkCode[sub_category_code]) {
            swal("Warning!", "Please enter a unique SubCategory Code", "warning");
            return false;
        }

        var values = { "category": CategoryModal.value, "sub_category_name": sub_category_name, "sub_category_code": sub_category_code, "sub_category_description": sub_category_description, "page_name": page_name, "organization_id": organization_id };
        setsubcategorySubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-subcategory`, values, headers).then((res) => {

            if (res.data && res.data.operation === "success") {
                swal("Great!", "SubCategory added successfully!", "success").then((value) => {
                    //window.location.reload();
                    setSubCategoryModalShow(false);
                    setsubcategorySubmitButtonState(false);
                    getsubcategorydetails();
                    setSubcategoryname("");
                    setSubcategorycode("");
                    setSubcategorydescription("");
                    setSelected_Category("");
                });
            }
            else if (res.data.operation === "same_subcategory_name") {
                swal("Oops!", "Same SubCategory Name Already Exist!", "warning").then((value) => {
                    //window.location.reload();
                });
            }
            else if (res.data.operation === "same_subcategory_code") {
                swal("Oops!", "Same SubCategory Code Already Exist!", "warning").then((value) => {
                    //window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitCategorydetails = (e) => {
        e.preventDefault();
        var category_name = categoryname.trim();
        var category_code = categorycode.trim();
        var description = description;
        var cexp = /^[0-9]+$/

        if (category_name.length === 0) {
            swal("Warning!", "Please enter a Category Name", "warning");
            return false;
        }
        if (category_code.length === 0) {
            swal("Warning!", "Please enter a Category Code", "warning");
            return false;
        }
        else if (!cexp.test(category_code)) {
            swal("Warning!", "Category Code can only be numbers!", "warning");
            return false;
        }
        if (checkCode[category_code]) {
            swal("Warning!", "Please enter a unique Category Code", "warning");
            return false;
        }
        var values = { "category_name": category_name, "category_code": category_code, "description": description, "page_name": page_name, "organization_id": organization_id };
        setCategorySubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/addcategory`, values, headers).then((res) => {
            if (res.data && res.data.operation === "success") {
                swal("Great!", "Category added successfully!", "success").then((value) => {
                    setCategorySubmitButtonState(false);
                    setCategoryModalShow(false);
                    getcategorydetails("", "global");
                    setCategoryname("");
                    setCategorycode("");
                    setDescription("");
                });
            }
            else if (res.data.operation === "same_category_name") {
                swal("Oops!", "Same Category Name Already Exist!", "error").then((value) => {
                    //window.location.reload();
                });
            }
            else if (res.data.operation === "same_category_code") {
                swal("Oops!", "Same Category Code Already Exist!", "error").then((value) => {
                    //window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const newBrandSubmit = () => {

        if (!brandName.trim()) {
            swal("Oops!", "Brand Name cannot be empty", "warning");
            return
        }

        var object = {};

        object.brand_name = brandName
        object.brand_description = brandDescription

        object.organization_id = organization_id;
        object.page_name = page_name;

        setBrandSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-brand`, object, headers).then((res) => {
            if (res.data.operation === "success") {
                swal("Great!", "Brand added successfully!", "success")
                setBrandSubmitButtonState(false)
                setAddBrandModalShow(false);
                getBranddetails();
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Brand already exists!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    function getElementByXpath(path) {
        return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    }

    function scrollToTop() {
        getElementByXpath("/html/body/div/div/div[2]/div[2]").scrollTo({ top: 0, behavior: 'smooth' });
    }

    const Search_list = (val) => {
        setFilter_arr([]);
        setFilter_flag(false);
        setMainSubmitButtonState(true);
        let search_type = "";
        if (Product_type === "Goods") {
            search_type = "HSN";
        }
        else {
            search_type = "SAC";
        }
        //setMainSubmitButtonState(false);
        var values = { "page_name": page_name, "search_type": search_type, "search_key": val, "organization_id": organization_id }
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/get-product-hsn-code`, values, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data.operation === "success") {
                if (res.data.data.length > 0) {
                    setFilter_arr(res.data.data);
                }
                setFilter_flag(true);
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading text-dark fw-bold fs-3 my-0">Add Item</h1>
                </div>
                <div>
                    {
                        state === 1 ?
                            <div className="form d-flex flex-column flex-lg-row">
                                <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                        <li className="nav-item">
                                            <a className={`nav-link text-active-primary pb-4 cursor-pointer ${navtype === "General" && "active"}`} onClick={(e) => { setnavtype("General"); }}>General</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link text-active-primary pb-4 cursor-pointer ${navtype === "Advance" && "active"}`} onClick={(e) => { setnavtype("Advance"); }}>Advanced</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        {navtype === "General" &&
                                            <div className={`tab-pane fade ${navtype === "General" && "show active"}`} role="tab-panel">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>General</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="fv-row mb-10">
                                                                <label className="fs-6 fw-semibold mb-2">Type
                                                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>
                                                                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button='true']">
                                                                    <div className="col">
                                                                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${Product_type === "Goods" && "active"}`} data-kt-button="true">
                                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                                <input className="form-check-input" type="radio" name="discount_option" value="2" onChange={(e) => { setProduct_type("Goods"); setFilter_arr([]); setSearch_item(""); }} checked={Product_type === "Goods"} />
                                                                            </span>
                                                                            <span className="ms-5">
                                                                                <span className="fs-4 fw-bold text-gray-800 d-block">Goods</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${Product_type === "Service" && "active"}`} data-kt-button="true">
                                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                                <input className="form-check-input" type="radio" name="discount_option" value="3" onChange={(e) => { setProduct_type("Service"); setFilter_arr([]); setSearch_item(""); }} checked={Product_type === "Service"} />
                                                                            </span>
                                                                            <span className="ms-5">
                                                                                <span className="fs-4 fw-bold text-gray-800 d-block">Service</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Item Name</label>
                                                                <input type="text" name="product_name" className="form-control mb-2" placeholder="Item name" value={ItemName} onChange={(e) => { setItemName(e.target.value) }} />
                                                                <div className="text-muted fs-7">A Item name is required and recommended to be unique.</div>
                                                            </div>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" type="checkbox" name="email-preferences[]" checked={Tracking_inventory === true} value={Tracking_inventory} onChange={(e) => {
                                                                    setTracking_inventory(e.target.checked);
                                                                    if (e.target.checked === true) {
                                                                        setTracking_inventory_type("None");
                                                                    }
                                                                    else {
                                                                        setTracking_inventory_type("");
                                                                    }
                                                                }} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bolder fs-5 mb-0">Track Inventory for this item</span>
                                                                    {Tracking_inventory && <span className="text-muted fs-6">You cannot enable/disable inventory tracking once you've created transactions for this item</span>}
                                                                </span>
                                                            </label>
                                                            <br /><br />
                                                            {
                                                                Tracking_inventory === true &&
                                                                <div className="mb-10">
                                                                    <label className="required fw-semibold fs-6 mb-5">Advanced Inventory Tracking</label>
                                                                    <div className="d-flex align-items-center mt-3">
                                                                        <label className="form-check form-check-inline form-check-solid me-5"><input className="form-check-input" type="radio" onChange={(e) => { setTracking_inventory_type("None") }} checked={Tracking_inventory_type === "None"} /><span className="fw-bold ps-2 fs-6">Quantity</span></label>
                                                                        <label className="form-check form-check-inline form-check-solid me-5"><input className="form-check-input" type="radio" onChange={(e) => { setTracking_inventory_type("Track Serial Number") }} checked={Tracking_inventory_type === "Track Serial Number"} /><span className="fw-bold ps-2 fs-6">Track Serial Number</span></label>
                                                                        <label className="form-check form-check-inline form-check-solid me-5"><input className="form-check-input" type="radio" onChange={(e) => { setTracking_inventory_type("Track Batches") }} checked={Tracking_inventory_type === "Track Batches"} /><span className="fw-bold ps-2 fs-6">Track Batches</span></label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="card card-flush py-4">

                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Shipping</h2>
                                                            </div>
                                                        </div>

                                                        <div className="card-body pt-0">
                                                            <div className="fv-row">
                                                                <div className="form-check form-check-custom form-check-solid mb-2">
                                                                    <input className="form-check-input" type="checkbox" id="kt_ecommerce_add_product_shipping_checkbox" checked={Shipping_flag} onChange={(e) => {
                                                                        setShipping_flag(e.target.checked);
                                                                    }} />
                                                                    <label className="form-check-label">This is a physical product</label>
                                                                </div>
                                                                <div className="text-muted fs-7">Set if the product is a physical or digital item. Physical products may require shipping.</div>
                                                            </div>

                                                            {Shipping_flag &&
                                                                <div className="mt-10">
                                                                    <div className="mb-10 fv-row">
                                                                        <label className="form-label">Weight</label>
                                                                        <input type="number" name="weight" className="form-control mb-2" placeholder="Product weight" value={Shipping_info[0].Weight}
                                                                            onChange={(e) => {
                                                                                let temp = Shipping_info.map(x => { return { ...x } });
                                                                                temp[0].Weight = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                                                                setShipping_info(temp);
                                                                            }} />
                                                                        <div className="text-muted fs-7">Set a product weight in kilograms (kg).</div>
                                                                    </div>
                                                                    <div className="fv-row">
                                                                        <label className="form-label">Dimension</label>
                                                                        <div className="d-flex flex-wrap flex-sm-nowrap gap-3">
                                                                            <input type="number" min="0" name="width" className="form-control mb-2" placeholder="Width (w)" value={Shipping_info[0].Dimension.Width}
                                                                                onChange={(e) => {
                                                                                    let temp = Shipping_info.map(x => { return { ...x } });
                                                                                    temp[0].Dimension.Width = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                                                                    setShipping_info(temp);
                                                                                }} />
                                                                            <input type="number" min="0" name="height" className="form-control mb-2" placeholder="Height (h)" value={Shipping_info[0].Dimension.Height} onChange={(e) => {
                                                                                let temp = Shipping_info.map(x => { return { ...x } });
                                                                                temp[0].Dimension.Height = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                                                                setShipping_info(temp);
                                                                            }} />
                                                                            <input type="number" min="0" name="length" className="form-control mb-2" placeholder="Length (l)" value={Shipping_info[0].Dimension.Length} onChange={(e) => {
                                                                                let temp = Shipping_info.map(x => { return { ...x } });
                                                                                temp[0].Dimension.Length = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                                                                setShipping_info(temp);
                                                                            }} />
                                                                        </div>
                                                                        <div className="text-muted fs-7">Enter the product dimensions in centimeters (cm).</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Specification</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Category</label>
                                                                    <Select
                                                                        styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                        options={CategoryList}
                                                                        isClearable
                                                                        onChange={(val) => {
                                                                            setSubCategory("");
                                                                            setCategory(val);
                                                                            if (val !== null) {
                                                                                getsubcategorydetails(val.value, "");
                                                                            }
                                                                            else {
                                                                                getsubcategorydetails("", "");
                                                                            }
                                                                        }}
                                                                        onInputChange={(val) => {
                                                                            console.log(val.length)
                                                                            if (val.length > 2) {
                                                                                getcategorydetails(val, "global");
                                                                            }
                                                                        }}
                                                                        onMenuClose={() => {
                                                                            setSubCategory("");
                                                                            getcategorydetails("", "global");
                                                                        }}
                                                                        isLoading={CategoryApiState}
                                                                        value={Category}
                                                                        placeholder="--Select product Category--"
                                                                        components={{
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children}
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1'
                                                                                                onClick={() => {
                                                                                                    setCategoryModalShow(true);
                                                                                                }}
                                                                                            >&#10011; Add Category</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product Category.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">SubCategory</label>
                                                                    <Select
                                                                        styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                        options={SubCategoryList}
                                                                        isClearable
                                                                        onChange={(val) => {
                                                                            setSubCategory(val);
                                                                        }}
                                                                        onInputChange={(val) => {
                                                                            if (val.length > 2) {
                                                                                //getsubcategorydetails(val);
                                                                                getsubcategorydetails(Category.value, val);
                                                                            }
                                                                        }}
                                                                        onMenuClose={() => {
                                                                            //getsubcategorydetails();
                                                                            if (Category !== null) {
                                                                                getsubcategorydetails(Category.value, "");
                                                                            }
                                                                            else {
                                                                                getsubcategorydetails("", "");
                                                                            }
                                                                        }}
                                                                        isLoading={SubCategoryApiState}
                                                                        value={SubCategory}
                                                                        components={{
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children}
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1'
                                                                                                onClick={() => {
                                                                                                    getcategorydetails("", "modal");
                                                                                                    setSubCategoryModalShow(true);
                                                                                                }}
                                                                                            >&#10011;Add SubCategory</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                )
                                                                            }
                                                                        }}
                                                                        placeholder="--Select product SubCategory--"
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product SubCategory.</div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Manufacturer</label>
                                                                    <Select
                                                                        styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                        options={ManufacturerList}
                                                                        isClearable
                                                                        onChange={(val) => {
                                                                            setManufacturer(val);
                                                                        }}
                                                                        onInputChange={(val) => {
                                                                            if (val.length > 2) {
                                                                                getmanufacturerdetails(val);
                                                                            }
                                                                        }}
                                                                        onMenuClose={() => {
                                                                            getmanufacturerdetails();
                                                                        }}
                                                                        isLoading={ManufacturerApiState}
                                                                        value={Manufacturer}
                                                                        placeholder="--Select product Manufacturer--"
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product Manufacturer.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Brand</label>
                                                                    <Select
                                                                        styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                        options={BrandList}
                                                                        isClearable
                                                                        onChange={(val) => {
                                                                            setBrand(val);
                                                                        }}
                                                                        onInputChange={(val) => {
                                                                            if (val.length > 2) {
                                                                                getBranddetails(val);
                                                                            }
                                                                        }}
                                                                        onMenuClose={() => {
                                                                            getBranddetails();
                                                                        }}
                                                                        isLoading={BrandApiState}
                                                                        value={Brand}
                                                                        placeholder="--Select product Brand--"
                                                                        components={{
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children}
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1'
                                                                                                onClick={() => {
                                                                                                    setAddBrandModalShow(true);
                                                                                                }}
                                                                                            >&#10011; Add Brand</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product Brand.</div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">UPC</label>
                                                                    <input type="text" className="form-control mb-2" placeholder="" value={UPC} onChange={(e) => { setUPC(e.target.value) }} />
                                                                    <div className="text-muted fs-7">Set the product UPC.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">EAN</label>
                                                                    <input type="text" className="form-control mb-2" placeholder="" value={EAN} onChange={(e) => { setEAN(e.target.value) }} />
                                                                    <div className="text-muted fs-7">Set the product EAN.</div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">MPN</label>
                                                                    <input type="text" className="form-control mb-2" placeholder="" value={MPN} onChange={(e) => { setMPN(e.target.value) }} />
                                                                    <div className="text-muted fs-7">Set the product MPN.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">ICBN </label>
                                                                    <input type="text" className="form-control mb-2" placeholder="" value={ICBN} onChange={(e) => { setICBN(e.target.value) }} />
                                                                    <div className="text-muted fs-7">Set the product ICBN.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                        {navtype === "Advance" &&
                                            <div className={`tab-pane fade ${navtype === "Advance" && "show active"}`} role="tab-panel">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Inventory</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">SKU</label>
                                                                <input type="text" name="sku" className="form-control mb-2" placeholder="SKU Number" value={SKU} onChange={(e) => { setSKU(e.target.value) }} />

                                                                <div className="text-muted fs-7">Enter the product SKU.</div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Unit</label>
                                                                <Select
                                                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                    name="category"
                                                                    id="category"
                                                                    options={UnitSelectionList}
                                                                    onChange={(event) => {
                                                                        setUnit(event.value);
                                                                    }}
                                                                    value={UnitSelectionList.filter(option => option.value === Unit).length !== 0 && UnitSelectionList.filter(option => option.value === Unit)[0]}
                                                                    placeholder="--Select product Unit--"
                                                                />
                                                                <div className="text-muted fs-7">Enter the product Unit.</div>
                                                            </div>

                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">{Product_type === "Goods" ? <>HSN</> : <>SAC</>} Code</label>
                                                                {Product_type === "Goods" ?
                                                                    <>
                                                                        <div className="d-flex">
                                                                            <input type="text" name="HSN" className="form-control mb-2" placeholder="HSN Code Number" value={Hsn_code} onChange={(e) => { setHsn_code(e.target.value) }} />
                                                                            <div className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" onClick={(e) => { setHsnModalShow(true); }}>
                                                                                <span className="svg-icon svg-icon-1">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="d-flex">
                                                                            <input type="text" name="SAC" className="form-control mb-2" placeholder="SAC Code Number" value={Sac_code} onKeyPress={(e) => {
                                                                                console.log('do validate', e.key)
                                                                                if (e.key === 'Enter') {
                                                                                    console.log('do validate')
                                                                                }
                                                                            }} onChange={(e) => { setSac_code(e.target.value) }} />
                                                                            <div className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" onClick={(e) => { setHsnModalShow(true); }}>
                                                                                <span className="svg-icon svg-icon-1">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="text-muted fs-7">Enter the product {Product_type === "Goods" ? <>HSN</> : <>SAC</>} Code number.</div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Tax Preference</label>
                                                                <Select
                                                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                    name="category"
                                                                    id="category"
                                                                    options={TaxSelectionList}
                                                                    onChange={(event) => {
                                                                        setTaxPreference(event.value);
                                                                    }}
                                                                    value={TaxSelectionList.filter(option => option.value === TaxPreference).length !== 0 && TaxSelectionList.filter(option => option.value === TaxPreference)[0]}
                                                                    placeholder="--Select Tax Preference--"
                                                                />
                                                                <div className="text-muted fs-7">Enter the Tax Preference.</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        TaxPreference === "Taxable" &&
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Default Tax Rates</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="fs-6 fw-semibold mb-2">Tax Calculation Method
                                                                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className={`rounded mx-2 px-6 py-2 ${Tax_methods === "TAX_Inclusive" && "bg-primary"}`} onClick={() => { setTax_methods("TAX_Inclusive"); }}>TAX Inclusive</div>
                                                                        <div className={`rounded mx-2 px-6 py-2 ${Tax_methods === "TAX_Exclusive" && "bg-primary"}`} onClick={() => { setTax_methods("TAX_Exclusive"); }}>TAX Exclusive</div>
                                                                    </div>
                                                                </div><br />
                                                                <div className="d-flex flex-wrap gap-5">
                                                                    <div className="fv-row w-100 flex-md-root">
                                                                        <label className="form-label required">Intra State Tax Rate</label>
                                                                        <Select
                                                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                            options={TaxGrouplist.filter(x => x.tax_group_type === "INTRASTATE").map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                            onChange={(event) => { setDefault_tax_preference({ ...Default_tax_preference, Intra_State_Tax: event.value }) }}
                                                                            value={Default_tax_preference.Intra_State_Tax !== "" && { label: `(${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_percentage}%) ${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_name}`, value: Default_tax_preference.Intra_State_Tax }}
                                                                            placeholder="--Select Intrastate tax--"
                                                                        />
                                                                        <div className="text-muted fs-7">Set the Intra State Tax Rate.</div>
                                                                    </div>
                                                                    <div className="fv-row w-100 flex-md-root">
                                                                        <label className="form-label required">Inter State Tax Rate</label>
                                                                        <Select
                                                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                            options={TaxGrouplist.filter(x => x.tax_group_type === "INTERSTATE").map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                            onChange={(event) => { setDefault_tax_preference({ ...Default_tax_preference, Inter_State_Tax: event.value }) }}
                                                                            value={Default_tax_preference.Inter_State_Tax !== "" && { label: `(${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_percentage}%) ${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_name}`, value: Default_tax_preference.Inter_State_Tax }}
                                                                            placeholder="--Select Interstate tax--"
                                                                        />
                                                                        <div className="text-muted fs-7">Set the Inter State Tax Rate.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>}
                                    </div>

                                    <div className="d-flex justify-content-end gap-3">
                                        {
                                            navtype === "General" ?
                                                <button type="button" onClick={() => { setnavtype("Advance"); scrollToTop(); }} className="btn btn-lg btn-primary">Next<i className="bi bi-caret-right-fill ms-2 fs-4"></i></button>
                                                :
                                                <>
                                                    <button type="button" onClick={() => { setnavtype("General"); scrollToTop(); }} className="btn btn-lg btn-primary"><i className="bi bi-caret-left-fill me-2 fs-4"></i>Previous</button>
                                                    <button data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { submitItemDetails(e) }} className="btn btn-primary">
                                                        <span className="indicator-label">Save Changes</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                </>
                                        }
                                    </div>
                                </div>

                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError>
                                    <button className='btn btn-success rounded' onClick={() => { history.go(0) }}>Reload the page</button>
                                </PageError>
                    }
                </div>
            </div>

            <Modal show={addBrandModalShow} onHide={() => { setAddBrandModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>Add Brand</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddBrandModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Brand Name</label>
                                <input type="text" className="form-control form-control-solid" value={brandName} onChange={(e) => { setBrandName(e.target.value) }} placeholder="Brand Name" />
                                <span className="form-text text-muted">Please enter Brand name.</span>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="fs-5 fw-bold mb-2">Brand Description</label>
                                <textarea className="form-control form-control-solid" rows="5" value={brandDescription} onChange={(e) => { setBrandDescription(e.target.value) }} placeholder="Brand Description" />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setAddBrandModalShow(false) }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={brandSubmitButtonState ? "on" : "off"} disabled={brandSubmitButtonState} onClick={() => { newBrandSubmit() }}>
                            <span className="indicator-label">+ Add</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={CategoryModalShow} onHide={() => { setCategoryModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Category</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setCategoryModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Category Name</label>
                                <input id="category_name" type="text" className="form-control form-control-solid" placeholder="" name="category-name" value={categoryname} onChange={(e) => { setCategoryname(e.target.value) }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Category Code</label>
                            <input id="category_code" className="form-control form-control-solid" placeholder="" name="categorycode" value={categorycode} onChange={(e) => { setCategorycode(e.target.value) }} />
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="fs-5 fw-bold mb-2">Description</label>
                            <input id="description" className="form-control form-control-solid" placeholder="" name="description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setCategoryModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitCategorydetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>
            <Modal show={SubCategoryModalShow} onHide={() => { setSubCategoryModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{subcategorId !== "" ? <>Update</> : <>Add</>} SubCategory</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setSubCategoryModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Select Category</label>
                                <Select
                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                    options={CategoryModalList}
                                    isClearable
                                    onChange={(val) => {
                                        setCategoryModal(val);
                                    }}
                                    onInputChange={(val) => {
                                        if (val.length > 2) {
                                            getcategorydetails(val, "modal");
                                        }
                                    }}
                                    onMenuClose={() => {
                                        getcategorydetails("", "modal");
                                    }}
                                    isLoading={CategoryModalApiState}
                                    value={CategoryModal}
                                    placeholder="--Select product Category--"
                                    components={{
                                        MenuList: (props) => {
                                            return (
                                                <components.MenuList  {...props}>
                                                    {props.children}
                                                    <div className='d-flex justify-content-center'>
                                                        <button className='btn btn-success rounded btn-sm my-1'
                                                            onClick={() => {
                                                                setCategoryModalShow(true);
                                                            }}
                                                        >&#10011; Add Category</button>
                                                    </div>
                                                </components.MenuList >
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">SubCategory Name</label>
                                <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={subcategoryname} onChange={(e) => { setSubcategoryname(e.target.value) }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="required fs-5 fw-bold mb-2">SubCategory Code</label>
                            <input id="subcategory_code" className="form-control form-control-solid" placeholder="" name="categorycode" value={subcategorycode} onChange={(e) => { setSubcategorycode(e.target.value) }} />
                        </div>

                        <div className="d-flex flex-column mb-5 fv-row">
                            <label className="fs-5 fw-bold mb-2">SubCategory Description</label>
                            <input id="subcategory_description" className="form-control form-control-solid" placeholder="" name="subcategory-description" value={subcategorydescription} onChange={(e) => { setSubcategorydescription(e.target.value) }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setSubCategoryModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitSubCategorydetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{subcategorId !== "" ? <>Update</> : <>Save</>}</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>
            <Modal show={HsnModalShow} onHide={() => { setHsnModalShow() }} dialogClassName="h-modal-75" centered>
                <Modal.Header>
                    <Modal.Title>Find {Product_type === "Goods" ? <>HSN</> : <>SAC</>} Code</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setSearch_item(""); setFilter_arr([]); setHsnModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="form-group row">
                            <div className="fv-row w-100 flex-md-root mb-5">
                                <label className="form-label">Search {Product_type === "Goods" ? <>HSN</> : <>SAC</>} code for your item</label>
                                <div className="d-flex">
                                    <input type="text" className="form-control" value={Search_item} onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            if (e.target.value !== "") {
                                                Search_list(e.target.value)
                                            }
                                            else {
                                                swal("Oops!", "Search field can't be blank!", "warning");
                                            }
                                        }
                                    }} onChange={(e) => { setSearch_item(e.target.value); }} />

                                    <button type="submit" className="btn btn-primary btn-sm rounded" onClick={(e) => {
                                        if (Search_item !== "") {
                                            Search_list(Search_item)
                                        }
                                        else {
                                            swal("Oops!", "Search field can't be blank!", "warning");
                                        }
                                    }} id="kt_careers_submit_button"
                                        data-kt-indicator={mainSubmitButtonState ? "on" : "off"}
                                        disabled={mainSubmitButtonState}
                                    >Search</button><br />
                                </div>
                                <div className="text-muted fs-7"></div>
                            </div>
                            {
                                (Filter_flag === true && Filter_arr.length > 0) ? Filter_arr.map((obj, index) => {
                                    return (
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <span className="svg-icon svg-icon-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>

                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <a onClick={(e) => {
                                                    if (Product_type === "Goods") {
                                                        setHsn_code(obj.codes);
                                                    }
                                                    else {
                                                        setSac_code(obj.codes);
                                                    }
                                                    setHsnModalShow(false);
                                                }} className="fs-6 text-gray-800 text-hover-primary fw-semibold cursor-pointer">{obj.code_description}</a>
                                                <span className="fs-7 text-muted fw-semibold">#{obj.codes}</span>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    (Filter_flag === true && Filter_arr.length === 0) &&
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                <div className="fs-6 text-gray-600"> </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setSearch_item(""); setFilter_arr([]); setHsnModalShow(false); }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default AddItem;
